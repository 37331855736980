import React from 'react';
import {useOnboardingContext} from './Onboarding';
import {
  Box,
  Flex,
  Divider,
  Button,
  useToast,
  Tooltip,
  Spacer,
  HStack,
} from '@chakra-ui/react';
import {LookalikeTypeahead} from '../LookalikeTypeahead';
import {useSimilarityState} from '../Market/ModelBuilder/Similarity.state';
import {useCustomer} from '../../hooks/api/metadata';
import {captureMessage} from '@sentry/react';
import {ScrollableFlex, WrapperFlex} from '../utils/scrolling';
import _ from 'lodash';
import {useDebounce} from 'usehooks-ts';
import {GuidanceBar, GuidanceBarMode} from '../GuidanceBar';
import {LookalikesImportButton} from '../Market/ModelBuilder/LookalikesImportButton';

export const LookalikeSelection = () => {
  const {onPageNavigate, isFirstStep} = useOnboardingContext();
  const customer = useCustomer();

  const toast = useToast();
  const {localState, isWithinLimits, publishChanges, getLimits} =
    useSimilarityState();

  const {minLookalikes, maxLookalikes} = getLimits(customer);

  // NOTE: useDebounce calls useEffect to determine whether the value has been
  // changed. This works for us since we use immer in the similarity state
  // store which creates a new array whenever a lookalike is added or removed.
  const debouncedLocalState = useDebounce(localState, 200);

  const onNext = async () => {
    try {
      await publishChanges.mutateAsync();
      onPageNavigate('next');
    } catch (e) {
      captureMessage('Error saving lookalikes', {
        extra: {error: e},
      });

      toast({
        title: 'Something unexpected happened',
        description: 'Please contact us at support@keyplay.io',
        status: 'error',
        duration: 5_000,
        isClosable: true,
      });
    }
  };

  return (
    <WrapperFlex>
      <ScrollableFlex>
        <Box fontSize="2xl" mb={3}>
          Next, name some of your best customers
        </Box>
        <HStack mb={8}>
          <Box color="kgray.300">
            Which companies are your best ICP examples? This informs scoring.
          </Box>
          <Spacer />
          <LookalikesImportButton>
            Upload a List of Domains
          </LookalikesImportButton>
        </HStack>
        <Box flexGrow={1}>
          <LookalikeTypeahead />
        </Box>
      </ScrollableFlex>

      <Divider />

      <Flex justifyContent={'space-between'} w={'100%'} px={10} py={4} gap={10}>
        {!isFirstStep && (
          <Button
            colorScheme="kbuttonblue"
            onClick={() => onPageNavigate('prev')}
            variant="outline"
            w="110px"
          >
            Back
          </Button>
        )}
        <LookalikesGuidanceBar
          lookalikesSize={debouncedLocalState.length}
          minLookalikes={minLookalikes}
          maxLookalikes={maxLookalikes}
        />
        <Tooltip
          isDisabled={isWithinLimits(customer)}
          label={`Add ${minLookalikes}-${maxLookalikes} lookalikes to continue`}
          placement="top-start"
        >
          <Button
            colorScheme="kbuttonblue"
            isDisabled={!isWithinLimits(customer)}
            isLoading={publishChanges.isPending}
            onClick={onNext}
            variant="solid"
            w="110px"
          >
            Continue
          </Button>
        </Tooltip>
      </Flex>
    </WrapperFlex>
  );
};

const LookalikesGuidanceBar = ({
  lookalikesSize,
  minLookalikes,
  maxLookalikes,
}: {
  lookalikesSize: number;
  minLookalikes: number;
  maxLookalikes: number;
}) => {
  let mode: GuidanceBarMode = 'default';
  let guidanceText = '';

  if (lookalikesSize < minLookalikes) {
    mode = 'warning';
    guidanceText = `Add at least ${minLookalikes} companies to continue.`;
  } else if (lookalikesSize > maxLookalikes) {
    mode = 'warning';
    guidanceText = 'Too many companies, please remove some to continue.';
  } else if (lookalikesSize < 5) {
    mode = 'warning';
    guidanceText = 'For best results, add 5 or more companies.';
  }

  return (
    <GuidanceBar borderRadius={5} flexGrow={1} mode={mode}>
      {guidanceText}
    </GuidanceBar>
  );
};
