import React, {createContext, useContext, useMemo} from 'react';
import {FieldDefinitionAnalysisRun} from '../../../../shared/enrichment';
import {TestList} from '../../../../shared/testLists';
import {WithId} from '../../../../shared/util';

const AnalysisRunContext = createContext<{
  analysisRun: WithId<FieldDefinitionAnalysisRun>;
  testLists: WithId<TestList>[];
} | null>(null);

export const AnalysisRunProvider = ({
  analysisRun,
  testLists,
  children,
}: {
  analysisRun: WithId<FieldDefinitionAnalysisRun>;
  testLists: WithId<TestList>[];
  children: React.ReactNode;
}) => {
  const value = useMemo(
    () => ({analysisRun, testLists}),
    [analysisRun, testLists]
  );

  return (
    <AnalysisRunContext.Provider value={value}>
      {children}
    </AnalysisRunContext.Provider>
  );
};

export const useAnalysisRunContext = () => {
  const context = useContext(AnalysisRunContext);
  if (!context) {
    throw new Error('AnalysisRunContext not found');
  }

  return context;
};
