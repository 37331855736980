import React from 'react';
import {useDropzone} from 'react-dropzone';
import {isDomainImportRow} from '../shared/import';
import {ObjectId} from 'bson';
import {FileValidationError, validateFile} from '../lib/validateFile';
import {useBulkFindAccountsMutation} from './api/bulkFindAccounts';
import {Box, Flex, ListItem, UnorderedList, useToast} from '@chakra-ui/react';

export const useBulkFindAccounts = ({
  maxRows,
  onError,
  onSuccess,
  type,
}: {
  maxRows: number;
  onError: (error: Set<FileValidationError>) => void;
  onSuccess: (accountIds: ObjectId[]) => void;
  type: 'lookalike' | 'preview';
}) => {
  const bulkFindAccounts = useBulkFindAccountsMutation();
  const toast = useToast();

  const {open} = useDropzone({
    accept: {'text/csv': ['.csv']},
    onDropAccepted: (files) => {
      const file = files[0];
      if (!file) {
        return;
      }

      validateFile({
        maxRows,
        onFileValidate: (errors, data) => {
          if (errors.size) {
            onError(errors);
            return;
          }

          const domains = data.map(({Domain}) => Domain);
          bulkFindAccounts.mutate(
            {domains, type},
            {
              onSuccess: onSuccess,
            }
          );
        },
        rowSchemaTypeguard: isDomainImportRow,
        selectedFile: file,
      });
    },
    onDropRejected: (fileRejections) => {
      toast({
        title: 'Invalid CSV file',
        description: (
          <Box>
            {fileRejections.map(({file, errors}) => (
              <Flex key={file.name} direction="column">
                <Box>{file.name}</Box>
                <UnorderedList>
                  {errors.map(({message}, index) => (
                    <ListItem key={index}>{message}</ListItem>
                  ))}
                </UnorderedList>
              </Flex>
            ))}
          </Box>
        ),
        status: 'error',
      });
    },
    maxSize: 10_000, // in bytes
    multiple: false,
  });

  return {isPending: bulkFindAccounts.isPending, open};
};
