import React, {useState} from 'react';
import {useOnboardingContext} from './Onboarding';
import {
  Flex,
  Button,
  Box,
  Radio,
  useToast,
  RadioGroup,
  Divider,
} from '@chakra-ui/react';
import {Role, RoleLabels, Roles} from '../../shared/user';
import {useUpdateUser} from '../../hooks/api/user';
import {captureMessage} from '@sentry/react';
import {ScrollableFlex, WrapperFlex} from '../utils/scrolling';

export const SetRole = () => {
  const {onPageNavigate, isFirstStep} = useOnboardingContext();
  const updateUser = useUpdateUser();
  const toast = useToast();
  const [role, setRole] = useState<Role | undefined>(undefined);

  const onNext = async () => {
    if (!role) {
      return;
    }

    try {
      await updateUser.mutateAsync({role});
      onPageNavigate('next');
    } catch (e) {
      captureMessage('Error saving user role', {
        extra: {error: e},
      });

      toast({
        title: 'Something unexpected happened',
        description: 'Please contact us at support@keyplay.io',
        status: 'error',
        duration: 5_000,
        isClosable: true,
      });
    }
  };

  return (
    <WrapperFlex textAlign={'center'}>
      <ScrollableFlex alignItems={'center'}>
        <Box fontSize="2xl" mb={5}>
          Before you dig into your accounts, what best describes your role?
        </Box>
        <Box color="kgray.300" mb={10}>
          It&apos;s our last question, promise!
        </Box>
        <RadioGroup
          flexGrow={1}
          onChange={(value) => setRole(value as Role)}
          w={'100%'}
          maxW={'lg'}
          value={role}
        >
          <Flex direction="column" gap={2} my={8}>
            {Roles.map((r) => (
              <Radio key={r} value={r} variant="outline">
                {RoleLabels[r]}
              </Radio>
            ))}
          </Flex>
        </RadioGroup>
      </ScrollableFlex>

      <Divider />

      <Flex justifyContent={'space-between'} w={'100%'} px={10} py={4}>
        {!isFirstStep && (
          <Button
            colorScheme="kbuttonblue"
            onClick={() => onPageNavigate('prev')}
            variant="outline"
            w="110px"
          >
            Back
          </Button>
        )}
        <Box flexGrow={1} />
        <Button
          colorScheme="kbuttonblue"
          isDisabled={!role || updateUser.isPending}
          isLoading={updateUser.isPending}
          onClick={onNext}
          variant="solid"
        >
          Start Discovering Accounts
        </Button>
      </Flex>
    </WrapperFlex>
  );
};
