import React, {useEffect, useState} from 'react';
import {Box, BoxProps, Image, Text, Tooltip} from '@chakra-ui/react';
import Select, {StylesConfig} from 'react-select';
import {AccountWithLogo} from '../shared/scoredAccounts';
import {useDebounce} from 'usehooks-ts';
import {useAccountTypeahead} from '../hooks/api/accountTypeahead';
import {AccountTypeaheadResponse} from '../shared/api/responses';
import _ from 'lodash';
import {ObjectId} from 'bson';
import {SearchDropdownIndicator} from './SearchDropdownIndicator';
import {useQueryClient} from '@tanstack/react-query';
import {AccountWithLogoQueryKeys} from '../hooks/api/accountWithLogo';

const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    border: '1px solid #EBE8EA',
    borderRadius: '10px',
    padding: '3px',
    marginRight: '0px',
    fontSize: '16px',
    flexDirection: 'row-reverse',
    height: '50px',
    fontWeight: '300',
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '16px',
    fontWeight: '300',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0, // remove padding around the list
  }),
  menuPortal: (base) => ({...base, zIndex: 9999}),
} satisfies StylesConfig;

const formatOptionLabel = (data: {value: AccountWithLogo}) => (
  <Box
    display="flex"
    alignItems="center"
    mt="0"
    key={data.value._id.toString()}
  >
    <Image
      src={data.value.logoUrl}
      alt="icon"
      boxSize="32px"
      mr="2"
      ml="1"
      fallbackSrc="/accountFallback.png"
    />
    <Text fontWeight="thin" fontSize="14px">
      {data.value.name}
    </Text>
    <Text ml="2" fontWeight="thin" color="gray.500" fontSize="14px">
      {data.value.domain}
    </Text>
  </Box>
);

export const AccountTypeahead = ({
  isDisabled,
  onSelect,
  placeholder,
  selected,
  disabledTooltip,
  props,
}: {
  isDisabled?: boolean;
  onSelect: (accountId: ObjectId) => void;
  placeholder?: string;
  selected: ObjectId[];
  disabledTooltip?: string;
  props?: BoxProps;
}) => {
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce<string>(search, 250);
  const queryClient = useQueryClient();

  const accountTypeaheadQuery = useAccountTypeahead(debouncedSearch);

  const [typeaheadAccounts, setTypeaheadAccounts] =
    useState<AccountTypeaheadResponse>([]);

  // don't render empty typeahead while data is loading (causes flickering)
  useEffect(() => {
    if (
      accountTypeaheadQuery.isPending ||
      _.isEqual(accountTypeaheadQuery.data, typeaheadAccounts)
    ) {
      return;
    }

    setTypeaheadAccounts(accountTypeaheadQuery.data ?? []);
  }, [
    accountTypeaheadQuery.data,
    accountTypeaheadQuery.isPending,
    typeaheadAccounts,
  ]);

  return (
    <Tooltip isDisabled={!isDisabled} label={disabledTooltip}>
      <Box position="sticky" top={0} zIndex={1} bg="white" {...props}>
        <Select
          components={{
            DropdownIndicator: SearchDropdownIndicator,
            IndicatorSeparator: null,
          }}
          controlShouldRenderValue={false}
          formatOptionLabel={formatOptionLabel}
          hideSelectedOptions={true}
          inputValue={search}
          isClearable={false}
          isDisabled={isDisabled}
          isMulti={false}
          isOptionSelected={(option) =>
            selected.some((selectedAccount) =>
              selectedAccount.equals(option.value._id)
            )
          }
          menuPortalTarget={document.body}
          noOptionsMessage={() => null}
          onInputChange={setSearch}
          onChange={(newValue) => {
            if (newValue) {
              const accountId = newValue.value._id;
              // Precache the account with logo
              queryClient.setQueryData(
                AccountWithLogoQueryKeys.account(accountId),
                newValue.value
              );
              onSelect(accountId);
            }
          }}
          options={typeaheadAccounts.map((account) => ({
            value: account,
            label: account.domain,
          }))}
          placeholder={placeholder}
          styles={customSelectStyles}
        />
      </Box>
    </Tooltip>
  );
};
