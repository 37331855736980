import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {useKeyplayApi} from '../../context/ApiContext';
import {
  IntegrationResponse,
  IntegrationsResponse,
  UpdateIntegrationRequest,
} from '../../shared/api/api';
import {keys} from '../../shared/util';
import {IntegrationType} from '../../shared/integrations';
import {CrmField} from '../../shared/integrations';
import {useTaskStatus} from './tasks';
import {TaskState} from '../../shared/task';
import {AllIntegrations} from '../../components/Integrations/AllIntegrations';
import {useIsFreemiumCustomer} from './metadata';
import {ApiQueryOptions, useApiMutation} from './api';
import {
  DeleteIntegration,
  DisconnectIntegration,
} from '../../shared/api/integrations';

const integrationKeys = {
  all: ['integrations'],
  get: (name?: string) => [...integrationKeys.all, name],
} as const;

export function useIntegrations(
  options?: ApiQueryOptions<IntegrationsResponse>
) {
  const isFreemiumCustomer = useIsFreemiumCustomer();
  const makeApiCall = useKeyplayApi();

  return useQuery({
    queryKey: integrationKeys.all,
    queryFn: () => makeApiCall<IntegrationsResponse>('/integrations'),
    ...options,
    enabled: !isFreemiumCustomer,
  });
}

export function useEnabledIntegration() {
  const {data, isPending, isError} = useIntegrations();
  if (isPending || isError) {
    return undefined;
  }

  return data[keys(data)[0]];
}

export function useIntegration(name: IntegrationType) {
  const makeApiCall = useKeyplayApi();

  return useQuery({
    queryKey: integrationKeys.get(name),
    queryFn: () => makeApiCall<IntegrationResponse>(`/integrations/${name}`),
  });
}

export function useUpdateIntegration(name: IntegrationType) {
  const makeApiCall = useKeyplayApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: UpdateIntegrationRequest) =>
      makeApiCall(
        `/integrations/${name}`,
        {
          method: 'POST',
          data,
        },
        {
          toastOnError: true,
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: integrationKeys.get(name),
      });
    },
  });
}

export function useQueueFullCrmRun() {
  const makeApiCall = useKeyplayApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (name: IntegrationType) =>
      makeApiCall(
        `/integrations/${name}/enrich`,
        {
          method: 'POST',
        },
        {
          toastOnError: true,
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['taskStatus'],
      });
    },
  });
}

export function useCrmFields(name: IntegrationType) {
  const makeApiCall = useKeyplayApi();

  return useQuery({
    queryKey: ['integration', 'crmFields', name],
    queryFn: () => makeApiCall<CrmField[]>(`/integrations/${name}/crmFields`),
    refetchOnWindowFocus: false,
  });
}

export function useIsCrmSyncing(options?: {
  onSyncComplete?: (taskState: TaskState) => void;
}) {
  const integration = useEnabledIntegration();

  const {isTaskInProgress} = useTaskStatus({
    taskType: integration
      ? AllIntegrations[integration.type].taskType
      : undefined,
    onTaskComplete: options?.onSyncComplete,
  });

  return isTaskInProgress;
}

export function useDisconnectIntegration() {
  const queryClient = useQueryClient();
  return useApiMutation('/integrations/disconnect', DisconnectIntegration, {
    onSuccess: (_, name) => {
      queryClient.invalidateQueries({
        queryKey: integrationKeys.get(name),
      });
    },
  });
}

export function useDeleteIntegration() {
  const queryClient = useQueryClient();
  return useApiMutation('/integrations/delete', DeleteIntegration, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: integrationKeys.all,
      });
    },
  });
}
