import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  ModalFooter,
  Button,
  Grid,
  GridItem,
  AbsoluteCenter,
  useToast,
  Link,
} from '@chakra-ui/react';
import {
  useCrmFields,
  useEnabledIntegration,
} from '../../hooks/api/integrations';
import {IntegrationResponse} from '../../shared/api/api';
import Select from 'react-select';
import {create} from 'zustand';
import {CrmField, isAllowedCrmFieldType} from '../../shared/integrations';
import {useCreateFieldDefinition} from '../../hooks/api/fieldDefinitions';
import {useCustomer} from '../../hooks/api/metadata';

interface CrmFieldStore {
  selectedCrmField: CrmField | null;
  setSelectedCrmField: (field: CrmField | null) => void;
}

const useCrmFieldStore = create<CrmFieldStore>((set) => ({
  selectedCrmField: null,
  setSelectedCrmField: (field) => set({selectedCrmField: field}),
}));

export const CrmFieldModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const integration = useEnabledIntegration();
  const {setSelectedCrmField} = useCrmFieldStore();
  const close = () => {
    setSelectedCrmField(null);
    onClose();
  };

  return (
    <Modal isCentered={true} isOpen={isOpen} onClose={close} size="2xl">
      <ModalOverlay />
      {integration ? (
        <CrmFieldModalContent integration={integration} onClose={close} />
      ) : (
        <ModalContent>
          <ModalHeader fontWeight="normal">Select a CRM Field</ModalHeader>
          <ModalBody minH="200px">
            <AbsoluteCenter textColor="kgray.300">
              Please connect a CRM integration to create CRM custom fields.
            </AbsoluteCenter>
          </ModalBody>
          <ModalCloseButton />
        </ModalContent>
      )}
    </Modal>
  );
};

const CrmFieldModalContent = ({
  integration,
  onClose,
}: {
  integration: IntegrationResponse;
  onClose: () => void;
}) => {
  const {selectedCrmField} = useCrmFieldStore();
  const createFieldDefinition = useCreateFieldDefinition();
  const toast = useToast();

  const saveCrmField = () => {
    if (!selectedCrmField || !isAllowedCrmFieldType(selectedCrmField.type)) {
      toast({
        title: 'Invalid CRM Field',
        description: 'Please select a valid CRM field.',
        status: 'error',
        duration: 5_000,
        isClosable: true,
      });
      return;
    }

    createFieldDefinition.mutate(
      {
        type: 'crm',
        config: {
          fieldName: selectedCrmField.name,
          integrationId: integration.id,
        },
        dataType: selectedCrmField.type,
        label: selectedCrmField.label,
        status: 'published',
      },
      {onSuccess: onClose}
    );
  };

  return (
    <ModalContent>
      <ModalHeader fontWeight="normal" mb="2">
        <Text fontSize="xl" mb="1">
          Select a CRM Field
        </Text>
        <Text fontSize="sm" textColor="kgray.300">
          Learn more about how to create custom CRM Signals{' '}
          <Link
            href="https://docs.keyplay.io/en/"
            isExternal
            textColor="kblue.300"
          >
            here
          </Link>
          .
        </Text>
      </ModalHeader>
      <ModalBody minH="200px">
        <CrmFieldSelect integration={integration} />
      </ModalBody>
      <ModalCloseButton />
      <ModalFooter
        borderTop="1px solid"
        borderColor="kgray.200"
        justifyContent="start"
      >
        <Button
          colorScheme="kbuttonblue"
          isDisabled={!selectedCrmField}
          onClick={saveCrmField}
        >
          Save
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};

const CrmFieldSelect = ({integration}: {integration: IntegrationResponse}) => {
  const {fieldDefinitions} = useCustomer();
  const crmFields = useCrmFields(integration.type);
  const {selectedCrmField, setSelectedCrmField} = useCrmFieldStore();

  const options =
    crmFields.data?.map((crmField) => {
      const isFieldDefined = fieldDefinitions?.some(
        ({type, config}) => type === 'crm' && config.fieldName === crmField.name
      );
      return {
        isDisabled: !isAllowedCrmFieldType(crmField.type) || isFieldDefined,
        value: crmField.name,
        label: crmField.label,

        crmField,
      };
    }) ?? [];

  return (
    <Grid
      templateColumns="1fr 200px"
      columnGap={8}
      rowGap={2}
      alignItems="baseline"
    >
      <GridItem fontSize="sm" textColor="kgray.300" textTransform="uppercase">
        Crm Field
      </GridItem>
      <GridItem fontSize="sm" textColor="kgray.300" textTransform="uppercase">
        Field Type
      </GridItem>

      <GridItem>
        <Select
          isLoading={crmFields.isPending}
          isMulti={false}
          onChange={(newValue) => {
            if (!newValue) {
              return;
            }

            const {crmField} = newValue;
            setSelectedCrmField(crmField);
          }}
          options={options}
          placeholder="Select a CRM Field"
          value={options.find(
            (option) => option.value === selectedCrmField?.name
          )}
        />
      </GridItem>
      <GridItem>{selectedCrmField?.type ?? '-'}</GridItem>
    </Grid>
  );
};
