import React, {Fragment, useEffect, useState} from 'react';
import {
  Box,
  Button,
  CloseButton,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Spacer,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {
  Download,
  Export,
  Grid as GridIcon,
  ImportExport,
  Search,
} from '@carbon/icons-react';
import {PrimaryAccountSortField} from '../../shared/api/api';
import _ from 'lodash';
import {ScoredAccount} from '../../shared/scoredAccounts';
import {Table} from '@tanstack/react-table';
import {useIsMyAccountsView, useUserControls} from './AccountGrid.controlstate';
import {RecommendedAccountsImportModal} from '../Accounts/RecommendedAccountsImport';
import {
  useIsFeatureEnabled,
  useIsFreeOrListBuilderCustomer,
} from '../../hooks/api/metadata';
import {CRMImportModal} from '../Accounts/CRMImportModal';
import {useAuth0} from '@auth0/auth0-react';
import {isKeyplayAdmin} from '../../lib/user';
import {FilterControl} from './FilterControl';
import {useTaskStatus} from '../../hooks/api/tasks';
import {isToday, lightFormat} from 'date-fns';
import {useScoringInfo} from '../../hooks/api/scoringInfo';
import {track} from '../../analytics';
import {ListBuilderExportModal} from './export/ListBuilderExportModal';
import {ScoringInfoResponse} from '../../shared/api/responses';
import {ActionButtons} from './ActionButtons';
import {useAccountView} from './AccountGrid';
import {ExportModal} from './export/ExportModal';
import {ClayExportModal} from './export/ClayExportModal';
import {MaxClayExportSize} from '../../shared/exports';
import {DocumentDownload} from '@carbon/icons-react';
import {HeaderButton} from './header/HeaderButton';
import {EnrichButton} from './header/EnrichButton';
import {useIsKeyplayAdmin} from '../../hooks/api/user';

interface AccountsTableHeaderProps {
  table: Table<ScoredAccount>;
  totalFetched: number | undefined;
  numberOfAccounts: number | undefined;
  fetchedAccounts: ScoredAccount[];
}

export const AccountsTableHeader = ({
  fetchedAccounts,
  numberOfAccounts,
  table,
}: AccountsTableHeaderProps) => {
  const isSelectiveSyncEnabled = useIsFeatureEnabled('selectiveSync');
  const accountView = useAccountView();
  const userControls = useUserControls();
  const {getNumberOfSelectedAccounts} = userControls;
  const isKeyplayAdmin = useIsKeyplayAdmin();

  const toast = useToast();
  const recommendedAccountsImportModal = useDisclosure();
  const crmImportModal = useDisclosure();
  const {data: scoringInfo} = useScoringInfo();

  // selecting rows for bulk actions
  const numSelected = getNumberOfSelectedAccounts();

  const countSnippet = `${numberOfAccounts?.toLocaleString()} accounts`;
  const selectedSnippet =
    numSelected > 0 ? `${numSelected.toLocaleString()} selected` : '';

  const latestRefreshTimestamp = scoringInfo?.latestRefreshTimestamp;
  const lastScoredMessage = latestRefreshTimestamp
    ? `
        Last updated
        ${
          isToday(latestRefreshTimestamp)
            ? ' Today '
            : lightFormat(latestRefreshTimestamp, ' M/dd ')
        }
        at
        ${lightFormat(latestRefreshTimestamp, ' h:mm a')}`
    : null;

  const {
    taskStatus: fileCrmImportStatus,
    isTaskInProgress: crmImportInProgress,
  } = useTaskStatus({
    enabled: accountView === 'crm',
    taskType: 'fileCRMImport',
    onTaskComplete: (taskState) => {
      // Show a friendly toast message if the user is still on the page when
      // the import finishes.
      if (taskState === 'done') {
        toast({
          status: 'success',
          title: 'CRM import complete!',
        });
      }
    },
  });

  return (
    <Flex alignItems="center" px={4} gap={1}>
      <Flex direction="column">
        <Tooltip hasArrow label={lastScoredMessage} placement="top-end">
          <Box color="kgray.300" mr={4} whiteSpace="nowrap">
            {countSnippet}
          </Box>
        </Tooltip>
        <Box
          display={!selectedSnippet ? 'none' : ''}
          color="kblue.200"
          mb="-18px"
          mr={4}
          whiteSpace="nowrap"
        >
          {selectedSnippet}
        </Box>
      </Flex>

      <ActionButtons
        fetchedAccounts={fetchedAccounts}
        numSelected={numSelected}
      />

      <Spacer />

      {isKeyplayAdmin && <EnrichButton />}

      <ExportButton
        numberOfAccounts={numberOfAccounts}
        scoringInfo={scoringInfo}
      />

      {accountView === 'imported' && (
        <>
          <HeaderButton
            onClick={recommendedAccountsImportModal.onOpen}
            leftIcon={
              <Box>
                <Download />
              </Box>
            }
          >
            Import
            <RecommendedAccountsImportModal
              isOpen={recommendedAccountsImportModal.isOpen}
              onClose={recommendedAccountsImportModal.onClose}
            />
          </HeaderButton>
        </>
      )}
      {accountView === 'crm' && isSelectiveSyncEnabled && (
        <Tooltip
          hasArrow
          key="crmImportTooltip"
          placement="bottom-start"
          label={
            fileCrmImportStatus.isPending || crmImportInProgress
              ? 'New imports disabled while current import is being processed.'
              : ''
          }
        >
          <HeaderButton
            onClick={crmImportModal.onOpen}
            isDisabled={fileCrmImportStatus.isPending || crmImportInProgress}
            leftIcon={
              <Box>
                <Download />
              </Box>
            }
          >
            Import
            <CRMImportModal
              isOpen={crmImportModal.isOpen}
              onClose={async (taskId) => {
                if (taskId) {
                  await fileCrmImportStatus.refetch();
                }
                crmImportModal.onClose();
              }}
            />
          </HeaderButton>
        </Tooltip>
      )}
      <SortControl />
      <FilterControl />
      <ColumnVisibilityControl table={table} />
      <SearchControl />
    </Flex>
  );
};

const defaultSortOptions = {
  overallFit: {
    label: 'Overall Fit',
    asc: 'Worst',
    desc: 'Best',
  },
} as const;

const savedTabSortOptions = {
  ...defaultSortOptions,
  saveDate: {
    label: 'Saved On',
    asc: 'Oldest',
    desc: 'Newest',
  },
};

const ExportButton = ({
  numberOfAccounts,
  scoringInfo,
}: {
  numberOfAccounts?: number;
  scoringInfo?: ScoringInfoResponse;
}) => {
  const {user} = useAuth0();
  const isMyAccountsView = useIsMyAccountsView();
  const isFreeOrListBuilderCustomer = useIsFreeOrListBuilderCustomer();
  const csvExportModal = useDisclosure();
  const clayExportModal = useDisclosure();
  const listBuilderCsvExportModal = useDisclosure();

  const exportsDisabled = scoringInfo?.isRescoring || numberOfAccounts === 0;
  const clayExportDisabled = (numberOfAccounts ?? 0) > MaxClayExportSize;

  let button;
  if (!isMyAccountsView) {
    if (!isKeyplayAdmin(user)) {
      return null;
    }

    button = (
      <HeaderButton
        alignSelf="center"
        flexShrink="0"
        leftIcon={<Export />}
        isDisabled={exportsDisabled}
        onClick={csvExportModal.onOpen}
      >
        Export
      </HeaderButton>
    );
  } else {
    button = isFreeOrListBuilderCustomer ? (
      <Menu placement="bottom-start">
        <MenuButton
          as={HeaderButton}
          alignSelf="center"
          flexShrink="0"
          leftIcon={<Export />}
          isDisabled={exportsDisabled}
          colorScheme={'kbuttonblue'}
          variant={'glow'}
        >
          Export
        </MenuButton>
        <MenuList zIndex={2} minWidth={32}>
          <MenuItem
            onClick={() => {
              track('exportListClicked', {user});
              listBuilderCsvExportModal.onOpen();
            }}
            pl={2}
          >
            <DocumentDownload style={{marginRight: 8}} />
            CSV
          </MenuItem>
          <Tooltip
            label={`Clay export supports a maximum of ${MaxClayExportSize} accounts (${numberOfAccounts} currently selected)`}
            isDisabled={!clayExportDisabled}
            placement="right"
          >
            <MenuItem
              isDisabled={clayExportDisabled}
              onClick={clayExportModal.onOpen}
              pl={2}
            >
              <Image boxSize="16px" src="/clay_logo.svg" mr="8px" />
              Clay
            </MenuItem>
          </Tooltip>
        </MenuList>
      </Menu>
    ) : (
      <Menu placement="bottom-start">
        <MenuButton
          as={HeaderButton}
          alignSelf="center"
          flexShrink="0"
          leftIcon={<Export />}
          isDisabled={exportsDisabled}
        >
          Export
        </MenuButton>
        <MenuList zIndex={2} minWidth={32}>
          <MenuItem onClick={csvExportModal.onOpen} pl={2}>
            <DocumentDownload style={{marginRight: 8}} />
            CSV
          </MenuItem>
          <Tooltip
            label={`Clay export supports a maximum of ${MaxClayExportSize} accounts (${numberOfAccounts} currently selected)`}
            isDisabled={!clayExportDisabled}
            placement="right"
          >
            <MenuItem
              isDisabled={clayExportDisabled}
              onClick={clayExportModal.onOpen}
              pl={2}
            >
              <Image boxSize="16px" src="/clay_logo.svg" mr="8px" />
              Clay
            </MenuItem>
          </Tooltip>
        </MenuList>
      </Menu>
    );
  }

  return (
    <>
      <Tooltip
        hasArrow
        isDisabled={!scoringInfo?.isRescoring}
        label="Please wait until the account refresh completes."
        placement="top"
        shouldWrapChildren={true}
      >
        {button}
      </Tooltip>
      <ListBuilderExportModal
        isOpen={listBuilderCsvExportModal.isOpen}
        onClose={listBuilderCsvExportModal.onClose}
        numberOfAccounts={numberOfAccounts ?? 0}
      />
      <ExportModal
        isOpen={csvExportModal.isOpen}
        onClose={csvExportModal.onClose}
        numberOfAccounts={numberOfAccounts}
      />
      <ClayExportModal
        isOpen={clayExportModal.isOpen}
        onClose={clayExportModal.onClose}
        numberOfAccounts={numberOfAccounts ?? 0}
      />
    </>
  );
};

const SortControl = () => {
  const accountView = useAccountView();
  const {sort, setSort} = useUserControls();

  // for some reason using a react fragment breaks the menu (not sure why),
  // so used an array instead
  const sortMenuItems = _.map(
    accountView === 'saved' ? savedTabSortOptions : defaultSortOptions,
    (sortInfo, key) => [
      key !== Object.keys(defaultSortOptions)[0] ? (
        <MenuDivider key={`${key}_divider`} />
      ) : null,
      <Box
        as="p"
        my={2}
        mx={4}
        fontWeight="600"
        fontSize="12px"
        key={`${key}_title}`}
      >
        {sortInfo.label}
      </Box>,
      <MenuItemOption key={`${key}//desc`} value={`${key}//desc`}>
        {sortInfo.desc}
      </MenuItemOption>,
      <MenuItemOption key={`${key}//asc`} value={`${key}//asc`}>
        {sortInfo.asc}
      </MenuItemOption>,
    ]
  );

  return (
    <Menu placement="bottom-start">
      <MenuButton
        as={Button}
        variant="ghost"
        color="kgray.400"
        fontSize="14"
        fontWeight="500"
        leftIcon={
          <Box>
            <ImportExport />
          </Box>
        }
      >
        Sort
      </MenuButton>
      <MenuList zIndex={10} minWidth={32}>
        <MenuOptionGroup
          type="radio"
          value={`${sort.field}//${sort.order}`}
          onChange={async (value) => {
            const [field, order] = (value as string).split('//');
            setSort({
              field: field as PrimaryAccountSortField,
              order: order as 'asc' | 'desc',
            });
          }}
        >
          {sortMenuItems}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};
const ColumnVisibilityControl = ({table}: {table: Table<ScoredAccount>}) => {
  // if I want to add an all control
  // checked: table.getIsAllColumnsVisible(),
  // onChange: table.getToggleAllColumnsVisibilityHandler(),

  const items = table
    .getAllLeafColumns()
    .filter((c) => !['select', 'account'].includes(c.id))
    .map((column) => {
      return (
        <MenuItemOption
          key={column.id}
          value={column.id}
          isChecked={column.getIsVisible()}
          onClick={column.getToggleVisibilityHandler()}
        >
          {typeof column.columnDef.header === 'string'
            ? column.columnDef.header
            : column.id}
        </MenuItemOption>
      );
    });

  const button = (
    <MenuButton
      as={Button}
      variant="ghost"
      color="kgray.400"
      fontSize="14"
      fontWeight="500"
      leftIcon={
        <Box>
          <GridIcon />
        </Box>
      }
    >
      Columns
    </MenuButton>
  );

  return (
    <Menu closeOnSelect={false}>
      {button}
      <MenuList zIndex={10}>{items}</MenuList>
    </Menu>
  );
};

const SearchControl = () => {
  const {search, setSearch} = useUserControls();
  const accountView = useAccountView();
  const [text, setText] = useState('');

  // we want the initial value of our search field to be the current
  // search term, if it exists. This handles two cases: when the
  // gridView changes, and when we recreate this element from scratch
  // because of navigating away and back
  useEffect(() => {
    setText(search);
  }, [accountView, search]);

  const onSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setSearch(text);
    }
  };

  const onClear = () => {
    setText('');
    setSearch('');
  };

  return (
    <InputGroup maxWidth="225px" ml={4}>
      <InputLeftElement pointerEvents="none">
        <Search style={{color: '#76767E'}} />
      </InputLeftElement>
      <Input
        placeholder="Search"
        onChange={(e) => setText(e.target.value)}
        onKeyDown={onSubmit}
        value={text}
      />
      {search && (
        <InputRightElement>
          <CloseButton onClick={onClear} />
        </InputRightElement>
      )}
    </InputGroup>
  );
};
