import React, {useState} from 'react';
import {
  Box,
  Flex,
  Divider,
  Tooltip,
  Button,
  Radio,
  Stack,
  RadioGroup,
  BoxProps,
  useToast,
} from '@chakra-ui/react';
import {useOnboardingContext} from './Onboarding';
import {ScrollableFlex, WrapperFlex} from '../utils/scrolling';
import {KeyplayScoringSignal} from '../../shared/signals';
import {SignalsRenderer} from '../AccountGrid/AccountGrid.components';
import {CompanyType} from '../../shared/onboarding';
import {useSetOnboardingTemplate} from '../../hooks/api/onboarding';

const companyTypes = [
  {
    type: 'growingVcBacked',
    label: 'Growing, VC-backed software companies',
    highlights: [
      'fundingOver1M',
      'sixMonthGrowthPositive',
      'hiring',
      'usingModernDataStack',
    ],
  },
  {
    type: 'b2cBrands',
    label: 'B2C brands with strong digital presence',
    highlights: [
      'b2c',
      'hiringMarketing',
      'trafficRankHigh',
      'usingMultipleDigitalAds',
    ],
  },
  {
    type: 'b2bBusinesses',
    label: 'B2B businesses with modern GTM strategy',
    highlights: [
      'b2b',
      'hiringSdrBdr',
      'productLed',
      'usingB2bMarketingAutomation',
    ],
  },
  {
    type: 'cultureConscious',
    label: 'Culture-conscious orgs using HR Tech',
    highlights: [
      'multipleLocations',
      'hiringRemote',
      'cultureContent',
      'usingAts',
    ],
  },
  {
    type: 'securityAndPrivacy',
    label: 'Security & privacy focused companies',
    highlights: [
      'internationalPresence',
      'hiringSecurity',
      'jobsMentionSoc2',
      'usingPublicCloud',
    ],
  },
  {
    type: 'traditionalIndustries',
    label: 'Mature companies with legacy tech',
    highlights: [
      'founded1950to1979',
      'hiring',
      'jobsMentionDigitalTransformation',
      'usingWorkday',
    ],
  },
  {
    type: 'aiPropensity',
    label: 'Companies with propensity to invest in AI',
    highlights: [
      'hiringMachineLearning',
      'hiringDataScience',
      'aiMessaging',
      'usingDatabricks',
    ],
  },
] satisfies {
  type: CompanyType;
  label: string;
  highlights: KeyplayScoringSignal[];
}[];

const buttonLinkWrapperStyle: (active: boolean) => BoxProps = (active) => ({
  outline: 'transparent solid 2px',
  outlineOffset: '2px',
  borderWidth: 1,
  borderRadius: '8px',
  _hover: {
    textDecoration: 'none',
    bg: 'kblue.50',
  },
  backgroundColor: active ? 'kblue.50' : 'none',
  borderColor: active ? 'kblue.400' : 'kgray.200',
});

export const TemplatePicker = () => {
  const {onPageNavigate, isFirstStep} = useOnboardingContext();
  const toast = useToast();
  const setTemplate = useSetOnboardingTemplate();
  const [companyType, setCompanyType] = useState<CompanyType | null>(null);

  const onNext = async () => {
    if (!companyType) {
      return;
    }

    setTemplate.mutate(
      {type: companyType},
      {
        onSuccess: () => {
          onPageNavigate('next');
        },

        onError: () => {
          toast({
            title: 'Error',
            description: 'Failed to set onboarding template',
            status: 'error',
            duration: 5_000,
            isClosable: true,
          });
        },
      }
    );
  };

  return (
    <WrapperFlex>
      <ScrollableFlex px="20%" pt={4}>
        <Box fontSize="2xl" mb={5}>
          Now, pick a scoring template for your ICP
        </Box>
        <Box color="kgray.300" mb={10}>
          We&apos;ll use this to build and score your first account list. You
          can edit your ICP model later.
        </Box>
        <Flex align="start">
          <Box flex="1">
            <RadioGroup onChange={(val) => setCompanyType(val as CompanyType)}>
              <Stack spacing={4} direction="column" width="100%">
                {companyTypes.map(({type, label, highlights}) => (
                  <Box
                    key={type}
                    flex="1"
                    {...buttonLinkWrapperStyle(companyType === type)}
                  >
                    <Radio value={type} px={4} py={5} width="100%">
                      <Box ml={2}>
                        <Box fontSize="xl" mb={2} ml={1}>
                          {label}
                        </Box>
                        <SignalsRenderer signals={highlights} />
                      </Box>
                    </Radio>
                  </Box>
                ))}
              </Stack>
            </RadioGroup>
          </Box>
        </Flex>{' '}
      </ScrollableFlex>

      <Divider />

      <Flex justifyContent={'space-between'} w={'100%'} px={10} py={4}>
        {!isFirstStep && (
          <Button
            colorScheme="kbuttonblue"
            onClick={() => onPageNavigate('prev')}
            variant="outline"
            w="110px"
          >
            Back
          </Button>
        )}
        <Box flexGrow={1} />
        <Tooltip
          isDisabled={!!companyType}
          label="Pick a company size and type to continue"
          placement="top-start"
        >
          <Button
            colorScheme="kbuttonblue"
            isDisabled={!companyType || setTemplate.isPending}
            isLoading={setTemplate.isPending}
            onClick={onNext}
            variant="solid"
            w="110px"
          >
            Continue
          </Button>
        </Tooltip>
      </Flex>
    </WrapperFlex>
  );
};
