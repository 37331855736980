import {useQuery} from '@tanstack/react-query';
import {useKeyplayApi} from '../../context/ApiContext';
import {UnsyncedCountResponse} from '../../shared/api/responses';
import {ObjectId} from 'bson';

export function useUnsyncedCount({
  enabled,
  runId,
}: {
  enabled?: boolean;
  runId: ObjectId;
}) {
  const makeApiCall = useKeyplayApi();
  return useQuery({
    queryKey: ['unsyncedCount', runId?.toString()],
    queryFn: () =>
      makeApiCall<UnsyncedCountResponse>(
        '/scoredAccounts/unsyncedCount',
        {
          params: {runId},
          method: 'GET',
        },
        {toastOnError: true}
      ),
    enabled: enabled && !!runId,
  });
}
