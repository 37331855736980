import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
  Box,
  VStack,
  Button,
  Link,
  FlexProps,
} from '@chakra-ui/react';
import _ from 'lodash';
import {PropsWithChildren} from 'react';
import {Underline} from '../../text';
import {useExportMutation} from '../useExportMutation';
import {pluralize} from '../../../lib/helpers';
import {useAccountQueryParams} from '../AccountGrid.controlstate';

interface ExportListModalProps {
  isOpen: boolean;
  onClose: () => void;
  numberOfAccounts: number;
}

export const ListBuilderExportModal = ({
  isOpen,
  onClose,
  numberOfAccounts,
}: ExportListModalProps) => {
  return (
    <Modal isCentered={true} isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent p={0} overflow="hidden">
        <ModalCloseButton />
        <ConfirmExportPage
          onClose={onClose}
          numberOfAccounts={numberOfAccounts}
        />
      </ModalContent>
    </Modal>
  );
};

const ModalSection = ({children, ...props}: PropsWithChildren<FlexProps>) => {
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      direction="row"
      gap={2}
      px={10}
      py={4}
      width="100%"
      {...props}
    >
      {children}
    </Flex>
  );
};

const buttonWidth = 150;

const ConfirmExportPage = ({
  onClose,
  numberOfAccounts,
}: {
  onClose: () => void;
  numberOfAccounts: number;
}) => {
  const query = useAccountQueryParams();
  const exportAccounts = useExportMutation({
    onSuccess: () => {
      onClose();
    },
  });

  return (
    <>
      <ModalHeader>
        <Flex direction="column" alignItems="center">
          <Box fontWeight="medium">Export Your Saved Accounts</Box>
        </Flex>
      </ModalHeader>
      <ModalBody display="flex" flexDir="column" p={0} mb={8} gap={1}>
        <ModalSection>
          <VStack alignItems="start">
            <Box fontSize="lg" fontWeight="bold">
              Export your accounts
            </Box>
            <Box>
              Download {pluralize(numberOfAccounts, 'account', ' accounts')}{' '}
              matching your current filters.
            </Box>
          </VStack>
          <Button
            colorScheme="kbuttonblue"
            fontWeight="normal"
            onClick={() =>
              exportAccounts.mutate({
                query,
                type: 'filtered_accounts',
                mode: 'async',
              })
            }
            isDisabled={exportAccounts.isPending}
            width={buttonWidth}
          >
            Download CSV
          </Button>
        </ModalSection>

        <ModalSection>
          <VStack alignItems="start">
            <Box fontSize="lg" fontWeight="bold">
              Sync to HubSpot
            </Box>
            <Box>List Builder plan includes 1-way HubSpot sync</Box>
          </VStack>
          <Button
            as={Link}
            colorScheme="kbuttonblue"
            fontWeight="normal"
            href="https://docs.keyplay.io/en/articles/8712913-hubspot-integration"
            isExternal
            width={buttonWidth}
            variant="outline"
          >
            Learn More
          </Button>
        </ModalSection>

        <ModalSection>
          <VStack alignItems="start">
            <Box fontSize="lg" fontWeight="bold">
              Sync to Salesforce
            </Box>
            <Box>
              Learn about 2-way SFDC sync, full enrichment, and custom signals
              on the <Underline>Keyplay Growth Plan</Underline>
            </Box>
          </VStack>
          <VStack>
            <Button
              as={Link}
              colorScheme="kbuttonblue"
              fontWeight="normal"
              href="https://keyplay.io/demo/"
              isExternal
              variant="outline"
              width={buttonWidth}
            >
              Request a Demo
            </Button>
            <Link
              color="kgray.300"
              fontSize={'sm'}
              href="https://keyplay.io/pricing/"
              target="_blank"
            >
              see plans &amp; pricing
            </Link>
          </VStack>
        </ModalSection>
      </ModalBody>
    </>
  );
};
