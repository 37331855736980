import {ObjectId} from 'bson';
import {createStore, StoreApi, useStore} from 'zustand';
import {AiFieldDefinition, isFieldDefinition} from '../../../shared/enrichment';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useSaveFieldDefinition} from '../../../hooks/api/fieldDefinitions';
import {useFieldDefinitionFromContext} from './FieldDefinitionContext';
import {fromEntries} from '../../../shared/util';
import _ from 'lodash';
import produce from 'immer';
import {useSelectedVersionStore} from './SelectedVersion.state';
import {EnrichmentPreviewResultQueryKeys} from '../../../hooks/api/fieldDefinitionPreview';

interface AiFieldBuilderState {
  updateServerState: ({
    fieldDefinition,
    updateMode,
  }: {
    fieldDefinition: AiFieldDefinition;
    updateMode: 'always' | 'ifNoChanges';
  }) => void;
  serverState: AiFieldDefinition;

  hasChanges: () => boolean;

  label: string;
  setLabel: (label: string) => void;

  selectedAccountIds: ObjectId[];
  addAccount: (accountId: ObjectId) => void;
  removeAccount: (accountId: ObjectId) => void;

  versions: {id: ObjectId; label: string; timestamp: Date}[];
  versionConfigs: Record<string, AiFieldDefinition['config']>;
  updateVersionConfig: (
    versionId: ObjectId,
    config: AiFieldDefinition['config']
  ) => void;
}

const createAiFieldBuilderStore = (fieldDefinition: AiFieldDefinition) =>
  createStore<AiFieldBuilderState>()((set, get) => ({
    updateServerState: ({fieldDefinition, updateMode}) => {
      if (get().hasChanges() && updateMode === 'ifNoChanges') {
        return;
      }

      set({
        serverState: fieldDefinition,
        label: fieldDefinition.label,
        selectedAccountIds: fieldDefinition.preview?.accounts ?? [],
        versions:
          fieldDefinition.preview?.versions.map((v) =>
            _.pick(v, ['id', 'label', 'timestamp'])
          ) ?? [],
        versionConfigs: fromEntries(
          fieldDefinition.preview?.versions.map((version) => [
            version.id.toString(),
            version.config,
          ]) ?? []
        ),
      });
    },
    serverState: fieldDefinition,

    hasChanges: () => {
      const {label, serverState, selectedAccountIds, versions, versionConfigs} =
        get();
      const localVersions = versions.map((version) => ({
        ...version,
        config: versionConfigs[version.id.toString()],
      }));

      return (
        label !== serverState.label ||
        !_.isEqual(serverState.preview?.accounts, selectedAccountIds) ||
        !_.isEqual(serverState.preview?.versions, localVersions)
      );
    },

    label: fieldDefinition.label,
    setLabel: (label) => set({label}),

    selectedAccountIds: fieldDefinition.preview?.accounts.map((id) => id) ?? [],
    addAccount: (accountId) => {
      const {selectedAccountIds} = get();
      if (selectedAccountIds.some((id) => id.equals(accountId))) {
        return;
      }

      set({selectedAccountIds: [...selectedAccountIds, accountId]});
    },
    removeAccount: (accountId) =>
      set({
        selectedAccountIds: get().selectedAccountIds.filter(
          (id) => !id.equals(accountId)
        ),
      }),

    versions:
      fieldDefinition.preview?.versions.map((v) =>
        _.pick(v, ['id', 'label', 'timestamp'])
      ) ?? [],
    versionConfigs: fromEntries(
      fieldDefinition.preview?.versions.map((v) => [
        v.id.toString(),
        v.config,
      ]) ?? []
    ),
    updateVersionConfig: (versionId, config) =>
      set(
        produce<AiFieldBuilderState>((state) => {
          state.versionConfigs[versionId.toString()] = config;
        })
      ),
  }));

// Store for each field definition
const aiFieldBuilderStore: Record<string, StoreApi<AiFieldBuilderState>> = {};
export const useAiFieldBuilderStore = () => {
  const fieldDefinition = useFieldDefinitionFromContext();
  const fieldDefinitionId = fieldDefinition.id.toString();
  const queryClient = useQueryClient();

  aiFieldBuilderStore[fieldDefinitionId] ??=
    createAiFieldBuilderStore(fieldDefinition);
  const store = useStore(aiFieldBuilderStore[fieldDefinitionId]);

  const saveFieldDefinition = useSaveFieldDefinition();
  const publishChanges = useMutation({
    mutationFn: async (
      publishMode:
        | {
            mode: 'save';
          }
        | {
            mode: 'publish';
            publishVersionId: ObjectId;
          }
    ) => {
      const fieldDefinition = {
        ...store.serverState,
        label: store.label,
        preview: {
          accounts: store.selectedAccountIds,
          versions: store.versions.map((version) => ({
            ...version,
            config: store.versionConfigs[version.id.toString()],
          })),
        },
      };

      if (!store.hasChanges() && publishMode.mode === 'save') {
        return;
      }

      if (!isFieldDefinition(fieldDefinition)) {
        throw new Error('Invalid field definition');
      }

      return saveFieldDefinition.mutateAsync(
        {
          fieldDefinition,
          ...(publishMode.mode === 'publish'
            ? {publishVersionId: publishMode.publishVersionId}
            : {}),
        },
        {
          onSuccess: async (fieldDefinition) => {
            // TODO: don't invalidate if the only thing that changed was the list
            // of preview accounts?
            await queryClient.invalidateQueries({
              queryKey: EnrichmentPreviewResultQueryKeys.field(
                fieldDefinition.id
              ),
            });

            store.updateServerState({fieldDefinition, updateMode: 'always'});
          },
        }
      );
    },
  });

  return {
    ...store,
    publishChanges,
  };
};

// Helper hook to update the config for the selected version
export function useUpdateSelectedVersionConfig() {
  const {updateVersionConfig} = useAiFieldBuilderStore();
  const {selectedVersionId} = useSelectedVersionStore();

  return (config: AiFieldDefinition['config']) => {
    updateVersionConfig(selectedVersionId, config);
  };
}
