import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import {useKeyplayApi} from '../../context/ApiContext';
import {
  ApiDefinition,
  BatchedApiDefinition,
} from '../../shared/api/definitions';
import {z} from 'zod';
import {captureMessage} from '@sentry/react';
import {Batcher, create, windowedFiniteBatchScheduler} from '@yornaath/batshit';

export type ApiQueryOptions<T> = Omit<
  UseQueryOptions<T>,
  'queryFn' | 'queryKey'
>;

// Factory function to create a query hook for a given API endpoint.
export function createApiQuery<Definition extends ApiDefinition>(
  path: string,
  definition: Definition,
  queryKeyFn: (
    args: z.infer<Definition['requestDataSchema']>
  ) => readonly unknown[],
  defaultOptions?: ApiQueryOptions<
    z.infer<Definition['responseDataSchema']>
  > & {
    toastOnError?: boolean;
  }
) {
  return (
    params: z.infer<Definition['requestDataSchema']> | 'skipToken',
    options?: ApiQueryOptions<z.infer<Definition['responseDataSchema']>>
  ) => {
    const makeApiCall = useKeyplayApi();
    return useQuery({
      queryKey: params !== 'skipToken' ? queryKeyFn(params) : ['skipToken'],
      queryFn: async () => {
        const result = await makeApiCall<Definition['responseDataSchema']>(
          path,
          {
            method: definition.method,
            params,
          },
          {toastOnError: defaultOptions?.toastOnError}
        );

        const safeParse = definition.responseDataSchema.safeParse(result);
        if (!safeParse.success) {
          captureMessage(safeParse.error.message, 'error');
          throw new Error('Invalid response');
        }

        return result;
      },
      ...defaultOptions,
      ...options,
      ...(params !== 'skipToken' ? {} : {enabled: false}),
    });
  };
}

export function createBatchedApiQuery<
  BatchedRequestData,
  BatchedResponseData,
  Item,
  ItemResponseData,
>(
  path: string,
  definition: BatchedApiDefinition<
    BatchedRequestData,
    BatchedResponseData,
    Item,
    ItemResponseData
  >,
  queryKeyFn: (args: Item) => readonly unknown[],
  defaultOptions?: ApiQueryOptions<ItemResponseData | null> & {
    toastOnError?: boolean;
  }
) {
  let batcher: Batcher<BatchedResponseData, Item, ItemResponseData | null>;
  return (params: Item, options?: ApiQueryOptions<ItemResponseData | null>) => {
    const makeApiCall = useKeyplayApi();
    // Create the batcher lazily since we need access to the useKeyplayApi hook
    if (batcher === undefined) {
      batcher = create({
        fetcher: async (args: Item[]) => {
          return makeApiCall<BatchedResponseData, BatchedRequestData>(path, {
            method: definition.method,
            params: definition.createParams(args),
          });
        },
        resolver: (items, item) => {
          const value = definition.resolveItem(items, item);
          if (!value) {
            captureMessage('Item not found in batched response', {
              extra: {path, item, items},
              level: 'error',
            });
          }
          return value;
        },
        scheduler: windowedFiniteBatchScheduler({
          windowMs: 10,
          maxBatchSize: definition.maxBatchSize ?? 100,
        }),
      });
    }

    return useQuery({
      queryKey: queryKeyFn(params),
      queryFn: async () => {
        return batcher.fetch(params);
      },
      ...defaultOptions,
      ...options,
    });
  };
}

export function useApiMutation<Definition extends ApiDefinition>(
  path: string,
  definition: Definition,
  defaultOptions?: UseMutationOptions<
    z.infer<Definition['responseDataSchema']>,
    unknown, // Error type
    z.infer<Definition['requestDataSchema']>
  > & {
    disableToastOnError?: boolean;
  }
) {
  const makeApiCall = useKeyplayApi();
  return useMutation<
    z.infer<Definition['responseDataSchema']>,
    unknown, // Error type
    z.infer<Definition['requestDataSchema']>
  >({
    mutationFn: async (args: z.infer<Definition['requestDataSchema']>) => {
      const result = await makeApiCall<
        z.infer<Definition['requestDataSchema']>
      >(
        path,
        {
          method: definition.method,
          data: args,
        },
        {toastOnError: !defaultOptions?.disableToastOnError}
      );

      const safeParse = definition.responseDataSchema.safeParse(result);
      if (!safeParse.success) {
        captureMessage(safeParse.error.message, 'error');
        throw new Error('Invalid response');
      }

      return result;
    },
    ...defaultOptions,
  });
}
