import {useQuery} from '@tanstack/react-query';
import {useKeyplayApi} from '../../context/ApiContext';
import {getFiltersForView} from '../../shared/api/helpers';
import {ScoredAccountsResponse} from '../../shared/api/responses';

export function useScoringRunCounts() {
  const makeApiCall = useKeyplayApi();
  const {data} = useQuery({
    queryKey: ['scoredAccountsTables'],
    queryFn: async () =>
      makeApiCall<ScoredAccountsResponse>(
        // TODO: probably makes sense to have a separate server endpoint to get these counts?
        '/scoredAccounts',
        {
          params: {
            query: {
              filter: {
                ...getFiltersForView('recommended'),
              },
              sort: {
                field: 'overallFit',
                order: 'desc',
              },
              limit: 1,
            },
          },
        }
      ),
  });

  const discoverCount = data?.totalLength;
  const scoringRun = data?.scoringRun;

  return {
    discoverCount,
    samSize: scoringRun?.samSize,
    numActive: scoringRun?.numActive,
  };
}
