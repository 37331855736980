import {useQuery} from '@tanstack/react-query';
import {useKeyplayApi} from '../../context/ApiContext';
import {AccountSimilarityScoreResponse} from '../../shared/api/responses';
import {ObjectId} from 'bson';

export function useAccountSimilarityScore({
  accountId,
  marketId,
}: {
  accountId?: ObjectId;
  marketId: ObjectId;
}) {
  const keyplayApi = useKeyplayApi();

  return useQuery({
    queryKey: ['accountSimilarityScore', accountId, marketId],
    queryFn: () =>
      keyplayApi<AccountSimilarityScoreResponse>('/accountSimilarityScore', {
        params: {accountId, marketId},
        method: 'GET',
      }),
    refetchOnWindowFocus: false,
    enabled: !!accountId,
  });
}
