import {create} from 'zustand';
import {ObjectId} from 'bson';

interface SelectTestListsState {
  testLists: ObjectId[];

  setTestLists: (testLists: ObjectId[]) => void;
  selectTestList: (testListId: ObjectId) => void;
  deselectTestList: (testListId: ObjectId) => void;
}

export const useSelectTestListStore = create<SelectTestListsState>()((set) => ({
  testLists: [],
  setTestLists: (testLists) => set({testLists}),
  selectTestList: (testListId) =>
    set((state) => ({testLists: [...state.testLists, testListId]})),
  deselectTestList: (testListId) =>
    set((state) => ({
      testLists: state.testLists.filter((id) => !id.equals(testListId)),
    })),
}));
