import {useQuery} from '@tanstack/react-query';
import {useKeyplayApi} from '../../context/ApiContext';
import {AccountTypeaheadResponse} from '../../shared/api/responses';

export function useAccountTypeahead(search: string) {
  const keyplayApi = useKeyplayApi();

  return useQuery({
    queryKey: ['accountTypeahead', search],
    queryFn: () =>
      keyplayApi<AccountTypeaheadResponse>('/accountTypeahead', {
        params: {search},
        method: 'GET',
      }),
    enabled: !!search,
    refetchOnWindowFocus: false,
  });
}
