import React from 'react';
import {useAiFieldBuilderStore} from './AiFieldBuilder.state';
import {useGetEnrichmentPreviewResult} from '../../../hooks/api/fieldDefinitionPreview';
import {
  Box,
  chakra,
  Flex,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import {useAccountWithLogo} from '../../../hooks/api/accountWithLogo';
import {ObjectId} from 'bson';
import _ from 'lodash';
import {useSelectedVersionStore} from './SelectedVersion.state';
import {assertNever} from '../../../shared/util';
import {AiFieldDefinition} from '../../../shared/enrichment';

// TODO: should be shared logic somewhere?
const isConfigEmpty = (config: AiFieldDefinition['config']) => {
  const {fieldType} = config;

  switch (fieldType) {
    case 'boolean':
    case 'rating':
      return !config.prompt.trim();
    case 'category':
      return config.categories.length < 2;
    default:
      assertNever(fieldType);
  }
};

const AiFieldBuilderRow = ({accountId}: {accountId: ObjectId}) => {
  const {serverState} = useAiFieldBuilderStore();
  const {selectedVersionId} = useSelectedVersionStore();

  // need to use server state here, because we don't want query to become enabled
  // before changes are persisted to server
  const selectedVersion = serverState.preview?.versions.find((version) => {
    return version.id.equals(selectedVersionId);
  });

  const config = selectedVersion!.config;

  const result = useGetEnrichmentPreviewResult(
    {
      accountId,
      fieldDefinitionId: serverState.id,
      versionId: selectedVersionId,
    },
    {enabled: !isConfigEmpty(config)}
  );
  if (result.isFetching) {
    return (
      <Tr>
        <Td>
          <AccountCell accountId={accountId} />
        </Td>
        <Td>
          <Skeleton h="2" speed={0} />
        </Td>
        <Td>
          <Skeleton h="2" speed={0} />
        </Td>
      </Tr>
    );
  } else if (!result.data) {
    return (
      <Tr>
        <Td>
          <AccountCell accountId={accountId} />
        </Td>
        <Td colSpan={2} textColor="kgray.300">
          -
        </Td>
      </Tr>
    );
  }

  const {value, reasoning} = result.data;

  return (
    <Tr>
      <Td>
        <AccountCell accountId={accountId} />
      </Td>
      <Td>{_.toString(value)}</Td>
      <Td>{reasoning}</Td>
    </Tr>
  );
};

export const AiFieldBuilderPreviewTable = () => {
  const {selectedAccountIds} = useAiFieldBuilderStore();

  return (
    <TableContainer
      bgColor="white"
      borderRadius="md"
      border="1px solid"
      borderColor="gray.200"
      overflowY="auto"
      whiteSpace="normal"
    >
      <Table>
        <Thead whiteSpace="nowrap">
          <Tr>
            <HeaderCell>Account</HeaderCell>
            <HeaderCell>Value</HeaderCell>
            <HeaderCell>Reasoning</HeaderCell>
          </Tr>
        </Thead>
        <Tbody>
          {selectedAccountIds.map((accountId) => {
            return (
              <AiFieldBuilderRow
                key={accountId.toString()}
                accountId={accountId}
              />
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

const sampleAccountIds = [
  new ObjectId('632358243046e5126bfacb05'), // 15Five
  new ObjectId('62b4e42e4b78b45377cb6c60'), // ClickUp
  new ObjectId('62b135a41de7b1246567bd47'), // Contentsquare
  new ObjectId('62b135a41de7b1246567bf82'), // Datadog
  new ObjectId('62b135a71de7b1246567ccbb'), // Gusto
];

export const SamplePreviewTable = () => {
  return (
    <TableContainer
      bgColor="white"
      borderRadius="md"
      border="1px solid"
      borderColor="gray.200"
      overflowY="auto"
      whiteSpace="normal"
    >
      <Table>
        <Thead whiteSpace="nowrap">
          <Tr>
            <HeaderCell>Account</HeaderCell>
            <HeaderCell>Your New AI Field</HeaderCell>
          </Tr>
        </Thead>
        <Tbody>
          {sampleAccountIds.map((accountId) => {
            return (
              <Tr key={accountId.toString()}>
                <Td w="200px">
                  <AccountCell accountId={accountId} />
                </Td>
                <Td>
                  <Skeleton h="2" speed={0} />
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

const HeaderCell = chakra(Th, {
  baseStyle: {
    bgColor: 'gray.50',
    fontSize: 'sm',
    fontWeight: 'normal',
    position: 'sticky',
    top: 0,
    textColor: 'kgray.500',
  },
});

const AccountCell = ({accountId}: {accountId: ObjectId}) => {
  const {data: account, isPending} = useAccountWithLogo(accountId);

  if (isPending || !account) {
    return <Skeleton />;
  }

  return (
    <Flex direction="column">
      <Box>{account.name}</Box>
      <Box fontSize="sm" textColor="gray.400">
        {account.domain}
      </Box>
    </Flex>
  );
};
