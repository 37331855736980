import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useKeyplayApi} from '../../context/ApiContext';
import {
  AccountAction,
  AccountActionPayload,
  AccountActionQueryPayload,
  AccountQueryBase,
} from '../../shared/api/api';
import _ from 'lodash';
import {useScoringRun} from '../../context/ScoringRunContext';
import {
  useAccountQueryParams,
  useIsMyAccountsView,
  useUserControls,
} from '../../components/AccountGrid/AccountGrid.controlstate';
import {WithRequired, assertNever} from '../../shared/util';
import {ObjectId} from 'bson';
import {MetadataQueryKey} from '../../context/MetadataContext';
import {useSavedAccountsStore} from '../../components/AccountGrid/useSavedAccountsStore';
import {useScoredAccounts} from '../../components/Hooks/scoredAccounts';
import {useCustomer} from './metadata';
import {getSavesQuota} from '../../shared/customer';

// Plain axios requests for updating one or more accounts by ID or query.
const useUpdateAcccounts = () => {
  const makeApiCall = useKeyplayApi();

  const updateAccounts = async (
    actionPayload: AccountActionPayload | AccountActionQueryPayload
  ) =>
    await makeApiCall(
      '/actions',
      {
        method: 'POST',
        data: actionPayload,
      },
      {
        toastOnError: true,
      }
    );

  const updateAccountsByIds = async (
    action: AccountAction,
    accountIds: ObjectId[],
    runId: ObjectId
  ) =>
    await updateAccounts({
      action,
      runId: runId.toString(),
      accountIds: accountIds.map((id) => id.toString()),
    });

  const updateAccountsByQuery = async (
    action: AccountAction,
    query: AccountQueryBase
  ) =>
    await updateAccounts({
      action,
      query,
    });

  return {
    updateAccountsByIds,
    updateAccountsByQuery,
  };
};

export type ActionContext =
  | {
      accountIds: ObjectId[];
      runId: ObjectId;
      type: 'list';
    }
  | {
      query: WithRequired<AccountQueryBase, 'limit'>;
      type: 'query';
    };

export const useSingleAccountActionContext = (
  accountId: ObjectId
): ActionContext | undefined => {
  const scoringRun = useScoringRun();

  return {
    accountIds: [accountId],
    runId: scoringRun._id,
    type: 'list',
  };
};

export const useAccountQueryActionContext = (): ActionContext | undefined => {
  const scoringRun = useScoringRun();
  const params = useAccountQueryParams();
  const userControls = useUserControls();
  const {numBulkSelected, rowSelection} = userControls;

  return numBulkSelected
    ? {
        query: {
          ...params,
          limit: numBulkSelected,
          runId: scoringRun._id.toString(),
        },
        type: 'query',
      }
    : {
        accountIds: Object.keys(_.pickBy(rowSelection, _.identity)).map(
          (id) => new ObjectId(id)
        ),
        runId: scoringRun._id,
        type: 'list',
      };
};

// Consolidated react-query mutation for updating one or more accounts by ID or query.
export const useAccountActions = (actionContext: ActionContext | undefined) => {
  const isMyAccountsView = useIsMyAccountsView();
  const {updateAccountsByIds, updateAccountsByQuery} = useUpdateAcccounts();
  const queryClient = useQueryClient();
  const {fetchedAccounts} = useScoredAccounts();
  const {setSavedAccountsContext} = useSavedAccountsStore();
  const customer = useCustomer();

  return useMutation({
    mutationFn: ({action}: {action: AccountAction}) => {
      if (!actionContext) {
        throw new Error('No action context provided');
      }

      const type = actionContext.type;
      switch (type) {
        case 'list':
          return updateAccountsByIds(
            action,
            actionContext.accountIds,
            actionContext.runId
          );
        case 'query':
          return updateAccountsByQuery(action, actionContext.query);
        default:
          assertNever(type);
      }
    },
    onSuccess: async (_, {action}) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['scoredAccountsTables'],
        }),
        queryClient.invalidateQueries({
          queryKey: [MetadataQueryKey],
        }),
      ]);

      if (action.name === 'tag') {
        if (!actionContext) {
          return;
        }

        const numAccounts =
          actionContext.type === 'list'
            ? actionContext.accountIds.length
            : actionContext.query.limit;

        const accountName =
          numAccounts === 1 && actionContext.type === 'list'
            ? fetchedAccounts.find(({accountId}) =>
                accountId.equals(actionContext.accountIds[0])
              )?.fields.name
            : undefined;

        const {current} = getSavesQuota(customer);

        setSavedAccountsContext({
          accountName,
          action: isMyAccountsView ? 'tag' : 'save',
          notificationType: current === 0 ? 'modal' : 'toast',
          numAccounts,
        });
      }
    },
  });
};
