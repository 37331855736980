import {AccountQuery, ImportSourceCountsResponse} from '../../shared/api/api';
import {useKeyplayApi} from '../../context/ApiContext';
import {keepPreviousData, useQuery} from '@tanstack/react-query';
import {useAccountView} from './AccountGrid';

interface UseImportSourceCountsProps {
  enabled: boolean;
  queryParams: AccountQuery | '';
}

// TODO: investigate unifying with useSignalCounts
export function useImportSourceCounts({
  queryParams,
  enabled,
}: UseImportSourceCountsProps) {
  const accountView = useAccountView();
  const makeApiCall = useKeyplayApi();
  return useQuery({
    queryKey: ['importSourceCounts', accountView, JSON.stringify(queryParams)],
    queryFn: () =>
      makeApiCall<ImportSourceCountsResponse>('/importSourceCounts', {
        params: {
          query: queryParams,
        },
      }),
    enabled,
    placeholderData: keepPreviousData,
    retry: 1,
  });
}
