import React, {useEffect, useState} from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  Spinner,
  Text,
  Tooltip,
  useDisclosure,
  useModalContext,
  useToast,
} from '@chakra-ui/react';
import {useAiFieldBuilderStore} from '../AiFieldBuilder.state';
import {
  useGetAnalysisRunResults,
  useLatestFieldDefinitionAnalysisRun,
  useUpdateFieldDefinitionAnalysisRun,
} from '../../../../hooks/api/fieldDefinitionAnalysisRun';
import {useGetTestLists} from '../../../../hooks/api/testList';
import {useAiFieldBuilderNavStore} from '../AiFieldBuilderNav.state';
import {ScrollableFlex, WrapperFlex} from '../../../utils/scrolling';
import _ from 'lodash';
import {useBuilderMode} from '../BuilderModeContext';
import {Download} from '@carbon/icons-react';
import {AnalysisRunProvider} from './AnalysisRunProvider';
import {AnalysisResultsTable} from './AnalysisResultsTable';
import {useSelectedVersionStore} from '../SelectedVersion.state';
import {SelectTestListsModal} from '../../TestLists/SelectTestListsModal';
import {ObjectId} from 'bson';
import {hasSamePreviewConfigs} from '../../../../shared/enrichment';
import {formatRelative} from 'date-fns';
import {Parser} from '@json2csv/plainjs';
import FileSaver from 'file-saver';
import {useCustomer} from '../../../../hooks/api/metadata';
import {useIsAnalysisRunInProgress} from './useIsAnalysisRunInProgress';
import {FieldBuilderPageIndicator} from '../FieldBuilderPageIndicator';

export const AiFieldBuilderAnalyzeListsStep = () => {
  const {serverState, publishChanges} = useAiFieldBuilderStore();
  const {prevStep} = useAiFieldBuilderNavStore();
  const {selectedVersionId} = useSelectedVersionStore();
  const mode = useBuilderMode();
  const {onClose} = useModalContext();

  const {
    data: fieldDefinitionAnalysisRun,
    isPending: isRunDetailsPending,
    isError: isRunDetailsError,
  } = useLatestFieldDefinitionAnalysisRun({
    fieldDefinitionId: serverState.id,
  });
  const {
    data: testLists,
    isPending: isTestListsPending,
    isError: isTestListsError,
  } = useGetTestLists({});

  const isPending = isRunDetailsPending || isTestListsPending;
  const isError = isRunDetailsError || isTestListsError;

  return (
    <Flex direction="column" flex="1">
      <Box position="relative" h="100%">
        <WrapperFlex>
          <ScrollableFlex alignItems="center" px="6">
            <Flex direction="column" maxW="800px" w="100%" flex="1">
              <FieldBuilderPageIndicator />
              <Flex direction="column" gap="4" mt="-8px">
                <HeaderSection />
                {isPending ? (
                  <Flex justifyContent="center" py="10">
                    <Spinner />
                  </Flex>
                ) : isError ? (
                  <Flex justifyContent="center" py="10">
                    <Text color="kgray.300">
                      Error loading analysis results.
                    </Text>
                  </Flex>
                ) : !fieldDefinitionAnalysisRun ? (
                  <Flex justifyContent="center" py="10">
                    <Text color="kgray.300">
                      No analysis has been run yet. Select one or more test list
                      to start analyzing.
                    </Text>
                  </Flex>
                ) : (
                  <AnalysisRunProvider
                    analysisRun={fieldDefinitionAnalysisRun}
                    testLists={testLists!}
                  >
                    <RefreshBanner />
                    <AnalysisResultsTable />
                    <Flex color="kgray.300" fontSize="sm" justifyContent="end">
                      Updated{' '}
                      {formatRelative(
                        fieldDefinitionAnalysisRun.timestamp,
                        new Date()
                      )}
                    </Flex>
                  </AnalysisRunProvider>
                )}
              </Flex>
            </Flex>
          </ScrollableFlex>
        </WrapperFlex>
      </Box>

      <Divider />

      <Flex justifyContent="space-between" w="100%" px={10} py={4}>
        <Button variant="ghost" onClick={prevStep}>
          Back
        </Button>

        <Button
          colorScheme="kbuttonblue"
          isLoading={publishChanges.isPending}
          onClick={() => {
            if (mode === 'review') {
              onClose();
              return;
            }

            publishChanges.mutate(
              {mode: 'publish', publishVersionId: selectedVersionId},
              {onSuccess: onClose}
            );
          }}
        >
          {mode === 'review' ? 'Exit' : 'Publish & Exit'}
        </Button>
      </Flex>
    </Flex>
  );
};

const HeaderSection = () => {
  const isAnalysisRunInProgress = useIsAnalysisRunInProgress();

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Text flexGrow="1" fontSize="xl">
        Analyze Lists
      </Text>
      <Flex alignItems="center" gap="2">
        {isAnalysisRunInProgress && (
          <Tooltip label="Analysis run in progress." placement="top">
            <Spinner color="kred.300" size="md" speed="1s" mr="4" />
          </Tooltip>
        )}
        <SelectTestListsButton />
        <DownloadButton />
      </Flex>
    </Flex>
  );
};

const SelectTestListsButton = () => {
  const {serverState, versions} = useAiFieldBuilderStore();
  const {data: analysisRun, isPending: isAnalysisRunPending} =
    useLatestFieldDefinitionAnalysisRun({
      fieldDefinitionId: serverState.id,
    });
  const testListModal = useDisclosure();
  const updateFieldDefinitionAnalysisRun =
    useUpdateFieldDefinitionAnalysisRun();

  const analysisRunTestLists =
    analysisRun?.results.map((result) => result.testListId) ?? [];
  const isAnalysisRunInProgress = useIsAnalysisRunInProgress();
  const samePreviewConfigs =
    !!analysisRun &&
    hasSamePreviewConfigs(serverState, analysisRun.fieldDefinition);

  const onSelectTestLists = (testLists: ObjectId[]) => {
    if (
      !testLists.length ||
      (samePreviewConfigs && _.isEqual(testLists, analysisRunTestLists))
    ) {
      return;
    }

    updateFieldDefinitionAnalysisRun.mutate({
      fieldDefinitionId: serverState.id,
      testListIds: testLists,
      versionIds: versions.map((v) => v.id),
    });
  };

  return (
    <>
      <SelectTestListsModal
        isOpen={testListModal.isOpen}
        onClose={testListModal.onClose}
        onSelectTestLists={onSelectTestLists}
        selectedTestLists={analysisRunTestLists}
        selectButtonText={
          samePreviewConfigs ? 'Update analysis run' : 'Start new analysis run'
        }
      />
      <Button
        colorScheme="kbuttonblue"
        onClick={testListModal.onOpen}
        isDisabled={isAnalysisRunPending || isAnalysisRunInProgress}
        isLoading={updateFieldDefinitionAnalysisRun.isPending}
        variant="outline"
      >
        Select Test Lists
      </Button>
    </>
  );
};

const DownloadButton = () => {
  const customer = useCustomer();
  const {serverState} = useAiFieldBuilderStore();
  const {data: analysisRun} = useLatestFieldDefinitionAnalysisRun({
    fieldDefinitionId: serverState.id,
  });
  const toast = useToast();

  const [isDownloadingResults, setIsDownloadingResults] = useState(false);
  const {
    data: analysisRunResults,
    isError: isAnalysisRunResultsError,
    isFetching: isAnalysisRunResultsFetching,
    isSuccess: isAnalysisRunResultsSuccess,
  } = useGetAnalysisRunResults(
    !analysisRun ? 'skipToken' : {fieldDefinitionRunId: analysisRun._id},
    {
      enabled: isDownloadingResults,
    }
  );

  const isAnalysisRunInProgress = useIsAnalysisRunInProgress();

  const handleDownload = () => {
    if (!analysisRun) {
      return;
    }
    setIsDownloadingResults(true);
  };

  useEffect(() => {
    if (isDownloadingResults && isAnalysisRunResultsError) {
      toast({
        title: 'Error downloading analysis results.',
        description: 'Please try again or contact support.',
        status: 'error',
      });
      setIsDownloadingResults(false);
    }
  }, [isAnalysisRunResultsError, isDownloadingResults, toast]);

  useEffect(() => {
    if (
      !isDownloadingResults ||
      isAnalysisRunResultsFetching ||
      !isAnalysisRunResultsSuccess
    ) {
      return;
    }

    const csv = new Parser().parse(
      analysisRunResults.map((result) => {
        return {
          Domain: result.domain,
          Lists: result.testListLabels.join(', '),
          Value: result.value,
          Reasoning: result.reasoning,
        };
      })
    );
    const blob = new Blob([csv], {type: 'text/csv'});
    FileSaver.saveAs(
      blob,
      `analysisRun-${customer.name}-${analysisRun?.fieldDefinition.label}`
    );

    setIsDownloadingResults(false);
  }, [
    analysisRun,
    analysisRunResults,
    customer.name,
    isAnalysisRunResultsFetching,
    isAnalysisRunResultsSuccess,
    isDownloadingResults,
  ]);

  return (
    <Button
      isDisabled={!analysisRun || isAnalysisRunInProgress}
      isLoading={isDownloadingResults}
      onClick={handleDownload}
      variant="ghost"
    >
      <Download />
    </Button>
  );
};

const RefreshBanner = () => {
  const {serverState, versions} = useAiFieldBuilderStore();
  const {data: analysisRun, isPending: isAnalysisRunPending} =
    useLatestFieldDefinitionAnalysisRun({
      fieldDefinitionId: serverState.id,
    });
  const updateFieldDefinitionAnalysisRun =
    useUpdateFieldDefinitionAnalysisRun();

  const isAnalysisRunInProgress = useIsAnalysisRunInProgress();

  if (
    isAnalysisRunInProgress ||
    !analysisRun ||
    hasSamePreviewConfigs(serverState, analysisRun.fieldDefinition)
  ) {
    return null;
  }

  const analysisRunTestLists =
    analysisRun?.results.map((result) => result.testListId) ?? [];

  const onRefreshAnalysisRun = () => {
    updateFieldDefinitionAnalysisRun.mutate({
      fieldDefinitionId: serverState.id,
      testListIds: analysisRunTestLists,
      versionIds: versions.map((v) => v.id),
    });
  };

  return (
    <Flex
      alignItems="baseline"
      bgColor="kyellow.100"
      borderRadius="md"
      justifyContent="space-between"
      py="4"
      px="4"
    >
      <Box>
        The analysis results are out of date with the current field definition.
      </Box>
      <Button
        isDisabled={isAnalysisRunPending}
        isLoading={updateFieldDefinitionAnalysisRun.isPending}
        size="sm"
        variant="link"
        onClick={onRefreshAnalysisRun}
      >
        Rerun analysis now.
      </Button>
    </Flex>
  );
};
