import {z} from 'zod';
import {
  PrimaryBusinessCategoryLabels,
  SecondaryBusinessCategoryLabels,
} from './account';
import {SignalDefinition} from './signalDefinition';
import {isKeyplayScoringSignal, KeyplayScoringSignal} from './signals';
import {ScoringSignal} from './signals';
import {entries, keys, zodEnum} from './util';
import {CustomSignalField} from './fields';

const SignalGroups = {
  category: [
    ...keys(PrimaryBusinessCategoryLabels),
    ...keys(SecondaryBusinessCategoryLabels),
  ],

  cloud: ['usingAws', 'usingAzure', 'usingGcp'],

  employeeRange: [
    'employees1to50',
    'employees51to100',
    'employees101to200',
    'employees201to500',
    'employees501to1000',
    'employees1001to2500',
    'employees2501to5000',
    'employeesOver5000',
  ],

  yearFoundedRange: [
    'foundedBefore1950',
    'founded1950to1979',
    'founded1980to1995',
    'founded1996to2006',
    'founded2007to2017',
    'founded2018orLater',
  ],

  fundingAmount: [
    'hasFunding',
    'fundingOver1M',
    'fundingAmountNotFound',
    'fundingAmount100Kto1M',
    'fundingAmount1Mto10M',
    'fundingAmount10Mto50M',
    'fundingAmount50Mto100M',
    'fundingAmountOver100M',
  ],

  lastFundingDate: [
    'lastFundingWithin3Months',
    'lastFunding4to12Months',
    'lastFunding13to24Months',
    'lastFunding25MonthsPlus',
  ],

  oneMonthGrowth: [
    'oneMonthGrowthNegative',
    'oneMonthGrowthNeutral',
    'oneMonthGrowthPositive',
  ],

  sixMonthGrowth: [
    'sixMonthGrowthNegative',
    'sixMonthGrowthNeutral',
    'sixMonthGrowthPositive',
    'sixMonthGrowthModerate',
    'sixMonthGrowthHigh',
    'sixMonthGrowthVeryHigh',
  ],

  linkedInFollowers: [
    'liFollowers1to50',
    'liFollowers51to500',
    'liFollowers501to1000',
    'liFollowers1001to5000',
    'liFollowersOver5001',
  ],

  trafficRank: [
    'trafficRankHigh',
    'trafficRankTop100K',
    'trafficRank100Kto200K',
    'trafficRank200Kto300K',
    'trafficRank300Kto500K',
    'trafficRank500Kto1M',
  ],

  recruitingVelocity: [
    'recruitingVelocityVeryHigh',
    'recruitingVelocityHigh',
    'recruitingVelocityModerate',
    'recruitingVelocityLow',
    'recruitingVelocityNone',
  ],

  linkedInAds: ['linkedInAds1to10', 'linkedInAds11to100', 'linkedInAds101Plus'],

  hiringSoftwareEng: ['hiringSoftwareEng2to5', 'hiringSoftwareEngOver5'],

  abTesting: [
    'usingAbTasty',
    'usingOptimizely',
    'usingSplitIo',
    'usingStatsig',
  ],

  ats: [
    'unknownAts',
    'noAtsFound',
    'adpAts',
    'angellistAts',
    'usingAshby',
    'bambooHr',
    'breezyHr',
    'comparably',
    'eightfold',
    'usingGreenhouse',
    'usingIcims',
    'jazzHr',
    'usingJobvite',
    'usingLever',
    'linkedInAts',
    'paycom',
    'paylocity',
    'personio',
    'usingRecruitee',
    'recruiterflow',
    'ripplingAts',
    'usingSmartRecruiters',
    'teamtailor',
    'talentEd',
    'ultipro',
    'usingWorkable',
    'usingWorkdayAts',
    'yCombinatorAts',
  ],

  dataWarehouse: [
    'usingDataWarehouse',
    'usingAzureSynapse',
    'usingBigQuery',
    'usingOracleAutonomousDataWarehouse',
    'usingPanoply',
    'usingRedshift',
    'usingSapDataWarehouseCloud',
    'usingSnowflake',
    'usingTeradataVantage',
    'usingVertica',
    'usingYellowbrick',
  ],

  generalLedger: [
    'acumatica',
    'epicor',
    'usingFinancialForce',
    'usingGeneralLedger',
    'usingIntacct',
    'microsoftDynamicsGp',
    'usingNetsuite',
    'oracleFusion',
    'usingQuickbooks',
    'usingQuickbooksOnline',
    'sapCloudErp',
    'usingXero',
  ],
} satisfies Record<string, KeyplayScoringSignal[]>;

const KeyplaySignalGroups = keys(SignalGroups);
export const KeyplaySignalGroupSchema = zodEnum(KeyplaySignalGroups);
export type KeyplaySignalGroup = z.infer<typeof KeyplaySignalGroupSchema>;

export type AtsSignal = (typeof SignalGroups)['ats'][number];

// NOTE: if you update any of the signal group values here, you'll need to
// run a migration to update any signals using that group
export const AccountFieldToSignalGroup = {
  employeeCount: 'employeeRange',
  followerCount: 'linkedInFollowers',
  totalFundingRaised: 'fundingAmount',
  trafficRank: 'trafficRank',
  yearFounded: 'yearFoundedRange',
} satisfies Record<Exclude<CustomSignalField, 'hiring'>, KeyplaySignalGroup>;

export function findSignalGroup({
  signal,
  signalDefinitions,
}: {
  signal: ScoringSignal;
  signalDefinitions: SignalDefinition[];
}): string | null {
  if (!isKeyplayScoringSignal(signal)) {
    return (
      signalDefinitions.find((s) => s.id === signal)?.group?.toString() ?? null
    );
  }

  for (const [group, signals] of entries(SignalGroups)) {
    if ((signals as ScoringSignal[]).includes(signal)) {
      return group;
    }
  }
  return null;
}
