import React, {useEffect, useMemo, useState} from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Text,
  Flex,
  Box,
  Button,
  useModalContext,
} from '@chakra-ui/react';
import {useAiFieldBuilderStore} from './AiFieldBuilder.state';
import {
  AiFieldBuilderStep,
  useAiFieldBuilderNavStore,
} from './AiFieldBuilderNav.state';
import {WrapperFlex} from '../../utils/scrolling';
import {AiFieldBuilderDefineFieldStep} from './AiFieldBuilderDefineFieldStep';
import {AiFieldBuilderSelectAccountsStep} from './AiFieldBuilderSelectAccountsStep';
import {AiFieldBuilderPreviewResultsStep} from './AiFieldBuilderPreviewResultsStep';
import {AiFieldBuilderModalHeader} from './AiFieldBuilderModalHeader';
import {
  FieldDefinitionProvider,
  useFieldDefinitionFromContext,
} from './FieldDefinitionContext';
import {ObjectId} from 'bson';
import {
  BuilderMode,
  BuilderModeProvider,
  useBuilderMode,
} from './BuilderModeContext';
import {AiFieldBuilderAnalyzeListsStep} from './AnalyzeListsStep/AiFieldBuilderAnalyzeListsStep';

export const AiFieldBuilderModal = ({
  fieldDefinitionId,
  isOpen,
  onClose,
  mode,
}: {
  fieldDefinitionId?: ObjectId;
  isOpen: boolean;
  onClose: () => void;
  mode: BuilderMode;
}) => {
  const {setStep} = useAiFieldBuilderNavStore();
  const resetState = () => {
    setStep('defineField');
  };

  return (
    <Modal
      isCentered={true}
      isOpen={isOpen}
      onClose={onClose}
      onCloseComplete={resetState}
      size="full"
    >
      <ModalOverlay />
      <ModalContent>
        <BuilderModeProvider mode={mode}>
          <AiFieldBuilderContainer fieldDefinitionId={fieldDefinitionId} />
        </BuilderModeProvider>
      </ModalContent>
    </Modal>
  );
};

const AiFieldBuilderContainer = ({
  fieldDefinitionId,
}: {
  fieldDefinitionId?: ObjectId;
}) => {
  const [localFieldDefinitionId, setLocalFieldDefinitionId] = useState<
    ObjectId | undefined
  >(fieldDefinitionId);
  const {setStep} = useAiFieldBuilderNavStore();

  return (
    <>
      {localFieldDefinitionId ? (
        <FieldDefinitionProvider fieldDefinitionId={localFieldDefinitionId}>
          <AiFieldBuilderModalContent />
        </FieldDefinitionProvider>
      ) : (
        <>
          <AiFieldBuilderModalHeader />
          <ModalBody as={Flex} flexDirection="column" p="0">
            <AiFieldBuilderTitleSection />
            <Box flex="1" position="relative">
              <WrapperFlex>
                <AiFieldBuilderDefineFieldStep
                  mode="create"
                  onContinue={(fieldDefinition) => {
                    setLocalFieldDefinitionId(fieldDefinition.id);
                    setStep('selectAccounts');
                  }}
                />
              </WrapperFlex>
            </Box>
          </ModalBody>
          <ModalCloseButton />
        </>
      )}
    </>
  );
};

const AiFieldBuilderModalContent = () => {
  const {publishChanges, updateServerState, serverState} =
    useAiFieldBuilderStore();
  const {step, setStep} = useAiFieldBuilderNavStore();
  const {onClose} = useModalContext();
  const mode = useBuilderMode();

  const fieldDefinition = useFieldDefinitionFromContext();
  // Update the server state if the field definition has changed
  useEffect(() => {
    updateServerState({fieldDefinition, updateMode: 'ifNoChanges'});
  }, [fieldDefinition, updateServerState]);

  const Steps = useMemo(
    () =>
      ({
        defineField: (
          <AiFieldBuilderDefineFieldStep
            mode="edit"
            onContinue={() => setStep('selectAccounts')}
          />
        ),
        selectAccounts: <AiFieldBuilderSelectAccountsStep />,
        previewResults: <AiFieldBuilderPreviewResultsStep />,
        analyzeLists: <AiFieldBuilderAnalyzeListsStep />,
      }) satisfies Record<AiFieldBuilderStep, React.ReactNode>,
    [setStep]
  );

  const saveDraftAndExit = () => {
    if (mode === 'review') {
      onClose();
      return;
    }

    publishChanges.mutate({mode: 'save'}, {onSuccess: onClose});
  };

  return (
    <>
      <AiFieldBuilderModalHeader />
      <ModalBody as={Flex} flexDirection="column" p="0">
        <AiFieldBuilderTitleSection fieldLabel={serverState.label}>
          <Button
            colorScheme="kbuttonblue"
            isLoading={publishChanges.isPending}
            onClick={saveDraftAndExit}
            variant="outline"
          >
            {mode === 'review' ? 'Exit' : 'Save Draft & Exit'}
          </Button>
        </AiFieldBuilderTitleSection>
        <Box flex="1" position="relative">
          <WrapperFlex>{Steps[step]}</WrapperFlex>
        </Box>
      </ModalBody>
      <ModalCloseButton />
    </>
  );
};

const AiFieldBuilderTitleSection = ({
  fieldLabel,
  children,
}: {
  children?: React.ReactNode;
  fieldLabel?: string;
}) => {
  const label = fieldLabel ? `— ${fieldLabel}` : '';

  return (
    <Flex
      alignItems="baseline"
      borderBottom="1px solid"
      borderColor="kgray.200"
      h="90px"
      justifyContent="space-between"
      p="6"
    >
      <Text fontSize="2xl" fontWeight="normal">
        AI Field Builder {label}
      </Text>
      {children}
    </Flex>
  );
};
