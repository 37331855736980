import {useQueryClient} from '@tanstack/react-query';
import {MetadataQueryKey} from '../../context/MetadataContext';
import {
  DeleteSignalDefinition,
  PostSignalDefinition,
} from '../../shared/api/definitions';
import {useApiMutation} from './api';

export const useCreateSignalDefinition = () => {
  const queryClient = useQueryClient();
  return useApiMutation('/customer/signalDefinition', PostSignalDefinition, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [MetadataQueryKey],
      });
    },
  });
};

export const useDeleteSignalDefinition = () => {
  const queryClient = useQueryClient();
  return useApiMutation(
    '/customer/deleteSignalDefinition',
    DeleteSignalDefinition,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: [MetadataQueryKey],
        });
      },
    }
  );
};
