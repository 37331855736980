import {useCallback} from 'react';
import {useCustomer} from './api/metadata';
import {resolveField} from '../shared/fields';
import {AccountFieldOrCustomField} from '../shared/signalDefinition';
import {captureMessage} from '@sentry/react';

export function useFieldResolver() {
  const customer = useCustomer();

  return useCallback(
    (field: AccountFieldOrCustomField) => {
      const resolvedField = resolveField(
        field,
        customer.fieldDefinitions ?? []
      );

      if (!resolvedField) {
        captureMessage(`Unable to resolve field: ${field}`, 'error');
      }

      return resolvedField;
    },
    [customer]
  );
}

export function useResolvedField(field: AccountFieldOrCustomField) {
  const fieldResolver = useFieldResolver();
  const resolvedField = fieldResolver(field);

  if (!resolvedField) {
    throw new Error(`Field ${field} not found`);
  }

  return resolvedField;
}
