import {useAiFieldBuilderStore} from '../AiFieldBuilder.state';
import {useLatestFieldDefinitionAnalysisRun} from '../../../../hooks/api/fieldDefinitionAnalysisRun';

export function useIsAnalysisRunInProgress() {
  const {serverState} = useAiFieldBuilderStore();
  const {data: analysisRun} = useLatestFieldDefinitionAnalysisRun({
    fieldDefinitionId: serverState.id,
  });

  return analysisRun?.results.some((result) => result.status === 'running');
}
