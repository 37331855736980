import {User as Auth0User} from '@auth0/auth0-react';
import React, {useContext, useMemo} from 'react';
import {useKeyplayApi} from './ApiContext';
import {useQuery} from '@tanstack/react-query';
import {UserResponse} from '../shared/api/api';

// The User type is a combination of the Auth0 user and any extra fields we get
// from our API.
type User = {
  auth0: Auth0User;
  extras: {
    intercomHash: string;
  };
};

const UserContext = React.createContext<User | undefined>(undefined);

export function UserProvider({
  children,
  auth0User,
}: React.PropsWithChildren<{
  auth0User: Auth0User;
}>) {
  const makeApiCall = useKeyplayApi();
  const {data} = useQuery({
    queryKey: ['user'],
    queryFn: () => makeApiCall<UserResponse>('/customer/user'),
    staleTime: Infinity,
  });

  const user = useMemo(() => {
    return {
      auth0: auth0User,
      extras: {
        intercomHash: data?.intercomHash ?? '',
      },
    };
  }, [auth0User, data]);

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}

export function useUser() {
  const userContext = useContext(UserContext);
  if (userContext === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }

  return userContext;
}
