import React from 'react';
import {
  FormControl,
  FormLabel,
  Textarea,
  FormHelperText,
} from '@chakra-ui/react';
import {useUpdateSelectedVersionConfig} from './AiFieldBuilder.state';
import {AiFieldDefinitionConfig} from '../../../shared/enrichment';
import {useBuilderMode} from './BuilderModeContext';

export const KeyClarificationsEditor = ({
  config,
}: {
  config: AiFieldDefinitionConfig;
}) => {
  const mode = useBuilderMode();
  const updateSelectedVersionConfig = useUpdateSelectedVersionConfig();

  return (
    <FormControl>
      <FormLabel textTransform="uppercase">
        Key Clarifications (optional)
      </FormLabel>
      <Textarea
        isDisabled={mode === 'review'}
        onChange={(e) => {
          updateSelectedVersionConfig({
            ...config,
            clarifications: e.target.value.split('\n'),
          });
        }}
        placeholder="Add additional instructions for your AI Analyst..."
        rows={5}
        value={config.clarifications?.join('\n') ?? ''}
      />
      <FormHelperText textColor="kgray.300">
        Add a new line for each instruction you’d like your AI Analyst to take
        into consideration.
      </FormHelperText>
    </FormControl>
  );
};
