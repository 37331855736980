import React from 'react';
import {Box, Button, Divider, Flex, Text} from '@chakra-ui/react';
import {useAiFieldBuilderNavStore} from './AiFieldBuilderNav.state';
import {LeftColumnContent, RightColumnContent, TwoColumnGrid} from './helpers';
import {useAiFieldBuilderStore} from './AiFieldBuilder.state';
import {ScrollableFlex, WrapperFlex} from '../../utils/scrolling';
import {assertNever} from '../../../shared/util';
import {PromptConfigEditor} from './PromptConfigEditor';
import {AiFieldBuilderPreviewTable} from './AiFieldBuilderPreviewTable';
import {EnrichmentPreviewResultQueryKeys} from '../../../hooks/api/fieldDefinitionPreview';
import {useSelectedVersionStore} from './SelectedVersion.state';
import {CategoriesConfigEditor} from './CategoriesConfigEditor';
import {useIsFetching} from '@tanstack/react-query';
import {useBuilderMode} from './BuilderModeContext';
import {FieldBuilderPageIndicator} from './FieldBuilderPageIndicator';

export const AiFieldBuilderPreviewResultsStep = () => {
  const {prevStep, nextStep} = useAiFieldBuilderNavStore();
  const {publishChanges, serverState, hasChanges} = useAiFieldBuilderStore();
  const [isPrevLoading, setIsPrevLoading] = React.useState(false);
  const {selectedVersionId} = useSelectedVersionStore();
  const mode = useBuilderMode();

  const onPrev = () => {
    if (mode === 'review') {
      prevStep();
      return;
    }

    publishChanges.mutate(
      {mode: 'save'},
      {onSuccess: prevStep, onSettled: () => setIsPrevLoading(false)}
    );
    setIsPrevLoading(true);
  };

  const onContinue = () => {
    if (mode === 'review') {
      nextStep();
      return;
    }

    publishChanges.mutate({mode: 'save'}, {onSuccess: nextStep});
  };

  const queryKey = EnrichmentPreviewResultQueryKeys.version({
    fieldDefinitionId: serverState.id,
    versionId: selectedVersionId,
  });

  const runningPreviewCount = useIsFetching({queryKey});

  return (
    <TwoColumnGrid>
      <Flex borderRight="1px solid" borderColor="kgray.200" direction="column">
        <Box position="relative" h="100%">
          <WrapperFlex>
            <ScrollableFlex p="0">
              <LeftColumnContent>
                <FieldBuilderPageIndicator />
                <VersionConfigEditor />
              </LeftColumnContent>
            </ScrollableFlex>
          </WrapperFlex>
        </Box>

        <Divider />

        <Flex justifyContent="space-between" w="100%" px={10} py={4} gap={10}>
          <Button onClick={onPrev} isLoading={isPrevLoading} variant="ghost">
            Back
          </Button>

          <Button
            colorScheme="kbuttonblue"
            isLoading={publishChanges.isPending}
            onClick={onContinue}
          >
            Continue to Test
          </Button>
        </Flex>
      </Flex>

      <RightColumnContent>
        <Flex alignItems="baseline" justifyContent="space-between" mt="-6px">
          <Text fontSize="xl">Preview</Text>
          {mode === 'modify' && (
            <Button
              isLoading={runningPreviewCount > 0}
              colorScheme="kbuttonblue"
              onClick={async () => publishChanges.mutate({mode: 'save'})}
              isDisabled={!hasChanges()}
            >
              Run Prompt to Preview
            </Button>
          )}
        </Flex>

        <Box flex="1" position="relative">
          <WrapperFlex>
            <ScrollableFlex p="0">
              <AiFieldBuilderPreviewTable />
            </ScrollableFlex>
          </WrapperFlex>
        </Box>
      </RightColumnContent>
    </TwoColumnGrid>
  );
};

const VersionConfigEditor = () => {
  const {selectedVersionId} = useSelectedVersionStore();
  const {versionConfigs} = useAiFieldBuilderStore();
  const config = versionConfigs[selectedVersionId.toString()];

  switch (config.fieldType) {
    // These use the same config editor for now
    case 'boolean':
    case 'rating':
      return <PromptConfigEditor config={config} />;
    case 'category':
      return <CategoriesConfigEditor config={config} />;

    default:
      assertNever(config);
  }
};
