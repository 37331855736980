import React, {createContext, useContext} from 'react';

export type BuilderMode = 'modify' | 'review';

const BuilderModeContext = createContext<BuilderMode>('modify');

export const BuilderModeProvider = ({
  children,
  mode,
}: {
  children: React.ReactNode;
  mode: BuilderMode;
}) => {
  return (
    <BuilderModeContext.Provider value={mode}>
      {children}
    </BuilderModeContext.Provider>
  );
};

export const useBuilderMode = () => {
  return useContext(BuilderModeContext);
};
