import React from 'react';
import {
  Box,
  Button,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import {Cloud, Rocket, WarningAlt} from '@carbon/icons-react';
import {useMutation} from '@tanstack/react-query';
import {FileCRMImportResponse} from '../../shared/api/api';
import {useKeyplayApi} from '../../context/ApiContext';
import {useImportModalStore} from './useImportModalStore';
import {FileValidationError} from '../../lib/validateFile';
import * as Sentry from '@sentry/react';
import {isCrmImportRow} from '../../shared/import';
import {FileChooser} from '../File/FileChooser';
import {FileValidator} from '../File/FileValidator';
import {FileValidationErrors} from '../File/FileValidationErrors';
import {useSignedUrlMutation} from './useSignedUrlMutation';
import {useUploadFileMutation} from './useUploadFileMutation';

interface CRMImportModalProps {
  isOpen: boolean;
  onClose: (taskId: string | null) => void;
}

const FileChooserStep = () => {
  const {selectedFile, setState} = useImportModalStore();

  return (
    <>
      <FileChooser
        selectedFile={selectedFile}
        onSelectedFile={(file) => {
          setState({
            selectedFile: file,
            step: 'validateFile',
          });
        }}
      />

      {!selectedFile && <InfoBox />}
    </>
  );
};

const MaxRows = 100_000;

const ValidateFileStep = () => {
  const {selectedFile, setState} = useImportModalStore();
  if (!selectedFile) {
    // Shouldn't happen...
    Sentry.captureMessage('validate file step but no file selected');
    setState({step: 'chooseFile'});
    return <></>;
  }

  return (
    <FileValidator
      maxRows={MaxRows}
      onFileValidate={(errors) => {
        if (errors.size > 0) {
          setState({
            fileValidationErrors: errors,
            step: 'errors',
          });
        } else {
          setState({
            fileValidationErrors: null,
            step: 'uploadFile',
          });
        }
      }}
      rowSchemaTypeguard={isCrmImportRow}
      selectedFile={selectedFile}
    />
  );
};

const UploadFileStep = ({
  onClose,
}: {
  onClose: (taskId: string | null) => void;
}) => {
  const toast = useToast();
  const makeApiCall = useKeyplayApi();
  const {selectedFile, remoteFileLocation, setState} = useImportModalStore();

  const queueFileCRMImport = useMutation<FileCRMImportResponse>({
    mutationFn: () => {
      return makeApiCall('/fileCRMImport', {
        method: 'POST',
        data: {
          cloudStorageBucket: remoteFileLocation?.cloudStorageBucket,
          cloudStorageFilePath: remoteFileLocation?.cloudStorageFilePath,
        },
      });
    },
    onSuccess: (data) => {
      toast({
        status: 'success',
        title: 'Import process started!',
        duration: 2_000,
        isClosable: true,
      });
      // Pass the task ID to the caller so it knows which task to get the status for.
      onClose(data.taskId || null);
    },
    onError: (error) => {
      console.error('queue crm import', error);
      toast({
        status: 'error',
        title: 'Error starting import',
        duration: 2_000,
        isClosable: true,
      });
      onClose(null);
    },
  });

  const uploadFileMutation = useUploadFileMutation({
    selectedFile,
    onSuccess: () => {
      queueFileCRMImport.mutate();
    },
    onError: (error) => {
      Sentry.captureException(error);
      toast({
        status: 'error',
        title: 'Error uploading file',
        duration: 2_000,
        isClosable: true,
      });
      onClose(null);
    },
  });

  const getSignedURLMutation = useSignedUrlMutation({
    selectedFile,
    onSuccess: (data) => {
      setState({
        remoteFileLocation: data,
      });
      uploadFileMutation.mutate({url: data.url});
    },
    onError: (error) => {
      Sentry.captureException(error);
      toast({
        status: 'error',
        title: ' Error requesting upload location',
        isClosable: true,
      });
      onClose(null);
    },
  });

  const handleImportClick = () => {
    if (!selectedFile) {
      return;
    }
    getSignedURLMutation.mutate();
  };

  const isPending =
    getSignedURLMutation.isPending ||
    uploadFileMutation.isPending ||
    queueFileCRMImport.isPending;
  return (
    <Flex direction="column" alignItems="center" gap={8}>
      <Flex alignItems="center">
        Your file looks amazing! Click to complete your import{' '}
        <Rocket style={{display: 'inline', marginLeft: '4px'}} />
      </Flex>
      {isPending && <Spinner />}
      <Button
        colorScheme="kbuttonblue"
        isDisabled={isPending}
        m={4}
        onClick={handleImportClick}
      >
        Import
      </Button>
    </Flex>
  );
};

const validationErrorText = (fileValidationError: FileValidationError) => {
  switch (fileValidationError) {
    case 'file_too_large':
      return 'File is too large. Max of 100K rows per import.';
    case 'missing_columns':
      return (
        <Box>
          Required columns not found. Start with a
          <ImportTemplateLink text="CSV import template" />.
        </Box>
      );
  }
};

const ValidationErrorsStep = () => {
  const {fileValidationErrors, setState} = useImportModalStore();
  if (!fileValidationErrors) {
    Sentry.captureMessage('validation errors step but no errors detected');
    return <></>;
  }

  return (
    <FileValidationErrors
      fileValidationErrors={fileValidationErrors}
      validationErrorFn={validationErrorText}
      onChooseAnotherFile={() =>
        setState({
          selectedFile: null,
          step: 'chooseFile',
        })
      }
    />
  );
};

export const CRMImportModal = ({isOpen, onClose}: CRMImportModalProps) => {
  const {step, reset} = useImportModalStore();
  // Importing the CSV file is a multistep process:
  //
  // 1. Validate file contents (check for column schema and file size).
  // 2. Request a signed URL to a Cloud Storage bucket.
  // 3. Upload the CSV using the signed URL.
  // 4. Initiate a CRM validation task.

  const closeModal = (taskId: string | null) => {
    reset();
    onClose(taskId);
  };

  return (
    <Modal isCentered={true} isOpen={isOpen} onClose={() => closeModal(null)}>
      <ModalOverlay />
      <ModalContent minW="800" p={4}>
        <ModalHeader textAlign="center" fontSize="xl">
          Import your CRM Accounts
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          p={4}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          {step === 'chooseFile' && <FileChooserStep />}
          {step === 'validateFile' && <ValidateFileStep />}
          {step === 'uploadFile' && <UploadFileStep onClose={closeModal} />}
          {step === 'errors' && <ValidationErrorsStep />}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const InfoBox = () => {
  return (
    <Flex
      flexDirection="column"
      backgroundColor="kyellow.100"
      p={4}
      gap={4}
      width="100%"
    >
      <Flex alignItems="center" display="flex">
        <WarningAlt
          style={{
            display: 'inline',
            marginRight: '4px',
          }}
        />
        Imports need two columns, in order: 1. CRM ID, 2. Domain.
        <ImportTemplateLink text="Download a CSV import template" />.
      </Flex>
      <Flex alignItems="center">
        <Cloud style={{display: 'inline', marginRight: '4px'}} />
        <Box>
          For Salesforce.com,{' '}
          <Box as="span" fontWeight="bold">
            use the 18-character Account ID
          </Box>
          . The 15-character ID will not work.
        </Box>
      </Flex>
    </Flex>
  );
};

const ImportTemplateLink = ({text}: {text: string}) => {
  return (
    <Link
      color="kblue.400"
      download={true}
      href="/Keyplay_CrmAccounts_Import_Template.csv"
      ml={1}
    >
      {text}
    </Link>
  );
};
