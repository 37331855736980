import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {useKeyplayApi} from '../../context/ApiContext';
import {WithId} from '../../shared/util';
import {Customer} from '../../shared/customer';
import {MetadataQueryKey} from '../../context/MetadataContext';

export function useMembership({enabled}: {enabled: boolean}) {
  const makeApiCall = useKeyplayApi();

  return useQuery({
    queryKey: ['customer', 'memberships'],
    queryFn: () => makeApiCall<WithId<Customer>[]>('/customer/membership'),
    enabled,
  });
}

export function useCompleteOnboarding() {
  const makeApiCall = useKeyplayApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => makeApiCall('/customer/onboarding', {method: 'POST'}),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [MetadataQueryKey],
      });
    },
  });
}
