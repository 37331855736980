import {create} from 'zustand';

export const AiFieldBuilderSteps = [
  'defineField',
  'selectAccounts',
  'previewResults',
  'analyzeLists',
] as const;
export type AiFieldBuilderStep = (typeof AiFieldBuilderSteps)[number];

interface AiFieldBuilderNavState {
  step: AiFieldBuilderStep;
  setStep: (step: AiFieldBuilderStep) => void;
  nextStep: () => void;
  prevStep: () => void;
}

export const useAiFieldBuilderNavStore = create<AiFieldBuilderNavState>()(
  (set, get) => ({
    step: 'selectAccounts',
    setStep: (step) => set({step}),
    nextStep: () => {
      const currentIndex = AiFieldBuilderSteps.indexOf(get().step);
      if (currentIndex < AiFieldBuilderSteps.length - 1) {
        set({step: AiFieldBuilderSteps[currentIndex + 1]});
      }
    },
    prevStep: () => {
      const currentIndex = AiFieldBuilderSteps.indexOf(get().step);
      if (currentIndex > 0) {
        set({step: AiFieldBuilderSteps[currentIndex - 1]});
      }
    },
  })
);
