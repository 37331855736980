import React from 'react';
import {FormControl, FormLabel, FormHelperText} from '@chakra-ui/react';
import Select from 'react-select';
import {
  AiFieldDefinitionConfig,
  AnalysisField,
  AnalysisFieldLabels,
  AnalysisFields,
} from '../../../shared/enrichment';
import {useUpdateSelectedVersionConfig} from './AiFieldBuilder.state';
import {useBuilderMode} from './BuilderModeContext';

const getAnalysisFieldOption = (analysisField: AnalysisField) => ({
  label: AnalysisFieldLabels[analysisField],
  value: analysisField,
});

const AnalysisFieldOptions = AnalysisFields.map(getAnalysisFieldOption);

export const AnalysisFieldsEditor = ({
  config,
}: {
  config: AiFieldDefinitionConfig;
}) => {
  const mode = useBuilderMode();
  const updateSelectedVersionConfig = useUpdateSelectedVersionConfig();

  return (
    <FormControl>
      <FormLabel textTransform="uppercase">
        Answer based on analysis of
      </FormLabel>
      <Select
        isDisabled={mode === 'review'}
        isMulti={true}
        onChange={(options) => {
          updateSelectedVersionConfig({
            ...config,
            analysisFields: options.map(({value}) => value),
          });
        }}
        options={AnalysisFieldOptions}
        placeholder="Select fields"
        value={config.analysisFields.map(getAnalysisFieldOption)}
      />
      <FormHelperText textColor="kgray.300">
        Your AI Analyst will look at fields you already have in Keyplay in order
        to answer your question. Add as many fields as are relevant.
      </FormHelperText>
    </FormControl>
  );
};
