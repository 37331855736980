import React from 'react';
import {Flex, Box} from '@chakra-ui/react';
import _ from 'lodash';

export const PageIndicator = ({
  page,
  totalPages,
}: {
  page: number;
  totalPages: number;
}) => {
  return (
    <Flex mb={0} gap={1}>
      {_.times(totalPages, (pageNumber) => {
        const isCurrentPage = pageNumber === page;
        // TODO: Investigate how to add a cool transition effect to the pills
        return (
          <Box
            backgroundColor={isCurrentPage ? 'kred.400' : 'kgray.200'}
            borderRadius={'full'}
            height="8px"
            key={pageNumber}
            width={isCurrentPage ? '20px' : '8px'}
          />
        );
      })}
    </Flex>
  );
};
