import React, {useState} from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
} from '@chakra-ui/react';
import {LeftColumnContent, RightColumnContent, TwoColumnGrid} from './helpers';
import Select, {components, OptionProps} from 'react-select';
import {AiFieldDefinition, AiFieldType} from '../../../shared/enrichment';
import {defaultSelectStyles} from '../../theme/select';
import {useCreateFieldDefinition} from '../../../hooks/api/fieldDefinitions';
import {assertNever, DistributedOmit} from '../../../shared/util';
import {WrapperFlex, ScrollableFlex} from '../../utils/scrolling';
import {
  AiFieldBuilderPreviewTable,
  SamplePreviewTable,
} from './AiFieldBuilderPreviewTable';
import {useAiFieldBuilderStore} from './AiFieldBuilder.state';
import {useBuilderMode} from './BuilderModeContext';
import {FieldBuilderPageIndicator} from './FieldBuilderPageIndicator';

interface FieldTypeOption {
  label: string;
  description: string;
  value: AiFieldType;
}

const fieldTypeOptions = [
  {
    label: '1-10 Rating',
    description: 'For questions like "How likely is..." or "How much does..."',
    value: 'rating',
  },
  {
    label: 'Categories',
    description: 'For questions like "Which types..."',
    value: 'category',
  },
  {
    label: 'True/False',
    description:
      'For questions like "Does this company..." or "Is this company..."',
    value: 'boolean',
  },
] satisfies FieldTypeOption[];

const FieldTypeOption = (props: OptionProps<FieldTypeOption>) => {
  const {label, description} = props.data as FieldTypeOption;
  return (
    <components.Option {...props}>
      <Text fontSize="14">{label}</Text>
      <Text fontSize="12" mt="2" fontWeight="thin" color="kgray.300">
        {description}
      </Text>
    </components.Option>
  );
};

const fieldTypeSelectStyles = defaultSelectStyles<FieldTypeOption, false>();

export const AiFieldBuilderDefineFieldStep = ({
  mode,
  onContinue,
}: {
  mode: 'create' | 'edit';
  onContinue: (fieldDefinition: AiFieldDefinition) => void;
}) => {
  return mode === 'create' ? (
    <CreateField onContinue={onContinue} />
  ) : (
    <EditField onContinue={onContinue} />
  );
};

const CreateField = ({
  onContinue,
}: {
  onContinue: (fieldDefinition: AiFieldDefinition) => void;
}) => {
  const createFieldDefinition = useCreateFieldDefinition();

  const [fieldName, setFieldName] = useState('');
  const [fieldType, setFieldType] = useState<AiFieldType | undefined>();

  const getFieldDefinition = (
    fieldType: AiFieldType
  ): DistributedOmit<AiFieldDefinition, 'id' | 'timestamp'> => {
    const commonConfig = {
      analysisFields: [],
      model: 'gpt-4o-mini' as const,
    };

    switch (fieldType) {
      case 'boolean':
        return {
          label: fieldName,
          type: 'ai',
          status: 'draft',
          dataType: 'boolean',
          config: {
            ...commonConfig,
            fieldType,
            prompt: '',
          },
        };
      case 'rating':
        return {
          label: fieldName,
          type: 'ai',
          status: 'draft',
          dataType: 'number',
          config: {
            ...commonConfig,
            fieldType,
            min: 1,
            max: 10,
            prompt: '',
          },
        };
      case 'category':
        return {
          label: fieldName,
          type: 'ai',
          status: 'draft',
          dataType: 'string',
          config: {
            ...commonConfig,
            fieldType,
            categories: [],
          },
        };
      default:
        assertNever(fieldType);
    }
  };

  const saveFieldDefinition = () => {
    if (!fieldType) {
      return;
    }

    createFieldDefinition.mutate(getFieldDefinition(fieldType), {
      onSuccess: (fieldDefinition) => {
        if (fieldDefinition.type !== 'ai') {
          throw new Error('Expected AI field definition');
        }

        onContinue(fieldDefinition);
      },
    });
  };

  return (
    <TwoColumnGrid>
      <Flex borderRight="1px solid" borderColor="kgray.200" direction="column">
        <Flex direction="column" flex="1" gap="6">
          <LeftColumnContent>
            <FieldBuilderPageIndicator />
            <FieldEditorSection
              fieldName={fieldName}
              setFieldName={setFieldName}
              fieldType={fieldType}
              setFieldType={setFieldType}
            />
          </LeftColumnContent>
        </Flex>

        <Divider />

        <Flex justifyContent="end" w="100%" px={10} py={4} gap={10}>
          <Button
            colorScheme="kbuttonblue"
            isDisabled={!fieldName.trim() || !fieldType}
            isLoading={createFieldDefinition.isPending}
            onClick={saveFieldDefinition}
          >
            Continue
          </Button>
        </Flex>
      </Flex>

      <RightColumnContent>
        <Flex direction="column" gap="2">
          <Text fontSize="xl">Preview</Text>
          <Text fontSize="md" color="kgray.300">
            You can choose to customize the accounts you see here in the next
            step.
          </Text>
        </Flex>
        <Box flex="1" position="relative">
          <WrapperFlex>
            <ScrollableFlex p="0">
              <SamplePreviewTable />
            </ScrollableFlex>
          </WrapperFlex>
        </Box>
      </RightColumnContent>
    </TwoColumnGrid>
  );
};

const EditField = ({
  onContinue,
}: {
  onContinue: (fieldDefinition: AiFieldDefinition) => void;
}) => {
  const {
    serverState: fieldDefinition,
    label,
    setLabel,
    publishChanges,
  } = useAiFieldBuilderStore();
  const mode = useBuilderMode();

  return (
    <TwoColumnGrid>
      <Flex borderRight="1px solid" borderColor="kgray.200" direction="column">
        <LeftColumnContent>
          <FieldBuilderPageIndicator />
          <Flex direction="column" flex="1" gap="6">
            <FieldEditorSection
              fieldName={label}
              setFieldName={setLabel}
              fieldType={fieldDefinition.config.fieldType}
            />
          </Flex>
        </LeftColumnContent>

        <Divider />

        <Flex justifyContent="end" w="100%" px={10} py={4} gap={10}>
          <Button
            colorScheme="kbuttonblue"
            isDisabled={!label.trim()}
            isLoading={publishChanges.isPending}
            onClick={() => {
              if (mode === 'review') {
                onContinue(fieldDefinition);
                return;
              }

              publishChanges.mutate(
                {mode: 'save'},
                {
                  onSuccess: () => {
                    onContinue(fieldDefinition);
                  },
                }
              );
            }}
          >
            Continue
          </Button>
        </Flex>
      </Flex>

      <RightColumnContent>
        <Text fontSize="xl">Preview</Text>
        <Box flex="1" position="relative">
          <WrapperFlex>
            <ScrollableFlex p="0">
              <AiFieldBuilderPreviewTable />
            </ScrollableFlex>
          </WrapperFlex>
        </Box>
      </RightColumnContent>
    </TwoColumnGrid>
  );
};

const FieldEditorSection = ({
  fieldName,
  setFieldName,
  fieldType,
  setFieldType,
}: {
  fieldName: string;
  setFieldName: (fieldName: string) => void;
  fieldType: AiFieldType | undefined;
  setFieldType?: (fieldType: AiFieldType) => void;
}) => {
  const mode = useBuilderMode();
  return (
    <>
      <Text fontSize="xl">Define your field</Text>
      <FormControl>
        <FormLabel>Field name</FormLabel>
        <Input
          isDisabled={mode === 'review'}
          type="text"
          value={fieldName}
          onChange={(e) => setFieldName(e.target.value)}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Field type</FormLabel>
        <Select
          components={{Option: FieldTypeOption}}
          isDisabled={!setFieldType}
          isMulti={false}
          isSearchable={false}
          onChange={(newValue) => {
            if (!newValue) {
              return;
            }

            setFieldType?.(newValue.value);
          }}
          options={fieldTypeOptions}
          placeholder="Select..."
          styles={fieldTypeSelectStyles}
          value={fieldTypeOptions.find((option) => option.value === fieldType)}
        />
      </FormControl>
    </>
  );
};
