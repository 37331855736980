import React from 'react';
import {PageIndicator} from '../../PageIndicator';
import {
  AiFieldBuilderSteps,
  useAiFieldBuilderNavStore,
} from './AiFieldBuilderNav.state';
import {Flex} from '@chakra-ui/react';
import {ContentHeaderSpacePx} from './helpers';

export const FieldBuilderPageIndicator = () => {
  const {step} = useAiFieldBuilderNavStore();

  return (
    <Flex alignItems="center" h={ContentHeaderSpacePx}>
      <PageIndicator
        page={AiFieldBuilderSteps.indexOf(step)}
        totalPages={AiFieldBuilderSteps.length}
      />
    </Flex>
  );
};
