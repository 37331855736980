import {GetTestLists} from '../../shared/api/definitions';
import {createApiQuery} from './api';

export const TestListQueryKeys = {
  all: ['testLists'] as const,
};

export const useGetTestLists = createApiQuery(
  '/testLists',
  GetTestLists,
  () => TestListQueryKeys.all
);
