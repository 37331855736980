import React, {useEffect, useState} from 'react';
import {Box, Button, Link, Spinner, Text} from '@chakra-ui/react';
import {
  useEnabledIntegration,
  useIsCrmSyncing,
  useQueueFullCrmRun,
} from '../../hooks/api/integrations';
import {pluralize} from '../../lib/helpers';
import {AllIntegrations} from '../Integrations/AllIntegrations';
import {useUnsyncedCount} from '../../hooks/api/scoredAccounts';
import {useScoringRun} from '../../context/ScoringRunContext';
import {IntegrationResponse} from '../../shared/api/api';
import StatusBar from '../StatusBar';
import {Link as RouterLink} from 'react-router-dom';

export const SavedAccountsStatusBar = () => {
  const scoringRun = useScoringRun();
  const integration = useEnabledIntegration();
  const unsyncedCount = useUnsyncedCount({
    // Only show the status bar if we have an enabled integration and a
    // valid scoring run
    enabled: !!integration,
    runId: scoringRun._id,
  });

  useEffect(() => {
    if ((unsyncedCount.data?.unsyncedCount ?? 0) > 0) {
      setMessageType('status');
    }
  }, [unsyncedCount.data?.unsyncedCount]);

  const [messageType, setMessageType] = useState<'status' | 'success'>(
    'status'
  );
  const isCrmSyncing = useIsCrmSyncing({
    onSyncComplete: (taskState) => {
      unsyncedCount.refetch();
      if (taskState === 'done') {
        setMessageType('success');
      }
    },
  });

  const integrationActive =
    integration?.status === 'active' &&
    !!integration?.config?.syncSettings?.type;

  if (!integrationActive || unsyncedCount.isError) {
    return <></>;
  }

  return {
    status: (
      <StatusBar bgColor="kgray.400">
        <StatusMessage
          integration={integration}
          isCrmSyncing={isCrmSyncing}
          totalUnsyncedAccounts={unsyncedCount.data?.unsyncedCount}
        />
      </StatusBar>
    ),
    success: (
      <StatusBar bgColor="kgreen.500">
        <SuccessMessage
          integrationName={AllIntegrations[integration.type].label}
        />
      </StatusBar>
    ),
  }[messageType];
};

const SuccessMessage = ({integrationName}: {integrationName: string}) => {
  return (
    <Text color="white">
      {integrationName} sync successful. Your CRM is now up to date with your
      Keyplay accounts.
    </Text>
  );
};

const StatusMessage = ({
  integration,
  isCrmSyncing,
  totalUnsyncedAccounts,
}: {
  integration: IntegrationResponse;
  isCrmSyncing: boolean;
  totalUnsyncedAccounts: number | undefined;
}) => {
  const queueFullCrmRun = useQueueFullCrmRun();

  const config = integration.config;
  const pushSavedAccountsDisabled =
    (config?.syncSettings?.type === 'twoWay' ||
      config?.syncSettings?.type === 'selectiveTwoWay') &&
    !config?.syncSettings?.pushSavedAccounts;

  const content =
    totalUnsyncedAccounts === undefined ? (
      <Spinner size="sm" />
    ) : totalUnsyncedAccounts > 0 ? (
      pushSavedAccountsDisabled ? (
        <>
          Syncing saved accounts is currently disabled. If you would like to
          enable it, please update your integration{' '}
          <Link
            as={RouterLink}
            textColor="kblue.200"
            to={`/settings/integrations/${integration.type}`}
          >
            settings
          </Link>
          .
        </>
      ) : (
        <>
          You have saved{' '}
          {pluralize(totalUnsyncedAccounts, 'account', 'accounts')} that{' '}
          {totalUnsyncedAccounts > 1 ? 'are not' : 'is not'} synced to{' '}
          {AllIntegrations[integration.type].label} yet. Your automated sync
          runs weekly on {integration.crmSyncDay}.
        </>
      )
    ) : (
      <>
        All saved accounts have been synced to{' '}
        {AllIntegrations[integration.type].label}.
      </>
    );

  return (
    <>
      <Box me={4}>{content}</Box>
      <Button
        borderRadius="xl"
        color="kgray.400"
        colorScheme="kbuttonsecondary"
        flexShrink="0"
        fontWeight="normal"
        isDisabled={!totalUnsyncedAccounts || pushSavedAccountsDisabled}
        isLoading={isCrmSyncing || queueFullCrmRun.isPending}
        onClick={() => queueFullCrmRun.mutate(integration.type)}
      >
        Sync Now
      </Button>
    </>
  );
};
