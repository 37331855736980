import {ObjectId} from 'bson';
import {GetEnrichmentPreviewResultDefinition} from '../../shared/api/definitions';
import {createApiQuery} from './api';
import {isAxiosError} from 'axios';

export const EnrichmentPreviewResultQueryKeys = {
  all: ['enrichmentPreviewResult'],
  field: (fieldDefinitionId: ObjectId) => [
    ...EnrichmentPreviewResultQueryKeys.all,
    fieldDefinitionId.toString(),
  ],
  version: ({
    fieldDefinitionId,
    versionId,
  }: {
    fieldDefinitionId: ObjectId;
    versionId: ObjectId;
  }) => [
    ...EnrichmentPreviewResultQueryKeys.field(fieldDefinitionId),
    versionId.toString(),
  ],
  account: ({
    fieldDefinitionId,
    versionId,
    accountId,
  }: {
    fieldDefinitionId: ObjectId;
    versionId: ObjectId;
    accountId: ObjectId;
  }) => [
    ...EnrichmentPreviewResultQueryKeys.version({fieldDefinitionId, versionId}),
    accountId.toString(),
  ],
} as const;

export const useGetEnrichmentPreviewResult = createApiQuery(
  '/enrichmentPreviewResult',
  GetEnrichmentPreviewResultDefinition,
  ({fieldDefinitionId, versionId, accountId}) =>
    EnrichmentPreviewResultQueryKeys.account({
      fieldDefinitionId,
      versionId,
      accountId,
    }),
  {
    retry: (failureCount, error) => {
      if (isAxiosError(error) && error.response?.status === 503) {
        return failureCount < 10;
      }
      return false;
    },
    retryDelay: 1_000,
    staleTime: Infinity,
  }
);
