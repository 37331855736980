import {
  KeyplayScoringCategorySchema,
  KeyplayScoringSignal,
  KeyplayScoringSignalSchema,
} from './signals';
import {z} from 'zod';
import {ObjectId} from 'bson';
import {assertNever, typeguardFromArray} from './util';
import _ from 'lodash';
import {AccountField, AccountFieldSchema} from './fields';
import {ObjectIdSchema} from './zod';
import {KeyplaySignalGroupSchema} from './signalGroups';

// Note: Recursive schemas in this file should be kept in sync with their respective types.

export const AccountFieldOrCustomFieldSchema = z.union([
  ObjectIdSchema,
  AccountFieldSchema,
]);
export type AccountFieldOrCustomField = z.infer<
  typeof AccountFieldOrCustomFieldSchema
>;

const FieldExistsEvalFnSchema = z.object({
  field: AccountFieldOrCustomFieldSchema,
  type: z.literal('fieldExists'),
});
export type FieldExistsEvalFn = z.infer<typeof FieldExistsEvalFnSchema>;

const FieldDoesNotExistEvalFnSchema = z.object({
  field: AccountFieldOrCustomFieldSchema,
  type: z.literal('fieldDoesNotExist'),
});
export type FieldDoesNotExistEvalFn = z.infer<
  typeof FieldDoesNotExistEvalFnSchema
>;

const IsTrueEvalFnSchema = z.object({
  field: AccountFieldOrCustomFieldSchema,
  type: z.literal('isTrue'),
});
export type IsTrueEvalFn = z.infer<typeof IsTrueEvalFnSchema>;

const IsFalseEvalFnSchema = z.object({
  field: AccountFieldOrCustomFieldSchema,
  type: z.literal('isFalse'),
});
export type IsFalseEvalFn = z.infer<typeof IsFalseEvalFnSchema>;

const NumberEvalFnSchema = z.object({
  field: AccountFieldOrCustomFieldSchema,
  operator: z.union([
    z.literal('>'),
    z.literal('>='),
    z.literal('<'),
    z.literal('<='),
    z.literal('='),
    z.literal('!='),
  ]),
  comparator: z.number(),
  type: z.literal('number'),
});
export type NumberEvalFn = z.infer<typeof NumberEvalFnSchema>;

const EqualEvalFnSchema = z.object({
  field: AccountFieldOrCustomFieldSchema,
  value: z.unknown(),
  type: z.literal('equal'),
});
export type EqualEvalFn = z.infer<typeof EqualEvalFnSchema>;

const RangeEvalFnSchema = z.object({
  field: AccountFieldOrCustomFieldSchema,
  lowerBound: z.number().optional(), // inclusive
  upperBound: z.number().optional(), // inclusive
  type: z.literal('range'),
});
export type RangeEvalFn = z.infer<typeof RangeEvalFnSchema>;

const LogicalOrEvalFnBaseSchema = z.object({
  minCount: z.number().optional(),
  type: z.literal('or'),
});
export type LogicalOrEvalFn = z.infer<typeof LogicalOrEvalFnBaseSchema> & {
  signalEvalFns: SignalEvalFn[];
};
const LogicalOrEvalFnSchema: z.ZodType<LogicalOrEvalFn> =
  LogicalOrEvalFnBaseSchema.extend({
    signalEvalFns: z.lazy(() => SignalEvalFnSchema.array()),
  });

const LogicalAndEvalFnBaseSchema = z.object({
  type: z.literal('and'),
});
export type LogicalAndEvalFn = z.infer<typeof LogicalAndEvalFnBaseSchema> & {
  signalEvalFns: SignalEvalFn[];
};
const LogicalAndEvalFnSchema = LogicalAndEvalFnBaseSchema.extend({
  signalEvalFns: z.lazy(() => SignalEvalFnSchema.array()),
});

const KeyplaySignalEvalFnSchema = z.object({
  signal: KeyplayScoringSignalSchema,
  type: z.literal('keyplaySignal'),
});
export type KeyplaySignalEvalFn = z.infer<typeof KeyplaySignalEvalFnSchema>;

export type SignalEvalFn =
  | EqualEvalFn
  | FieldExistsEvalFn
  | FieldDoesNotExistEvalFn
  | IsTrueEvalFn
  | IsFalseEvalFn
  | NumberEvalFn
  | RangeEvalFn
  | LogicalOrEvalFn
  | LogicalAndEvalFn
  | KeyplaySignalEvalFn;
export const SignalEvalFnSchema: z.ZodType<SignalEvalFn> = z.lazy(() =>
  z.union([
    EqualEvalFnSchema,
    FieldExistsEvalFnSchema,
    FieldDoesNotExistEvalFnSchema,
    IsTrueEvalFnSchema,
    IsFalseEvalFnSchema,
    NumberEvalFnSchema,
    RangeEvalFnSchema,
    LogicalOrEvalFnSchema,
    LogicalAndEvalFnSchema,
    KeyplaySignalEvalFnSchema,
  ])
);

export const SignalDefinitionSchema = z.object({
  id: z.string(),
  label: z.string(),
  category: KeyplayScoringCategorySchema,
  evalFn: SignalEvalFnSchema,
  timestamp: z.date(),
  group: z.union([ObjectIdSchema, KeyplaySignalGroupSchema]).optional(),
  bonusPointsOnly: z.boolean().optional(),
});
export type SignalDefinition = z.infer<typeof SignalDefinitionSchema>;

const CreatableSignalDefinitionTypes = [
  'isTrue',
  'isFalse',
  'range',
] satisfies SignalEvalFn['type'][];
export const isCreatableSignalDefinitionType = typeguardFromArray(
  CreatableSignalDefinitionTypes
);

export type SignalEvalFnInput =
  | {
      field: ObjectId | AccountField;
      type: 'field';
    }
  | {
      signal: KeyplayScoringSignal;
      type: 'signal';
    };

export function getSignalEvalFnInputs(
  evalFn: SignalEvalFn
): SignalEvalFnInput[] {
  const {type} = evalFn;
  switch (type) {
    case 'equal':
    case 'fieldExists':
    case 'fieldDoesNotExist':
    case 'isTrue':
    case 'isFalse':
    case 'number':
    case 'range':
      return [
        {
          field: evalFn.field,
          type: 'field',
        },
      ];

    case 'keyplaySignal':
      return [
        {
          signal: evalFn.signal,
          type: 'signal',
        },
      ];

    case 'or':
    case 'and':
      return _.flatten(evalFn.signalEvalFns.map(getSignalEvalFnInputs));

    default:
      assertNever(type);
  }
}
