import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {useKeyplayApi} from '../../../context/ApiContext';
import {GetCustomerResponse} from '../../../shared/api/api';
import {UpdateCreditsRequest} from '../../../shared/api/requests';
import {ObjectId} from 'bson';
import {createApiQuery} from '../../../hooks/api/api';
import {GetCustomers} from '../../../shared/api/definitions';

export const CustomerQueryKeys = {
  all: ['debug', 'customers'] as const,
  list: () => [...CustomerQueryKeys.all, 'list'] as const,
  details: (customerId: ObjectId) =>
    [...CustomerQueryKeys.all, 'details', customerId.toString()] as const,
};

export const useGetCustomers = createApiQuery(
  '/debug/customers',
  GetCustomers,
  () => CustomerQueryKeys.list(),
  {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  }
);

export function useCustomerDetails(customerId: ObjectId) {
  const makeApiCall = useKeyplayApi();

  return useQuery({
    queryKey: CustomerQueryKeys.details(customerId),
    queryFn: () =>
      makeApiCall<GetCustomerResponse>(`/debug/customer/${customerId}`, {
        method: 'GET',
      }),
    staleTime: 60_000,
  });
}

export function useUpdateCreditsMutation(customerId: ObjectId) {
  const makeApiCall = useKeyplayApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      amount,
      description,
    }: {
      amount: number;
      description: string;
    }) =>
      makeApiCall<void, UpdateCreditsRequest>(
        '/debug/customer/updateCredits',
        {
          method: 'POST',
          data: {
            customerId: customerId.toString(),
            amount,
            description,
          },
        },
        {toastOnError: true}
      ),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: CustomerQueryKeys.details(customerId),
      });
    },
  });
}

export function useAddUserMutation() {
  const makeApiCall = useKeyplayApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: {
      email: string;
      customerId: ObjectId;
      isTemporary?: boolean;
    }) =>
      makeApiCall(
        '/debug/customer/addUser',
        {method: 'POST', data},
        {toastOnError: true}
      ),
    onSuccess: async (_, {customerId}) => {
      await queryClient.invalidateQueries({
        queryKey: CustomerQueryKeys.details(customerId),
      });
    },
  });
}

export function useRemoveUserMutation() {
  const makeApiCall = useKeyplayApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: {userId: string; customerId: ObjectId}) =>
      makeApiCall(
        '/debug/customer/removeUser',
        {method: 'POST', data},
        {toastOnError: true}
      ),
    onSuccess: async (_, {customerId}) => {
      await queryClient.invalidateQueries({
        queryKey: CustomerQueryKeys.details(customerId),
      });
    },
  });
}
