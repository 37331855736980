import React, {useCallback, useEffect, useState} from 'react';
import {
  Flex,
  FormControl,
  Divider,
  Text,
  Button,
  Grid,
  Input,
  FormLabel,
  Textarea,
} from '@chakra-ui/react';
import {AnalysisFieldsEditor} from './AnalysisFieldsEditor';
import {KeyClarificationsEditor} from './KeyClarificationsEditor';
import {useUpdateSelectedVersionConfig} from './AiFieldBuilder.state';
import {ChakraClose} from '../../Icons/Carbon';
import {AiCategoryFieldDefinitionConfig} from '../../../shared/enrichment';

export const CategoriesConfigEditor = ({
  config,
}: {
  config: AiCategoryFieldDefinitionConfig;
}) => {
  const updateSelectedVersionConfig = useUpdateSelectedVersionConfig();

  const addEmptyCategory = useCallback(
    () =>
      updateSelectedVersionConfig({
        ...config,
        categories: [
          ...config.categories,
          {
            name: '',
            definition: '',
            examples: [],
          },
        ],
      }),
    [config, updateSelectedVersionConfig]
  );

  useEffect(() => {
    if (!config.categories.length) {
      addEmptyCategory();
    }
  }, [addEmptyCategory, config.categories]);

  return (
    <Flex direction="column" gap="6">
      <Text fontSize="xl">Define your categories</Text>

      <Flex direction="column" gap="4">
        <Flex direction="column" gap="2">
          {config.categories.map((_, idx) => (
            <CategoryEditor key={idx} categoryIndex={idx} config={config} />
          ))}
        </Flex>

        {config.categories.length <= 10 && (
          <Button w="fit-content" onClick={addEmptyCategory}>
            + Add category
          </Button>
        )}
      </Flex>

      <Divider />

      <AnalysisFieldsEditor config={config} />

      <Divider />

      <KeyClarificationsEditor config={config} />
    </Flex>
  );
};

const CategoryEditor = ({
  categoryIndex,
  config,
}: {
  categoryIndex: number;
  config: AiCategoryFieldDefinitionConfig;
}) => {
  const category = config.categories[categoryIndex];

  // Track example input separately since it requires trimming and filtering
  const [examplesInput, setExamplesInput] = useState(
    category.examples.join(', ')
  );

  const updateSelectedVersionConfig = useUpdateSelectedVersionConfig();

  const updateCategory = ({
    name,
    definition,
    examples,
  }: Partial<{
    name: string;
    definition: string;
    examples: string;
  }>) => {
    if (examples !== undefined) {
      setExamplesInput(examples);
    }

    updateSelectedVersionConfig({
      ...config,
      categories: config.categories.map((category, idx) =>
        idx === categoryIndex
          ? {
              ...category,
              ...(name !== undefined && {name}),
              ...(definition !== undefined && {definition}),
              ...(examples !== undefined && {
                examples: examples
                  .split(',')
                  .map((example) => example.trim())
                  .filter((example) => example.length > 0),
              }),
            }
          : category
      ),
    });
  };

  const removeCategory = () => {
    updateSelectedVersionConfig({
      ...config,
      categories: config.categories.filter((_, idx) => idx !== categoryIndex),
    });
  };

  return (
    <Flex
      border="1px solid"
      borderColor="kgray.200"
      borderRadius="md"
      direction="column"
      position="relative"
      gap="4"
      p="4"
      pt="3"
    >
      {config.categories.length > 1 && (
        <Button
          onClick={removeCategory}
          position="absolute"
          px="0"
          right="1"
          top="1"
          variant="ghost"
        >
          <ChakraClose boxSize="5" />
        </Button>
      )}

      <Text fontSize="lg">Category {categoryIndex + 1}</Text>

      <Grid templateColumns="1fr 1fr" gap="2">
        <FormControl>
          <FormLabel fontSize="sm" textTransform="uppercase">
            Category Name
          </FormLabel>
          <Input
            onChange={(e) => updateCategory({name: e.target.value})}
            placeholder="Short descriptive name"
            value={category.name}
          />
        </FormControl>
        <FormControl>
          <FormLabel fontSize="sm" textTransform="uppercase">
            Examples (optional)
          </FormLabel>
          <Input
            onChange={(e) => {
              updateCategory({examples: e.target.value});
            }}
            placeholder="2-5 example companies"
            value={examplesInput}
          />
        </FormControl>
      </Grid>
      <FormControl>
        <FormLabel fontSize="sm" textTransform="uppercase">
          Category Definition
        </FormLabel>
        <Textarea
          onChange={(e) => updateCategory({definition: e.target.value})}
          value={category.definition}
        />
      </FormControl>
    </Flex>
  );
};
