import React from 'react';
import {useUpdateSelectedVersionConfig} from './AiFieldBuilder.state';
import {
  Flex,
  FormControl,
  FormLabel,
  Textarea,
  FormHelperText,
  Divider,
  Text,
} from '@chakra-ui/react';
import {AnalysisFieldsEditor} from './AnalysisFieldsEditor';
import {KeyClarificationsEditor} from './KeyClarificationsEditor';
import {
  AiBooleanFieldDefinitionConfig,
  AiRatingFieldDefinitionConfig,
} from '../../../shared/enrichment';
import {useBuilderMode} from './BuilderModeContext';

export const PromptConfigEditor = ({
  config,
}: {
  config: AiBooleanFieldDefinitionConfig | AiRatingFieldDefinitionConfig;
}) => {
  const mode = useBuilderMode();
  const updateSelectedVersionConfig = useUpdateSelectedVersionConfig();

  return (
    <Flex direction="column" gap="6">
      <Text fontSize="xl">Write your prompt</Text>

      <FormControl>
        <FormLabel textTransform="uppercase">Prompt</FormLabel>
        <Textarea
          isDisabled={mode === 'review'}
          onChange={(e) => {
            updateSelectedVersionConfig({
              ...config,
              prompt: e.target.value,
            });
          }}
          rows={5}
          placeholder="Ask your AI analyst anything..."
          value={config.prompt}
        />
        <FormHelperText textColor="kgray.300">
          Prompting is most successful when you phrase your prompt as a question
          about a single account, like “Does this company have brick and mortar
          locations?”
        </FormHelperText>
      </FormControl>

      <Divider />

      <AnalysisFieldsEditor config={config} />

      <Divider />

      <KeyClarificationsEditor config={config} />
    </Flex>
  );
};
