import React from 'react';
import {Box, Button, Flex, Grid, Link, Text, useToast} from '@chakra-ui/react';
import {Integration} from './AllIntegrations';
import {
  CrmField,
  EnrichmentFieldLabels,
  AdditionalEnrichmentField,
  MultiMarketFields,
  AdditionalEnrichmentFieldConfig,
  FieldOverride,
} from '../../shared/integrations';
import {AddAlt, ChevronRight, TrashCan} from '@carbon/icons-react';
import {UseQueryResult} from '@tanstack/react-query';
import {useIntegrationDetailsState} from './IntegrationDetails.state';
import {DistributedOmit} from '../../shared/util';
import {useCustomer} from '../../hooks/api/metadata';
import {GridCell} from './components';
import {IntegrationSection} from './components';
import {GridHeader} from './components';
import Select, {OptionProps, components} from 'react-select';
import {IntegrationType} from '../../shared/integrations';
import {FieldDefinition} from '../../shared/enrichment';
import _ from 'lodash';
import {Market} from '../../shared/market';

export const AdditionalEnrichmentFieldMapping = ({
  integrationName,
  integrationInfo,
  availableEnrichmentFields,
  enrichmentFields,
  crmFieldsResult,
  markets,
}: {
  integrationName: IntegrationType;
  integrationInfo: Integration;
  availableEnrichmentFields: AdditionalEnrichmentField[];
  enrichmentFields: AdditionalEnrichmentFieldConfig[];
  crmFieldsResult: UseQueryResult<CrmField[]>;
  markets: Market[];
}) => {
  const {addAdditionalField} = useIntegrationDetailsState(integrationName);

  const toast = useToast();
  const customer = useCustomer();

  // Use for efficient lookups of existing fields
  const crmFieldLookups = new Set(
    enrichmentFields.map((field) => field.crmFieldName)
  );

  // These are all the enrichment field options to show in the select dropdown
  const enrichmentFieldOptions = getAllFieldConfigOptions(
    availableEnrichmentFields,
    enrichmentFields,
    markets,
    customer.fieldDefinitions ?? []
  );

  // These are all the CRM field options to show in the select dropdown
  const crmFieldOptions =
    crmFieldsResult.data?.map((field) => ({
      isDisabled: crmFieldLookups.has(field.name),
      label: field.label,
      value: field.name,
    })) ?? [];

  // When the user adds a new field mapping, we select the first available enrichment field
  // and set the CRM field to an empty string so the placeholder text is displayed.
  const addNewFieldMapping = () => {
    const firstAvailableField = enrichmentFieldOptions.find(
      ({isDisabled}) => !isDisabled
    );

    if (!firstAvailableField) {
      toast({
        title: 'Unable to add new field mapping',
        description: 'Contact Keyplay support for assistance.',
        status: 'error',
      });
      return;
    }

    const {fieldConfig} = firstAvailableField;
    addAdditionalField({
      ...fieldConfig,
      enabled: true,
      override: 'always',
    });
  };

  const hasMoreFieldsToAdd =
    enrichmentFields.length < enrichmentFieldOptions.length;

  return (
    <IntegrationSection>
      <Box fontSize={'lg'} fontWeight={500} mb={6}>
        Additional Field Mapping
      </Box>
      <Grid templateColumns={'70px 1fr min-content 1fr 220px'} gap={2}>
        {enrichmentFields.length > 0 && (
          <>
            <GridHeader></GridHeader>
            <GridHeader>Keyplay Field</GridHeader>
            <GridHeader></GridHeader>
            <GridHeader>{integrationInfo.label} Field</GridHeader>
            <GridHeader ms="4">Override?</GridHeader>
          </>
        )}

        {enrichmentFields.length === 0 && (
          <GridCell colSpan={6} px={0}>
            <Text fontStyle={'italic'}>No field mappings configured.</Text>
          </GridCell>
        )}

        {enrichmentFields.map((selectedField, index) => (
          <EnrichmentFieldGridRow
            key={index}
            crmFieldOptions={crmFieldOptions}
            crmFieldsResult={crmFieldsResult}
            enrichmentFieldOptions={enrichmentFieldOptions}
            selectedField={selectedField}
            integrationLabel={integrationInfo.label}
            integrationName={integrationName}
          />
        ))}

        {hasMoreFieldsToAdd && (
          <GridCell colSpan={2} px={2}>
            <Link
              alignItems={'center'}
              as={Flex}
              color="kblue.300"
              fontWeight={500}
              gap={2}
              onClick={addNewFieldMapping}
            >
              <AddAlt />
              Add a field
            </Link>
          </GridCell>
        )}
      </Grid>
    </IntegrationSection>
  );
};

function getAllFieldConfigOptions(
  availableEnrichmentFields: AdditionalEnrichmentField[],
  enrichmentFieldConfigs: AdditionalEnrichmentFieldConfig[],
  markets: Market[],
  fieldDefinitions: FieldDefinition[]
) {
  return [
    // For each additional enrichment field we create an option, except
    // for the multi-market fields, which we handle separately.
    ..._.difference(availableEnrichmentFields, MultiMarketFields).map(
      (field) => {
        const existingFieldConfig = enrichmentFieldConfigs.find(
          (config) =>
            config.kind === 'keyplay' && config.enrichmentField === field
        );

        return getFieldConfigOption({
          existingFieldConfig,
          defaultFieldConfig: {
            enrichmentField: field,
            kind: 'keyplay',
          },
          label: EnrichmentFieldLabels[field],
          value: field,
        });
      }
    ),
    // For each multi-market field, we create an option for each market
    ...MultiMarketFields.map((field) => {
      return markets.map((market) => {
        const existingFieldConfig = enrichmentFieldConfigs.find(
          (config) =>
            config.kind === 'keyplay' &&
            config.enrichmentField === field &&
            config.marketId?.equals(market.id)
        );
        const label =
          markets.length > 0
            ? market.label + ' ' + EnrichmentFieldLabels[field]
            : EnrichmentFieldLabels[field];

        return getFieldConfigOption({
          existingFieldConfig,
          defaultFieldConfig: {
            enrichmentField: field,
            kind: 'keyplay',
            marketId: market.id,
          },
          label,
          value: field + market.id,
        });
      });
    }).flat(),
    // Create an option for each custom field
    ...(fieldDefinitions.map(({id, label}) => {
      const existingFieldConfig = enrichmentFieldConfigs.find(
        (config) => config.kind === 'custom' && config.customFieldId.equals(id)
      );

      return getFieldConfigOption({
        existingFieldConfig,
        defaultFieldConfig: {
          customFieldId: id,
          kind: 'custom',
        },
        label: label,
        value: id.toString(),
      });
    }) ?? []),
  ].sort((a, b) => a.label.localeCompare(b.label));
}

function getFieldConfigOption({
  existingFieldConfig,
  defaultFieldConfig,
  label,
  value,
}: {
  existingFieldConfig?: AdditionalEnrichmentFieldConfig;
  defaultFieldConfig: DistributedOmit<
    AdditionalEnrichmentFieldConfig,
    'enabled' | 'override' | 'enrichmentFieldType' | 'crmFieldName'
  >;
  label: string;
  value: string;
}): FieldConfigOption {
  return {
    label,
    value,
    isDisabled: !!existingFieldConfig,
    fieldConfig: existingFieldConfig ?? {
      ...defaultFieldConfig,
      enabled: true,
      override: 'always',
      enrichmentFieldType: 'additional',
      crmFieldName: '',
    },
  };
}

type BaseOption = {
  isDisabled: boolean;
  label: string;
  value: string;
};
type FieldConfigOption = BaseOption & {
  fieldConfig: AdditionalEnrichmentFieldConfig;
};

type EnrichmentFieldGridRowProps = {
  crmFieldOptions: BaseOption[];
  crmFieldsResult: UseQueryResult<CrmField[]>;
  enrichmentFieldOptions: FieldConfigOption[];
  selectedField: AdditionalEnrichmentFieldConfig;
  integrationLabel: string;
  integrationName: IntegrationType;
};

interface OverrideSelectOption {
  value: FieldOverride;
  label: string;
  description: string;
}

const OverrideOption = (props: OptionProps<OverrideSelectOption>) => {
  const {label, description} = props.data;
  return (
    <components.Option {...props}>
      <Text>{label}</Text>
      <Text fontSize="small" mt="2" fontWeight="thin" color="kgray.300">
        {description}
      </Text>
    </components.Option>
  );
};

const EnrichmentFieldGridRow = ({
  crmFieldOptions,
  crmFieldsResult,
  enrichmentFieldOptions,
  selectedField,
  integrationLabel,
  integrationName,
}: EnrichmentFieldGridRowProps) => {
  const {deleteAdditionalField, updateAdditionalField, publishChanges} =
    useIntegrationDetailsState(integrationName);

  const onUpdateSelectedEnrichmentField = (
    oldFieldConfig: AdditionalEnrichmentFieldConfig,
    updatedFieldConfig: AdditionalEnrichmentFieldConfig
  ) => {
    updateAdditionalField(oldFieldConfig, updatedFieldConfig);

    if (updatedFieldConfig.crmFieldName) {
      publishChanges.mutate();
    }
  };

  const onDeleteSelectedField = (
    deletedField: AdditionalEnrichmentFieldConfig
  ) => {
    deleteAdditionalField(deletedField);
    publishChanges.mutate();
  };

  let crmFieldValue = '';
  if (crmFieldsResult.isPending) {
    crmFieldValue = 'Loading...';
  } else if (selectedField.crmFieldName) {
    const crmField = crmFieldsResult.data?.find(
      (field) => field.name === selectedField.crmFieldName
    );
    // It's possible we have a field mapping for a field that no longer exists in HubSpot
    crmFieldValue = crmField?.label ?? 'unknown field';
  }

  const overrideOptions = [
    {
      value: 'always',
      label: 'Always override',
      description: 'Always override this field',
    },
    {
      value: 'ifEmpty',
      label: 'No override',
      description: 'Only write to this field if blank',
    },
  ] satisfies OverrideSelectOption[];

  return (
    <>
      <GridCell p={0} justifyContent={'center'}>
        <Button
          colorScheme="red"
          fontSize={14}
          onClick={() => {
            onDeleteSelectedField(selectedField);
          }}
          variant="outline"
        >
          <TrashCan />
        </Button>
      </GridCell>

      <GridCell p={0}>
        <Select
          hideSelectedOptions={true}
          menuPlacement="top"
          onChange={(selectedOption) => {
            if (!selectedOption) {
              return;
            }

            const {fieldConfig} = selectedOption;
            onUpdateSelectedEnrichmentField(selectedField, {
              ...fieldConfig,
              crmFieldName: selectedField.crmFieldName,
            });
          }}
          options={enrichmentFieldOptions}
          styles={{
            container: (base) => ({
              ...base,
              flexGrow: 1,
            }),
          }}
          value={enrichmentFieldOptions.find((option) =>
            _.isEqual(option.fieldConfig, selectedField)
          )}
        />
      </GridCell>
      <GridCell>
        <ChevronRight />
      </GridCell>
      <GridCell p={0}>
        <Select
          hideSelectedOptions={true}
          menuPlacement="top"
          onChange={(selectedOption) => {
            if (!selectedOption) {
              return;
            }

            onUpdateSelectedEnrichmentField(selectedField, {
              ...selectedField,
              crmFieldName: selectedOption.value,
            });
          }}
          options={crmFieldOptions}
          placeholder={`Select a ${integrationLabel} field`}
          styles={{
            container: (base) => ({
              ...base,
              flexGrow: 1,
            }),
          }}
          isLoading={crmFieldsResult.isPending}
          value={
            crmFieldValue && {
              label: crmFieldValue,
              value: selectedField.crmFieldName,
            }
          }
        />
      </GridCell>
      <GridCell p={0} ms="4">
        <Select
          components={{Option: OverrideOption}}
          isMulti={false}
          isSearchable={false}
          menuPlacement="top"
          options={overrideOptions}
          placeholder={'Select...'}
          value={overrideOptions.find(
            (o) => o.value === selectedField.override
          )}
          onChange={(selectedOption) => {
            if (!selectedOption) {
              return;
            }

            onUpdateSelectedEnrichmentField(selectedField, {
              ...selectedField,
              override: selectedOption.value,
            });
          }}
          styles={{
            container: (base) => ({
              ...base,
              flexGrow: 1,
            }),
            option: (provided, state) => ({
              ...provided,
              backgroundColor: state.isFocused ? '#EDF2FD' : '',
              color: 'inherit',
              ':hover': {
                backgroundColor: '#EDF2FD',
              },
            }),
          }}
        />
      </GridCell>
    </>
  );
};
