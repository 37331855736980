import React from 'react';
import {
  Box,
  Button,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  UnorderedList,
} from '@chakra-ui/react';
import {useMarkets} from '../../hooks/api/metadata';
import {SignalDefinition} from '../../shared/signalDefinition';
import {getSignalsInMarket} from '../../shared/market';
import {useDeleteSignalDefinition} from '../../hooks/api/signalDefinitions';

export const DeleteSignalModal = ({
  signalDefinition,
  isOpen,
  onClose,
}: {
  signalDefinition: SignalDefinition;
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <DeleteSignalModalContent
        signalDefinition={signalDefinition}
        onClose={onClose}
      />
    </Modal>
  );
};

const DeleteSignalModalContent = ({
  signalDefinition,
  onClose,
}: {
  signalDefinition: SignalDefinition;
  onClose: () => void;
}) => {
  const {id: signalId, label: signalLabel} = signalDefinition;
  const markets = useMarkets();
  const marketsUsedIn = markets.filter((market) =>
    getSignalsInMarket(market).includes(signalId)
  );
  const deleteSignalDefinition = useDeleteSignalDefinition();

  return (
    <ModalContent>
      <ModalCloseButton />
      {marketsUsedIn.length ? (
        <>
          <ModalHeader>Unable to Delete &apos;{signalLabel}&apos;</ModalHeader>
          <ModalBody>
            <Box>
              This signal is currently being used in the following markets:
            </Box>
            <UnorderedList ps="4" pt="4">
              {marketsUsedIn.map((market) => (
                <ListItem key={market.id.toString()}>{market.label}</ListItem>
              ))}
            </UnorderedList>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>OK</Button>
          </ModalFooter>
        </>
      ) : (
        <>
          <ModalHeader>Delete &apos;{signalLabel}&apos;?</ModalHeader>
          <ModalBody>
            Are you sure you want to delete &apos;{signalLabel}&apos;?
          </ModalBody>
          <ModalFooter gap="4">
            <Button onClick={onClose}>Cancel</Button>
            <Button
              colorScheme="red"
              isLoading={deleteSignalDefinition.isPending}
              onClick={() => {
                deleteSignalDefinition.mutate({signalId});
                onClose();
              }}
            >
              Delete
            </Button>
          </ModalFooter>
        </>
      )}
    </ModalContent>
  );
};
