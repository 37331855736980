import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Flex,
  Button,
} from '@chakra-ui/react';
import {useInviteMutation} from '../../hooks/api/users';
import {useForm} from 'react-hook-form';

interface InviteUserModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const InviteUserModal = ({isOpen, onClose}: InviteUserModalProps) => {
  const inviteUser = useInviteMutation();
  const {
    formState: {errors},
    getValues,
    handleSubmit,
    register,
    reset,
    watch,
  } = useForm<{email: string}>({defaultValues: {email: ''}});
  const email = watch('email');

  const closeModal = () => {
    reset();
    onClose();
  };

  const onHandleSubmit = () => {
    inviteUser.mutate(getValues('email'), {
      onSuccess: closeModal,
    });
  };

  return (
    <Modal isCentered={true} isOpen={isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent pb="4">
        <ModalCloseButton />
        <ModalHeader>Invite user</ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(onHandleSubmit)}>
            <FormControl isInvalid={!!errors.email} mb="4">
              <FormLabel textTransform="uppercase">User</FormLabel>
              <Input
                autoComplete="off"
                placeholder="Email address..."
                type="email"
                {...register('email', {
                  maxLength: 255,
                  required: true,
                })}
              />
              <FormErrorMessage>
                {errors.email && 'Please enter a valid email address.'}
              </FormErrorMessage>
            </FormControl>
            <Flex gap="2" mt="10">
              <Button
                colorScheme="kbuttonblue"
                isDisabled={!email.length}
                isLoading={inviteUser.isPending}
                type="submit"
              >
                Send invite
              </Button>
              <Button onClick={closeModal}>Cancel</Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
