import * as React from 'react';
import {AccountsTable} from './AccountGrid.table';
import {useQueryClient} from '@tanstack/react-query';
import {
  AbsoluteCenter,
  Box,
  Flex,
  Grid,
  Spinner,
  Toast,
  useDisclosure,
} from '@chakra-ui/react';
import {AccountDetails} from './AccountGrid.details';
import {useEffect, useRef, useState} from 'react';
import {ObjectId} from 'bson';
import _ from 'lodash';
import {AccountsTableHeader} from './AccountGrid.header';
import {useReactTable, getCoreRowModel} from '@tanstack/react-table';
import {useColumnDefinitions} from './AccountGrid.columns';
import {AccountGridSkeleton} from './AccountGrid.skeleton';
import {useUserControls} from './AccountGrid.controlstate';
import {AccountView} from '../../shared/api/helpers';
import {ScoredAccount} from '../../shared/scoredAccounts';
import {track} from '../../analytics';
import {useIsRescoring} from '../../hooks/api/scoringInfo';
import {SavedAccountsStatusBar} from './SavedAccountsStatusBar';
import {RescoringStatusBar} from './RescoringStatusBar';
import {useScoredAccounts} from '../Hooks/scoredAccounts';
import {fromEntries} from '../../shared/util';
import {
  NumAutoSelectAccounts,
  useShouldAutoSelectRows,
} from './useShouldAutoSelectRows';
import {SavedAccountsModalOrToast} from './SavedAccountsModal';
import {ScoringRunProvider} from '../../context/ScoringRunContext';

const AccountViewContext = React.createContext<AccountView | undefined>(
  undefined
);
export const useAccountView = (): AccountView => {
  const context = React.useContext(AccountViewContext);
  if (!context) {
    throw new Error(
      'useAccountView must be used within an AccountViewProvider'
    );
  }
  return context;
};
interface AccountGridProps {
  gridView: AccountView;
}

const AccountGrid = ({gridView}: AccountGridProps) => (
  <AccountViewContext.Provider value={gridView}>
    <_AccountGrid />
  </AccountViewContext.Provider>
);

const _AccountGrid = () => {
  const accountView = useAccountView();
  // state for whether the details pane is open or not
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [selectedAccount, setSelectedAccount] = useState<ScoredAccount | null>(
    null
  );

  // Data Fetching. Our sort/filter controls alter the query we send to the server
  // (we have server-side-driven table controls)

  const userControls = useUserControls();
  const {
    columnVisibility,
    numBulkSelected,
    rowSelection,
    setColumnVisibility,
    setRowSelection,
  } = userControls;

  const {
    fetchNextPage,
    hasNextPage,
    isFetching,
    isPending,
    isPlaceholderData,
    fetchedAccounts,
    totalCount,
    scoringRun,
  } = useScoredAccounts();

  // variables for the data that we've fetched.
  const totalFetched = fetchedAccounts.length;

  useEffect(() => {
    // Push updated account to the account details pane.
    // This creates a better UX for the user, otherwise they would need to
    // navigate away before they see any account changes.
    if (!selectedAccount) {
      return;
    }

    const updatedAccount = fetchedAccounts.find((account) =>
      account.accountId.equals(selectedAccount.accountId)
    );
    if (updatedAccount) {
      setSelectedAccount(updatedAccount);
    }
  }, [fetchedAccounts, selectedAccount]);

  // only for scoredAccounts (default) endpoint

  const isRescoring = useIsRescoring();

  // invalidate and refetch accounts data if a rescore finishes
  const queryClient = useQueryClient();
  const rescoreStarted = useRef<boolean>(false);
  useEffect(() => {
    if (isRescoring) {
      rescoreStarted.current = true;
    } else if (rescoreStarted.current) {
      rescoreStarted.current = false;
      queryClient.invalidateQueries({queryKey: ['scoredAccountsTables']});
    }
  }, [isRescoring, queryClient, rescoreStarted]);

  const onOpenDetails = React.useCallback(
    (accountId: ObjectId) => {
      const matchingAccount = fetchedAccounts?.filter((acc) =>
        acc.accountId.equals(accountId)
      );
      if (matchingAccount?.length) {
        track('accountDetailsOpened');
        setSelectedAccount(matchingAccount[0]);
        onOpen();
      }
    },
    [fetchedAccounts, onOpen]
  );

  const shouldAutoSelectRows = useShouldAutoSelectRows();
  useEffect(() => {
    if (shouldAutoSelectRows) {
      const topAccountsSelection = fromEntries(
        fetchedAccounts
          .slice(0, NumAutoSelectAccounts)
          .map((account) => [account.accountId.toString(), true])
      );

      setRowSelection(topAccountsSelection);
    }
  }, [fetchedAccounts, shouldAutoSelectRows, setRowSelection]);

  // The table itself.
  const columnDefinitions = useColumnDefinitions(onOpenDetails);
  const table = useReactTable({
    columns: columnDefinitions,
    data: fetchedAccounts,
    state: {
      columnVisibility,
      rowSelection,
    },
    getCoreRowModel: getCoreRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    getRowId: (row) => row.accountId.toString(),
  });

  /* End of hooks */

  // navigating within details pane
  const onNextOrPrev = (
    accountId: ObjectId | string,
    direction: 'next' | 'prev'
  ) => {
    const current = _.findIndex(fetchedAccounts, (acc) =>
      acc.accountId.equals(accountId)
    );
    if (current === -1) {
      console.log("wasn't able to find current account in accounts list");
      return;
    }
    const newIndex = direction === 'next' ? current + 1 : current - 1;
    if (
      totalFetched > 0 &&
      newIndex >= 0 &&
      newIndex < fetchedAccounts.length
    ) {
      track('accountDetailsNavigated', {
        properties: {
          direction,
        },
      });
      setSelectedAccount(fetchedAccounts[newIndex]);
    }
    if (newIndex < totalFetched - 10 && hasNextPage) {
      fetchNextPage();
    }
  };

  if (isPending) {
    return <AccountGridSkeleton />;
  }

  let selectAllAccountsMessage = null;
  if (numBulkSelected) {
    selectAllAccountsMessage = (
      <Box
        position="absolute"
        bottom="20px"
        zIndex={50}
        left="50%"
        transform="translateX(-50%)"
      >
        <Toast
          status="info"
          description={`${
            numBulkSelected === totalCount ? 'All' : 'Top'
          } ${numBulkSelected.toLocaleString()} accounts selected`}
        />
      </Box>
    );
  }

  const stickyStatusBar =
    selectAllAccountsMessage ||
    (accountView === 'saved' && !isRescoring && <SavedAccountsStatusBar />) ||
    (isRescoring && <RescoringStatusBar />);

  return !scoringRun ? (
    <InitialLoading />
  ) : (
    <ScoringRunProvider scoringRun={scoringRun}>
      <>
        <Grid templateRows="71px 1fr" position="relative" fontSize="14px">
          <AccountsTableHeader
            table={table}
            totalFetched={totalFetched}
            numberOfAccounts={totalCount}
            fetchedAccounts={fetchedAccounts}
          />
          <AccountsTable
            table={table}
            fetchedAccounts={fetchedAccounts}
            totalFetched={totalFetched}
            numberOfAccounts={totalCount}
            isFetching={isFetching}
            isFetchingDifferentQuery={isPlaceholderData}
            onOpenDetails={onOpenDetails}
            fetchNextPage={fetchNextPage}
          />
        </Grid>
        <AccountDetails
          isOpen={isOpen}
          onClose={onClose}
          onNextOrPrev={onNextOrPrev}
          selectedAccount={selectedAccount}
        />

        {stickyStatusBar}
        <SavedAccountsModalOrToast />
      </>
    </ScoringRunProvider>
  );
};

const InitialLoading = () => {
  return (
    <Flex flexDir="column" h="100%" position="relative">
      <AbsoluteCenter>
        <Flex alignItems="center" direction="column" flex="1">
          <Spinner color="kred.300" size="lg" mb={4} />
          <Box fontSize="lg" mb={2}>
            Building your list.
          </Box>
          <Box color="kgray.300">
            If this takes more than a few minutes, try refreshing the page.
          </Box>
        </Flex>
      </AbsoluteCenter>
    </Flex>
  );
};

export default AccountGrid;
