import {chakra, Flex, Grid} from '@chakra-ui/react';

export const TwoColumnGrid = chakra(Grid, {
  baseStyle: {
    gridTemplateColumns: '50% 50%',
    h: '100%',
  },
});

export const LeftColumnContent = chakra(Flex, {
  baseStyle: {
    flex: '1',
    flexDirection: 'column',
    px: '6',
    pb: '6',
  },
});

export const ContentHeaderSpacePx = '56px';

export const RightColumnContent = chakra(Flex, {
  baseStyle: {
    bg: '#FDFDFE',
    flex: '1',
    flexDirection: 'column',
    gap: '4',
    px: '6',
    pb: '6',
    pt: ContentHeaderSpacePx,
  },
});
