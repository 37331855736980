import {useQuery} from '@tanstack/react-query';
import {useKeyplayApi} from '../../context/ApiContext';
import {TaskStatusRequest, TaskStatusResponse} from '../../shared/api/api';
import {TaskState} from '../../shared/task';
import {useEffect, useState} from 'react';

type TaskStatusOptions = {
  enabled?: boolean;
  taskType: TaskStatusRequest['type'] | undefined;
  onTaskComplete?: (state: TaskState) => void;
};

export const TaskStatusQueryKeys = {
  all: ['taskStatus'],
  task: (taskType: TaskStatusRequest['type'] | undefined) => [
    ...TaskStatusQueryKeys.all,
    taskType,
  ],
} as const;

export function useTaskStatus({
  enabled,
  taskType,
  onTaskComplete,
}: TaskStatusOptions) {
  const keyplayApi = useKeyplayApi();
  const [isTaskInProgress, setTaskInProgress] = useState(false);

  const taskStatus = useQuery({
    queryKey: TaskStatusQueryKeys.task(taskType),
    queryFn: () =>
      keyplayApi<TaskStatusResponse>('/task', {params: {type: taskType}}),
    enabled: !!taskType && enabled,
    refetchInterval: (query) => {
      const state = query.state.data?.task?.state;
      // Refetch more often while the task is pending or running.
      if (state === 'pending' || state === 'running') {
        return 10_000;
      }

      return 60_000;
    },
  });

  useEffect(() => {
    const task = taskStatus.data?.task;
    if (!task) {
      return;
    }

    setTaskInProgress((prevTaskInProgress) => {
      const taskInProgress =
        task.state === 'pending' || task.state === 'running';
      if (prevTaskInProgress && !taskInProgress) {
        onTaskComplete?.(task.state);
      }

      return taskInProgress;
    });
  }, [onTaskComplete, taskStatus.data]);

  return {
    taskStatus,
    isTaskInProgress,
  };
}
