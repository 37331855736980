import React from 'react';
import {WarningAlt} from '@carbon/icons-react';
import {
  Box,
  useToast,
  Flex,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  FormErrorMessage,
  Button,
  Alert,
  AlertIcon,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';
import {useForm, SubmitHandler} from 'react-hook-form';
import {FileValidationError, validateFile} from '../../../lib/validateFile';
import {TestListName} from '../../../shared/validation';
import {FileChooser} from '../../File/FileChooser';
import {FileValidationErrors} from '../../File/FileValidationErrors';
import * as Sentry from '@sentry/react';
import {useModelTestListImportState} from './ModelTestListImport.state';
import {z} from 'zod';
import {zodTypeguard} from '../../../shared/api/helpers';
import {usePublishModelTestList} from '../../../hooks/api/scoringModel';
import {useMarketFromContext} from '../MarketProvider';
import {MaxModelTestAccounts} from '../../../shared/api/definitions';

const TestListRowSchema = z.object({
  Domain: z.string(),
});
const isTestListRow = zodTypeguard(TestListRowSchema);

const FileChooserStep = () => {
  const {setState} = useModelTestListImportState();

  return (
    <>
      <FileChooser
        selectedFile={null}
        onSelectedFile={(file) => {
          validateFile({
            maxRows: MaxModelTestAccounts,
            onFileValidate: (errors, data) => {
              if (errors.size) {
                setState({
                  fileValidationErrors: errors,
                  step: 'errors',
                });
              } else {
                setState({
                  step: 'uploadFile',
                  domains: data.map((row) => row.Domain),
                });
              }
            },
            rowSchemaTypeguard: isTestListRow,
            selectedFile: file,
          });
        }}
      />

      <InfoBox />
    </>
  );
};

interface FormData {
  label: string;
}

const UploadFileStep = ({onClose}: {onClose: () => void}) => {
  const toast = useToast();
  const addList = usePublishModelTestList();
  const {id: marketId} = useMarketFromContext();

  const {domains} = useModelTestListImportState();
  const {
    formState: {errors},
    handleSubmit,
    getValues,
    register,
  } = useForm<FormData>();

  const handleImportClick: SubmitHandler<FormData> = () => {
    if (!domains) {
      return;
    }
    addList.mutate(
      {
        label: getValues('label'),
        domains,
        marketId,
      },
      {
        onSuccess: () => {
          toast({
            status: 'success',
            title: 'List has been created and domains are processing!',
            duration: 5_000,
            isClosable: true,
          });
          onClose();
        },
      }
    );
  };

  const isPending = addList.isPending;
  const {minLength, maxLength, pattern} = TestListName;

  return (
    <Flex direction="column" alignItems="center" gap={8}>
      <Flex alignItems="center">
        Your file looks amazing! Lets add a{' '}
        <Box mx={1} as="b">
          name
        </Box>
        .
      </Flex>
      <Flex direction="column" gap={8}>
        <Box as="form" onSubmit={handleSubmit(handleImportClick)} width="100%">
          <Flex direction="column" alignItems="center">
            <FormControl isRequired isInvalid={!!errors.label} width="50%">
              <FormLabel>List name</FormLabel>
              <Input
                isDisabled={isPending}
                isInvalid={!!errors.label}
                size="md"
                maxLength={maxLength}
                {...register('label', {
                  minLength,
                  maxLength,
                  pattern,
                  required: true,
                })}
              />
              {!errors.label ? (
                <FormHelperText>Input a list name</FormHelperText>
              ) : (
                <FormErrorMessage>Invalid group name.</FormErrorMessage>
              )}
            </FormControl>
            <Button
              colorScheme="kbuttonblue"
              m={4}
              type="submit"
              isLoading={isPending}
            >
              Create
            </Button>
          </Flex>
        </Box>
        <Alert status="info">
          <AlertIcon />
          List name must contain only letters and numbers and be between{' '}
          {minLength} and {maxLength} characters.
        </Alert>
      </Flex>
    </Flex>
  );
};

const validationErrorText = (fileValidationError: FileValidationError) => {
  switch (fileValidationError) {
    case 'file_too_large':
      return `File is too large. Max of ${MaxModelTestAccounts} rows per import.`;
    case 'missing_columns':
      return (
        <Box>
          Required columns not found. Start with a
          <ImportTemplateLink text="CSV import template" />.
        </Box>
      );
  }
};

const ValidationErrorsStep = () => {
  const {setState, fileValidationErrors} = useModelTestListImportState();
  if (!fileValidationErrors) {
    Sentry.captureMessage('validation errors step but no errors detected');
    return <></>;
  }

  return (
    <FileValidationErrors
      fileValidationErrors={fileValidationErrors}
      validationErrorFn={validationErrorText}
      onChooseAnotherFile={() =>
        setState({
          step: 'chooseFile',
        })
      }
    />
  );
};

export const ModelTestListImportModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const {step, reset} = useModelTestListImportState();

  const closeModal = () => {
    reset();
    onClose();
  };

  return (
    <Modal isCentered={true} isOpen={isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent minW="800" p={4}>
        <ModalHeader textAlign="center" fontSize="xl">
          Add Test List
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          p={4}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          {step === 'chooseFile' && <FileChooserStep />}
          {step === 'uploadFile' && <UploadFileStep onClose={closeModal} />}
          {step === 'errors' && <ValidationErrorsStep />}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const InfoBox = () => {
  return (
    <Box backgroundColor="kyellow.100" p={4} gap={4} width="100%">
      <Box alignItems="center" display="flex">
        <WarningAlt
          style={{
            display: 'inline',
            marginRight: '4px',
          }}
        />
        Imports need to include a column for Domain.
        <ImportTemplateLink text="Download a CSV import template" />.
      </Box>
    </Box>
  );
};

const ImportTemplateLink = ({text}: {text: string}) => {
  return (
    <Link
      color="kblue.400"
      download={true}
      href="/Keyplay_TestList_Import_Template.csv"
      ml={1}
    >
      {text}
    </Link>
  );
};
