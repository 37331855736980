import React from 'react';
import {
  Box,
  BoxProps,
  Image,
  Spinner,
  Text,
  Tooltip,
  Flex,
  Tag,
  VStack,
  Center,
  Skeleton,
} from '@chakra-ui/react';
import {useSimilarityState} from '../ModelBuilder/Similarity.state';
import {
  BucketType,
  useScoringModelSignalStore,
} from '../ModelBuilder/SignalAssignmentStore';
import {entries} from '../../../shared/util';
import {SimpleSignal} from '../../signals';
import {ScoringModeSelector} from '../ModelBuilder/ScoringModeSelector';
import {ScrollableFlex, WrapperFlex} from '../../utils/scrolling';
import {
  LocationLabels,
  useSamEditorStore,
} from '../../SamEditor/useSamEditorStore';
import {BusinessAudienceLabels} from '../../../shared/account';
import {pluralize} from '../../../lib/helpers';
import {useAccountWithLogo} from '../../../hooks/api/accountWithLogo';
import {ObjectId} from 'bson';

const OverviewSection = ({children, ...props}: BoxProps) => (
  <Box
    px={6}
    pt={6}
    bgColor="kgray.50"
    borderColor="kgray.200"
    borderWidth="1px"
    borderRadius="lg"
    width="100%"
    {...props}
  >
    {children}
  </Box>
);

const LookalikeImage = ({accountId}: {accountId: ObjectId}) => {
  const {data: account, isPending, isError} = useAccountWithLogo(accountId);
  if (isPending) {
    return <Skeleton boxSize="32px" />;
  }

  if (!account || isError) {
    return null;
  }

  return (
    <Tooltip
      hasArrow={false}
      py="1"
      label={account.name}
      key={account.domain}
      aria-label="tooltip"
    >
      <Image
        key={account.logoUrl}
        src={account.logoUrl}
        alt="icon"
        boxSize="32px"
        fallbackSrc="/accountFallback.png"
      />
    </Tooltip>
  );
};

const LookalikesSummary = () => {
  const {hasInitialized, localState} = useSimilarityState();

  let content;

  if (!hasInitialized) {
    content = <Spinner />;
  } else {
    if (!localState.length) {
      content = (
        <Text color="kgray.300" fontWeight="thin">
          No accounts added yet {'\u2013'} define your lookalike list to use
          similarity in your account scoring.
        </Text>
      );
    } else {
      const maxItemsToShow = 10;
      const overflowAmount = localState.length - maxItemsToShow;

      content = (
        <>
          {localState.slice(0, maxItemsToShow).map((accountId) => (
            <LookalikeImage key={accountId.toString()} accountId={accountId} />
          ))}
          {overflowAmount > 0 && (
            <Text color="kgray.300" fontWeight="normal">
              + {overflowAmount} more
            </Text>
          )}
        </>
      );
    }
  }

  return (
    <>
      <Flex alignItems="center" gap="2" position="relative">
        <Text fontSize="2xl">Lookalikes</Text>
        <Tag borderRadius="full">{localState.length}</Tag>
      </Flex>
      <Flex
        alignContent="center"
        alignItems="center"
        flexWrap="wrap"
        py={6}
        gap={5}
        rowGap={6}
      >
        {content}
      </Flex>
    </>
  );
};

const SignalsSummary = () => {
  const {hasInitialized, clientWeights, clientBuckets} =
    useScoringModelSignalStore();
  const numSignals = entries(clientBuckets).length;

  let content;

  if (!hasInitialized) {
    content = <Spinner />;
  } else {
    if (!numSignals) {
      content = (
        <Text color="kgray.300" fontWeight="thin">
          No signals added yet {'\u2013'} add some on the Signals tab.
        </Text>
      );
    } else {
      const maxItemsToShow = 5;

      const renderBucket = (bucketName: BucketType) => {
        const signalsToRender = entries(clientBuckets)
          .filter(([_signal, bucket]) => bucket === bucketName)
          .sort(
            (a, b) => (clientWeights[b[0]] ?? 0) - (clientWeights[a[0]] ?? 0)
          );
        const overflowAmount = signalsToRender.length - maxItemsToShow;

        if (!signalsToRender.length) {
          return <></>;
        }

        return (
          <Box mb={5}>
            <Box
              fontWeight="normal"
              fontSize="sm"
              textTransform="uppercase"
              mb={3}
              color="kgray.300"
            >
              {bucketName}
            </Box>

            <Flex
              alignContent="center"
              alignItems="center"
              flexWrap="wrap"
              gap={4}
              rowGap={3}
            >
              {signalsToRender.slice(0, maxItemsToShow).map(([signal]) => (
                <SimpleSignal
                  key={signal}
                  signal={signal}
                  weight={clientWeights[signal]}
                  fontSize="sm"
                />
              ))}
              {overflowAmount > 0 && (
                <Text color="kgray.300" fontWeight="normal">
                  + {overflowAmount} more
                </Text>
              )}
            </Flex>
          </Box>
        );
      };

      content = (
        <Box>
          {renderBucket('positive')}
          {renderBucket('negative')}
        </Box>
      );
    }
  }

  return (
    <>
      <Flex alignItems="center" gap="2" position="relative">
        <Text fontSize="2xl">Signals</Text>
        <Tag borderRadius="full">{numSignals}</Tag>
      </Flex>
      <Flex
        alignContent="center"
        alignItems="center"
        flexWrap="wrap"
        py={6}
        gap={2}
        rowGap={4}
      >
        {content}
      </Flex>
    </>
  );
};

const ServiceableMarketSummary = () => {
  const {
    audience,
    primaryCategories,
    secondaryCategories,
    excludedPrimaryCategories,
    excludedSecondaryCategories,
    minEmployees,
    maxEmployees,
    locations,
    locationsType,
    stackFitSignals,
    advancedSignalGroups,
  } = useSamEditorStore();

  const audienceText = audience
    .map((a) => BusinessAudienceLabels[a])
    .join(' or ');
  const categoriesCount = primaryCategories.length + secondaryCategories.length;
  const industryText =
    categoriesCount > 0
      ? pluralize(categoriesCount, 'industry', 'industries')
      : 'any industry';
  const excludedCategoriesCount =
    (excludedPrimaryCategories?.length ?? 0) +
    (excludedSecondaryCategories?.length ?? 0);
  const excludedIndustryText =
    excludedCategoriesCount > 0
      ? ` (excluding ${pluralize(
          excludedCategoriesCount,
          'industry',
          'industries'
        )}) `
      : '';
  const employeeText =
    minEmployees === undefined && maxEmployees === undefined
      ? 'any number of'
      : minEmployees === undefined
        ? `up to ${maxEmployees?.toLocaleString()}`
        : maxEmployees === undefined
          ? `at least ${minEmployees?.toLocaleString()}`
          : `${minEmployees.toLocaleString()}-${maxEmployees.toLocaleString()}`;
  const locationsText =
    locations.length > 0
      ? `with ${LocationLabels[locationsType]} in ${pluralize(
          locations.length,
          'location',
          'locations'
        )}`
      : 'in any location';
  const totalSignals =
    stackFitSignals.length + advancedSignalGroups.flat().length;
  const signalsText =
    totalSignals > 0 ? ` and matching ${totalSignals} signals` : '';

  return (
    <Flex direction="column" gap="6" pb="6">
      <Text fontSize="2xl">Serviceable Market</Text>
      <Text textColor="kgray.300" lineHeight="1.5">
        We sell to {audienceText} companies that are in {industryText}{' '}
        {excludedIndustryText} who have {employeeText} employees {locationsText}
        {signalsText}.
      </Text>
    </Flex>
  );
};

export const Overview = () => {
  return (
    <WrapperFlex>
      <ScrollableFlex p={0}>
        <Center>
          <Box minWidth="600px" width="50%" py={6}>
            <VStack gap="3">
              <OverviewSection>
                <Text fontSize="2xl">Score Mode</Text>
                <Text
                  color="kgray.300"
                  fontWeight="thin"
                  fontSize="sm"
                  mt={1.5}
                >
                  Choose how lookalike scores are combined with signal scores to
                  create your overall score
                </Text>
                <Box my={6} maxWidth="400px">
                  <ScoringModeSelector />
                </Box>
              </OverviewSection>
              <OverviewSection>
                <ServiceableMarketSummary />
              </OverviewSection>
              <OverviewSection>
                <LookalikesSummary />
              </OverviewSection>
              <OverviewSection>
                <SignalsSummary />
              </OverviewSection>
            </VStack>
          </Box>
        </Center>
      </ScrollableFlex>
    </WrapperFlex>
  );
};
