import React, {useState} from 'react';
import {
  Button,
  Divider,
  Grid,
  GridItem,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import {
  useSignalBuilderNavStore,
  useSignalBuilderStore,
} from './SignalBuilder.state';
import {useCustomer} from '../../../hooks/api/metadata';
import Select from 'react-select';
import {
  ScoringCategories,
  ScoringCategoryLabels,
} from '../../../shared/signals';
import _ from 'lodash';
import {useCreateSignalDefinition} from '../../../hooks/api/signalDefinitions';
import {ObjectId} from 'bson';
import {RangeSignalBuilder} from './RangeSignalBuilder';
import {Label} from './helpers';

import {BooleanSignalBuilder} from './BooleanSignalBuilder';
import {
  AccountFieldOrCustomField,
  getSignalEvalFnInputs,
  SignalDefinition,
  SignalEvalFn,
} from '../../../shared/signalDefinition';
import {CustomSignalFields, isAccountField} from '../../../shared/fields';
import {useFieldResolver, useResolvedField} from '../../../hooks/fields';
import {filterNull} from '../../../shared/util';
import {AccountFieldToSignalGroup} from '../../../shared/signalGroups';

export const CreateSignalModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const {setStep} = useSignalBuilderNavStore();
  const {reset} = useSignalBuilderStore();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onCloseComplete={() => {
        reset();
        setStep('selectField');
      }}
      isCentered
      size="3xl"
    >
      <ModalOverlay />
      <CreateSignalModalContent onClose={onClose} />
    </Modal>
  );
};

const CreateSignalModalContent = ({onClose}: {onClose: () => void}) => {
  const {step} = useSignalBuilderNavStore();
  const {reset} = useSignalBuilderStore();
  const [selectedField, setSelectedField] =
    useState<AccountFieldOrCustomField | null>(null);

  return (
    <ModalContent>
      <ModalCloseButton />
      {step === 'selectField' && (
        <SelectFieldStep
          selectedField={selectedField}
          onFieldSelected={(selectedField) => {
            setSelectedField(selectedField);
            reset();
          }}
        />
      )}
      {step === 'defineSignal' && !!selectedField && (
        <DefineSignalStep onClose={onClose} field={selectedField} />
      )}
    </ModalContent>
  );
};

const SelectFieldStep = ({
  selectedField,
  onFieldSelected,
}: {
  selectedField: AccountFieldOrCustomField | null;
  onFieldSelected: (field: AccountFieldOrCustomField) => void;
}) => {
  const {setStep} = useSignalBuilderNavStore();
  const fieldResolver = useFieldResolver();
  const customer = useCustomer();
  const dataType = selectedField
    ? fieldResolver(selectedField)?.dataType
    : null;

  const customerFields =
    customer.fieldDefinitions
      ?.filter(({dataType}) => dataType === 'number' || dataType === 'boolean')
      ?.filter(({status}) => status === 'published') ?? [];

  const resolvedFieldOptions = filterNull(
    [...CustomSignalFields, ...customerFields.map(({id}) => id)].map(
      (field) => {
        const resolvedField = fieldResolver(field);
        if (!resolvedField) {
          return null;
        }

        return {
          value: field,
          label: resolvedField.label,
          source: resolvedField.source,
        };
      }
    )
  ).sort((a, b) => a.label.localeCompare(b.label));

  const groupedFieldOptions = [
    {
      label: 'Custom',
      options: resolvedFieldOptions.filter(({source}) => source === 'custom'),
    },
    {
      label: 'Keyplay',
      options: resolvedFieldOptions.filter(({source}) => source === 'keyplay'),
    },
  ];

  return (
    <>
      <ModalHeader fontWeight="normal">
        <Text fontSize="xl" mb="1">
          Select Signal Source
        </Text>
        <Text fontSize="sm" textColor="kgray.300">
          Learn more about how to create custom signals{' '}
          <Link
            href="https://docs.keyplay.io/en/"
            isExternal
            textColor="kblue.300"
          >
            here
          </Link>
          .
        </Text>
      </ModalHeader>
      <ModalBody minH="300px">
        <Grid
          templateColumns="1fr 200px"
          columnGap="8"
          rowGap="2"
          alignItems="baseline"
        >
          <GridItem>
            <Label>Field</Label>
          </GridItem>
          <GridItem>
            <Label>Field Type</Label>
          </GridItem>

          <GridItem>
            <Select
              isMulti={false}
              onChange={(newValue) => {
                if (!newValue) {
                  return;
                }

                onFieldSelected(newValue.value);
              }}
              options={groupedFieldOptions}
              placeholder="Select a Field"
              value={resolvedFieldOptions.find(
                ({value}) => value.toString() === selectedField?.toString()
              )}
            />
          </GridItem>
          <GridItem>{dataType ?? '-'}</GridItem>
        </Grid>
      </ModalBody>
      <ModalFooter
        borderTop="1px solid"
        borderColor="kgray.200"
        justifyContent="start"
      >
        <Button
          colorScheme="kbuttonblue"
          fontWeight="normal"
          isDisabled={!selectedField}
          onClick={() => setStep('defineSignal')}
        >
          Next
        </Button>
      </ModalFooter>
    </>
  );
};

const categoryOptions = ScoringCategories.map((category) => ({
  value: category,
  label: ScoringCategoryLabels[category],
}));

function getSignalGroup({
  field,
  signalDefinitions,
}: {
  evalFn: SignalEvalFn;
  field: AccountFieldOrCustomField;
  signalDefinitions: SignalDefinition[];
}) {
  // if we're creating a signal for a single Keyplay field, prefer the built-in group for that field
  let accountFieldGroup;
  if (isAccountField(field)) {
    accountFieldGroup =
      AccountFieldToSignalGroup[
        field as keyof typeof AccountFieldToSignalGroup
      ];
  }

  // otherwise if there's an existing signal definition that matches the field
  // of the new signal definition, use that
  const signalDefinitionGroup = signalDefinitions.find((signalDefinition) => {
    const inputs = getSignalEvalFnInputs(signalDefinition.evalFn);
    return (
      inputs.length === 1 &&
      inputs[0].type === 'field' &&
      _.isEqual(inputs[0].field, field)
    );
  })?.group;

  return accountFieldGroup ?? signalDefinitionGroup ?? new ObjectId();
}

const DefineSignalStep = ({
  onClose,
  field,
}: {
  onClose: () => void;
  field: AccountFieldOrCustomField;
}) => {
  const {setStep} = useSignalBuilderNavStore();
  const {evalFn, label, setSignalCategory, signalCategory} =
    useSignalBuilderStore();
  const {dataType, label: fieldLabel} = useResolvedField(field);

  const {signalDefinitions} = useCustomer();
  const createSignalDefinition = useCreateSignalDefinition();
  const saveSignalDefinition = () => {
    if (!signalCategory || !label || !evalFn) {
      return;
    }

    const group = getSignalGroup({
      evalFn,
      field,
      signalDefinitions: signalDefinitions ?? [],
    });

    createSignalDefinition.mutate(
      {
        label,
        category: signalCategory,
        evalFn,
        group,
      },
      {onSuccess: onClose}
    );
  };

  return (
    <>
      <ModalHeader fontWeight="normal">
        <Text fontSize="xl" mb="1">
          Define Signals - {fieldLabel}
        </Text>
        <Text fontSize="sm" textColor="kgray.300">
          Learn more about how to create custom signals{' '}
          <Link
            href="https://docs.keyplay.io/en/"
            isExternal
            textColor="kblue.300"
          >
            here
          </Link>
          .
        </Text>
      </ModalHeader>
      <ModalBody minH="300px">
        <Grid columnGap="8" rowGap="2" templateColumns="60% 1fr">
          <GridItem>
            <Label>Category</Label>
          </GridItem>
          <GridItem />
          <GridItem>
            <Select
              isMulti={false}
              onChange={(newValue) => {
                if (!newValue) {
                  return;
                }

                setSignalCategory(newValue.value);
              }}
              options={categoryOptions}
              placeholder="Select a category"
              value={categoryOptions.find(
                ({value}) => value === signalCategory
              )}
            />
          </GridItem>
          <GridItem />
          <GridItem colSpan={2}>
            <Divider my="5" />
          </GridItem>
          {dataType === 'number' && <RangeSignalBuilder field={field} />}
          {dataType === 'boolean' && <BooleanSignalBuilder field={field} />}
        </Grid>
      </ModalBody>
      <ModalFooter
        borderTop="1px solid"
        borderColor="kgray.200"
        justifyContent="start"
        gap="2"
      >
        <Button
          colorScheme="kbuttonblue"
          fontWeight="normal"
          isDisabled={!signalCategory || !label || !evalFn}
          isLoading={createSignalDefinition.isPending}
          onClick={saveSignalDefinition}
        >
          Save Signals
        </Button>
        <Button
          fontWeight="normal"
          onClick={() => setStep('selectField')}
          variant="ghost"
        >
          Back
        </Button>
      </ModalFooter>
    </>
  );
};
