import {useApiMutation} from './api';
import {BulkFindAccounts} from '../../shared/api/definitions';
import {useIsMutating} from '@tanstack/react-query';

const BulkFindAccountsMutationKey = ['bulkFindAccounts'];

export const useBulkFindAccountsMutation = () => {
  return useApiMutation('/bulkFindAccounts', BulkFindAccounts, {
    mutationKey: BulkFindAccountsMutationKey,
  });
};

export const useIsBulkFindAccountsLoading = () =>
  useIsMutating({
    mutationKey: BulkFindAccountsMutationKey,
  }) > 0;
