import _ from 'lodash';

export function pluralize(count: number, word: string, plural: string) {
  return `${count.toLocaleString()} ${count === 1 ? word : plural}`;
}

export function calculateNormalizedPercentage<T>({
  targetValue,
  valueCounts,
  isLastValue,
}: {
  targetValue: T;
  valueCounts: Array<[T, number]>;
  isLastValue: boolean;
}): {count: number; percentage: number} {
  const sum = _.sumBy(valueCounts, ([_, count]) => count);
  const count =
    _.find(valueCounts, ([value]) => value === targetValue)?.[1] ?? 0;

  if (!isLastValue) {
    return {
      count,
      percentage: Math.round((count / sum) * 100),
    };
  }

  // For the last value, adjust to ensure total equals 100
  let percentage = 100;
  for (const [value, count] of valueCounts) {
    if (value === targetValue) {
      continue;
    }

    // Ensure we don't subtract more than the remaining percentage
    percentage = Math.max(0, percentage - Math.round((count / sum) * 100));
  }

  return {
    count,
    percentage,
  };
}
