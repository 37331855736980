import React from 'react';
import {
  Box,
  Button,
  ButtonProps,
  Code,
  Divider,
  Flex,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import {useAiFieldBuilderStore} from './AiFieldBuilder.state';
import {LeftColumnContent, RightColumnContent, TwoColumnGrid} from './helpers';
import {AccountTypeahead} from '../../AccountTypeahead';
import {ScrollableFlex, WrapperFlex} from '../../utils/scrolling';
import {AccountList} from '../../AccountList';
import {useAiFieldBuilderNavStore} from './AiFieldBuilderNav.state';
import {AiFieldBuilderPreviewTable} from './AiFieldBuilderPreviewTable';
import {useBulkFindAccounts} from '../../../hooks/useBulkFindAccounts';
import {MaxBulkPreviewAccounts} from '../../../shared/validation';
import {FieldBuilderPageIndicator} from './FieldBuilderPageIndicator';

export const AiFieldBuilderSelectAccountsStep = () => {
  const {selectedAccountIds, addAccount, removeAccount, publishChanges} =
    useAiFieldBuilderStore();
  const {nextStep, prevStep} = useAiFieldBuilderNavStore();

  const onContinue = () => {
    publishChanges.mutate({mode: 'save'}, {onSuccess: nextStep});
  };

  return (
    <TwoColumnGrid>
      <Flex borderRight="1px solid" borderColor="kgray.200" direction="column">
        <Box position="relative" h="100%">
          <WrapperFlex>
            <ScrollableFlex p="0">
              <LeftColumnContent>
                <FieldBuilderPageIndicator />
                <Flex direction="column" flex="1" gap="4">
                  <Flex direction="column" gap="1">
                    <Text fontSize="xl">Choose accounts to preview</Text>
                    <Text fontSize="md" color="kgray.300">
                      Choose up to {MaxBulkPreviewAccounts} accounts.
                    </Text>
                  </Flex>
                  <Flex alignItems="center" gap="2">
                    <Box flex="1">
                      <AccountTypeahead
                        disabledTooltip="Max preview accounts reached"
                        isDisabled={
                          selectedAccountIds.length >= MaxBulkPreviewAccounts
                        }
                        onSelect={addAccount}
                        placeholder="Search for an account..."
                        selected={selectedAccountIds}
                      />
                    </Box>
                    <AccountsImportButton borderRadius="xl" h="100%">
                      Upload CSV
                    </AccountsImportButton>
                  </Flex>
                  <AccountList
                    onRemoveAccount={removeAccount}
                    selectedAccountIds={selectedAccountIds}
                  />
                </Flex>
              </LeftColumnContent>
            </ScrollableFlex>
          </WrapperFlex>
        </Box>

        <Divider />

        <Flex justifyContent="space-between" w="100%" px={10} py={4}>
          <Button variant="ghost" onClick={prevStep}>
            Back
          </Button>

          <Button
            colorScheme="kbuttonblue"
            isDisabled={!selectedAccountIds.length}
            isLoading={publishChanges.isPending}
            onClick={onContinue}
          >
            Continue
          </Button>
        </Flex>
      </Flex>

      <RightColumnContent>
        <Flex direction="column" gap="1">
          <Text fontSize="xl">Preview</Text>
          <Text fontSize="md" color="kgray.300">
            Choose accounts that will help you review your AI Analyst&apos;s
            answers
          </Text>
        </Flex>
        <Box flex="1" position="relative">
          <WrapperFlex>
            <ScrollableFlex p="0">
              <AiFieldBuilderPreviewTable />
            </ScrollableFlex>
          </WrapperFlex>
        </Box>
      </RightColumnContent>
    </TwoColumnGrid>
  );
};

export const AccountsImportButton = ({children, ...rest}: ButtonProps) => {
  const toast = useToast();
  const {selectedAccountIds, addAccount} = useAiFieldBuilderStore();
  const remainingAccounts = MaxBulkPreviewAccounts - selectedAccountIds.length;

  const {isPending, open} = useBulkFindAccounts({
    maxRows: remainingAccounts,
    onError: () => {
      toast({
        title: 'Invalid account list',
        description: `Please ensure your CSV file has a Domain column and is less than ${remainingAccounts} rows.`,
        status: 'error',
      });
    },
    onSuccess: (accountIds) => {
      accountIds.forEach(addAccount);
    },
    type: 'preview',
  });

  return (
    <Tooltip
      hasArrow
      isDisabled={isPending}
      label={
        <Flex direction="column" gap="4" my="2">
          <Text>The CSV should include a single column of domain names.</Text>
          <Code>
            <Box>Domain</Box>
            <Box>example1.com</Box>
            <Box>example2.com</Box>
          </Code>
        </Flex>
      }
      placement="top"
    >
      <Button
        colorScheme="kbuttonblue"
        fontSize="sm"
        fontWeight="normal"
        isDisabled={isPending || remainingAccounts <= 0}
        onClick={open}
        variant="outline"
        {...rest}
      >
        {children}
      </Button>
    </Tooltip>
  );
};
