import {
  Button,
  Drawer,
  DrawerContent,
  DrawerBody,
  Grid,
  GridItem,
  Box,
  Flex,
  Spacer,
  BoxProps,
  DrawerOverlay,
  Link,
  Spinner,
  Image,
  Skeleton,
  SkeletonProps,
  useDisclosure,
  FlexProps,
} from '@chakra-ui/react';
import {useQuery} from '@tanstack/react-query';
import React, {useEffect, useState} from 'react';
import {useKeyplayApi} from '../../context/ApiContext';
import {ScoringCategory, ScoringCategoryLabels} from '../../shared/signals';
import {
  AudienceRenderer,
  FitRenderer,
  PrimaryCategoriesRenderer,
  SecondaryCategoriesRenderer,
  SignalsRenderer,
  TagsRenderer,
} from './AccountGrid.components';
import {ArrowLeft, ArrowRight, ArrowUpRight} from '@carbon/icons-react';
import {ObjectId} from 'bson';
import {useHotkeys} from 'react-hotkeys-hook';
import {useIsMyAccountsView, useUserControls} from './AccountGrid.controlstate';
import {useActions, TierOverridePopout} from './AccountGrid.actions';
import {AccountAction, AccountLinksResponse} from '../../shared/api/api';
import _ from 'lodash';
import {AccountTagsEditorModal} from '../AccountTagsEditor';
import {ScoredAccount} from '../../shared/scoredAccounts';
import {CountryList} from '../../shared/countries';
import {useIsRescoring} from '../../hooks/api/scoringInfo';
import {useIsCrmSyncing} from '../../hooks/api/integrations';
import {
  useAccountActions,
  useSingleAccountActionContext,
} from '../../hooks/api/actions';
import {
  PrimaryBusinessCategory,
  SecondaryBusinessCategory,
  BusinessAudience,
} from '../../shared/account';
import {useScoringSignalResolver} from '../../hooks/scoringSignal';
import {DrawerCloser} from '../DrawerCloser';
import {Title} from '../Title';

interface AccountDetailsProps {
  selectedAccount: ScoredAccount | null;
  isOpen: boolean;
  onClose: () => void;
  onNextOrPrev: (
    accountId: ObjectId | string,
    direction: 'next' | 'prev'
  ) => void;
}

export const AccountDetails = ({
  selectedAccount,
  isOpen,
  onClose,
  onNextOrPrev,
}: AccountDetailsProps) => {
  const contents = selectedAccount ? (
    <AccountDetailsContents
      account={selectedAccount}
      onClose={onClose}
      onNextOrPrev={onNextOrPrev}
    />
  ) : (
    <Grid alignItems="center" justifyContent="center" color="kgray.300">
      No account
    </Grid>
  );

  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement="right"
        size="xl"
        onClose={onClose}
        blockScrollOnMount={false}
      >
        <DrawerOverlay />
        <DrawerContent maxWidth="80%">
          <DrawerCloser onClose={onClose} />
          <DrawerBody p={0}>{contents}</DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

const renderQuickLinks = (quickLinks: AccountLinksResponse) => {
  const links = quickLinks.map((link, index) => {
    return (
      <Link
        key={index}
        href={link.url}
        target="_blank"
        rel="noreferrer noopener"
        color="kblue.400"
        fontSize="0.875rem"
        cursor="pointer"
      >
        <Flex display="inline-flex" gap="4px" mr={6}>
          {link.label}
          <ArrowUpRight style={{position: 'relative', top: 1}} />
        </Flex>
      </Link>
    );
  });
  return <Box>{links}</Box>;
};

const ImagePlaceholder = ({...props}: SkeletonProps) => (
  <Skeleton borderRadius="8px" sx={{aspectRatio: '16 / 10'}} {...props} />
);

const AccountDetailsContents = ({
  account,
  onNextOrPrev,
}: {
  account: ScoredAccount;
  onClose: () => void;
  onNextOrPrev: (
    accountId: ObjectId | string,
    direction: 'next' | 'prev'
  ) => void;
}) => {
  useHotkeys('right', () => onNextOrPrev(account.accountId, 'next'), [
    account,
    onNextOrPrev,
  ]);
  useHotkeys('left', () => onNextOrPrev(account.accountId, 'prev'), [
    account,
    onNextOrPrev,
  ]);

  const isMyAccountsView = useIsMyAccountsView();
  const tagAccountsModal = useDisclosure();
  const isRescoring = useIsRescoring();
  const isCrmSyncing = useIsCrmSyncing();

  const makeApiCall = useKeyplayApi();

  // Maintain separate state for account tags, so they can be updated independently
  // of the account before query invalidation completes.
  const [accountTags, setAccountTags] = useState<ObjectId[]>([]);
  useEffect(() => {
    setAccountTags(account.mutatedState.tags);
  }, [account.mutatedState.tags]);

  const {
    isPending: isLoadingScreenshots,
    isSuccess: haveScreenshots,
    data: screenshotData,
  } = useQuery({
    queryKey: ['screenshots', account.accountId.toString()],
    queryFn: () =>
      makeApiCall<{root?: string; pricing?: string}>(
        `/screenshot?id=${account.accountId}`
      ),
  });

  const {isSuccess: haveQuickLinks, data: quickLinksData} = useQuery({
    queryKey: ['accountLinks', account.accountId.toString()],
    queryFn: () =>
      makeApiCall<AccountLinksResponse>(
        `/accountLinks?accountId=${account.accountId}`
      ),
  });

  // mutations to take actions
  const {resetRowSelection} = useUserControls();

  const actionContext = useSingleAccountActionContext(account.accountId);
  const accountActions = useAccountActions(actionContext);

  const updateAccount = ({
    action,
    advanceToNextPage,
    onSuccess,
  }: {
    action: AccountAction;
    advanceToNextPage: boolean;
    onSuccess?: () => void;
  }) => {
    accountActions.mutate(
      {action},
      {
        onSuccess: () => {
          onSuccess?.();
          resetRowSelection();
          if (advanceToNextPage) {
            onNextOrPrev(account.accountId, 'next');
          }
        },
      }
    );
  };

  const actionsEnabled =
    !isRescoring && !accountActions.isPending && !isCrmSyncing;
  const actionsToShow = useActions();

  const allActionButtons = {
    approve: (
      <Button
        key="save"
        mr={2}
        colorScheme="kgreen"
        onClick={() =>
          updateAccount({
            action: {name: 'approve'},
            advanceToNextPage: true,
          })
        }
        isDisabled={!actionsEnabled}
      >
        Save
      </Button>
    ),
    disqualify: (
      <Button
        key="disqualify"
        colorScheme="gray"
        onClick={() =>
          updateAccount({
            action: {name: 'disqualify'},
            advanceToNextPage: true,
          })
        }
        isDisabled={!actionsEnabled}
      >
        Disqualify
      </Button>
    ),
    reactivate: (
      <Button
        key="reactivate"
        colorScheme="gray"
        onClick={() =>
          updateAccount({
            action: {name: 'reactivate'},
            advanceToNextPage: true,
          })
        }
        isDisabled={!actionsEnabled}
      >
        Reactivate
      </Button>
    ),
    exclude: (
      <Button
        key="exclude"
        colorScheme="gray"
        onClick={() =>
          updateAccount({
            action: {name: 'exclude'},
            advanceToNextPage: true,
          })
        }
        isDisabled={!actionsEnabled}
      >
        Exclude
      </Button>
    ),
    override: (
      <TierOverridePopout
        placement="top"
        key="override"
        onClick={(override) =>
          updateAccount({
            action: {name: 'override', override},
            advanceToNextPage: false,
          })
        }
      >
        <Button colorScheme="gray" isDisabled={!actionsEnabled}>
          Override Tier
        </Button>
      </TierOverridePopout>
    ),
    tag: (
      <Flex key="tag">
        <AccountTagsEditorModal
          actionContext={actionContext}
          isOpen={tagAccountsModal.isOpen}
          tagOnly={isMyAccountsView}
          onClose={tagAccountsModal.onClose}
          onTagsUpdated={(tags) => {
            // Update the tags manually, so we don't have to wait until
            // the query invalidation completes.
            setAccountTags(tags);
            resetRowSelection();
          }}
          selectedTags={accountTags}
        />
        <Button
          isDisabled={!actionsEnabled}
          variant="outline"
          onClick={tagAccountsModal.onOpen}
        >
          {isMyAccountsView ? 'Edit Tags' : 'Save & Tag'}
        </Button>
      </Flex>
    ),
  };
  const actionButtons = _.pick(allActionButtons, actionsToShow);

  // screenshots

  let screensContent = null;
  const titleStyles: BoxProps = {
    mt: 2,
    mb: 6,
    textAlign: 'center',
    color: 'kgray.300',
  };
  if (haveScreenshots && screenshotData) {
    screensContent = (
      <Box pb={1}>
        <Image
          src={screenshotData.root}
          width="100%"
          sx={{aspectRatio: '16 / 10'}}
          borderRadius="8px"
          fallback={<ImagePlaceholder />}
        />
        <SubSectionTitle {...titleStyles}>Home</SubSectionTitle>
        {screenshotData?.pricing && (
          <>
            <Image
              src={screenshotData.pricing}
              width="100%"
              sx={{aspectRatio: '16 / 10'}}
              borderRadius="8px"
              fallback={<ImagePlaceholder />}
            />
            <SubSectionTitle {...titleStyles}>Pricing</SubSectionTitle>
          </>
        )}
      </Box>
    );
  } else if (isLoadingScreenshots) {
    screensContent = (
      <>
        <ImagePlaceholder width="100%" />
        <SubSectionTitle {...titleStyles}>Home</SubSectionTitle>
      </>
    );
  }

  // rendering

  return (
    <>
      <Title title={account.fields.name ?? 'Account Details'} />
      <Grid templateRows="72px 1fr 64px" height="100%">
        <Flex
          px={6}
          borderBottomWidth="1px"
          borderBottomColor="kgray.100"
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
        >
          <Grid alignItems="center" templateColumns="7fr 9fr">
            <Flex alignItems="center" gap={2}>
              <Box fontSize="20px" fontWeight="500" color="kgray.500">
                {account.fields.name}
              </Box>
              <TagsRenderer
                deletable={true}
                disabled={accountActions.isPending}
                onDeleteTag={({tagsToRemove, remainingTags}) => {
                  updateAccount({
                    action: {
                      name: 'tag',
                      tagsToRemove: tagsToRemove.map((id) => id.toString()),
                    },
                    advanceToNextPage: false,
                    onSuccess: () => {
                      setAccountTags(remainingTags);
                    },
                  });
                }}
                tagIds={account.mutatedState.tags.filter((tag) =>
                  accountTags.some((accountTag) => accountTag.equals(tag))
                )}
              />
              {accountActions.isPending && <Spinner />}
            </Flex>
            <Flex justifyContent="space-around">
              <Flex alignItems="center">
                <Box position="relative" top="-1px">
                  {account.tier}
                </Box>
                <SubSectionTitle color="#CFCFD9" ml={2} mr={6} mb={0}>
                  tier
                </SubSectionTitle>

                <FitRenderer value={account.overallFit} />
                <SubSectionTitle color="#CFCFD9" ml={2} mb={0}>
                  overall fit
                </SubSectionTitle>
              </Flex>
            </Flex>
          </Grid>
        </Flex>
        <Grid templateColumns="7fr 9fr" overflowY="auto">
          <Grid templateRows="min-content 1fr">
            <GridItem
              p={6}
              borderBottomWidth="1px"
              borderBottomColor="kgray.100"
            >
              <Box fontSize={16} mb={6}>
                {account.fields.tagline}
              </Box>
              <Grid templateColumns="1fr 1fr 1fr" gap="16px">
                <AttributeItem title="Employees">
                  {account.fields.employeeCount}
                </AttributeItem>
                <AttributeItem title="HQ Country">
                  {CountryList[
                    account.fields.hqCountry as keyof typeof CountryList
                  ] || account.fields.hqCountry}
                </AttributeItem>
              </Grid>
            </GridItem>
            <GridItem as={Flex} direction="column" gap="4" p="6">
              <Section title="Categories">
                <CategoriesSection
                  primaryCategories={account.primaryCategory}
                  secondaryCategories={account.secondaryCategory}
                  audiences={account.audience}
                />
              </Section>
              <SectionDivider />
              <Section title="Signals">
                <Flex direction="column" gap="6">
                  <SignalsSection category="profile" account={account} />
                  <SignalsSection category="org" account={account} />
                  <SignalsSection category="relevance" account={account} />
                  <SignalsSection category="stackFit" account={account} />
                </Flex>
              </Section>
              <SectionDivider />
              {haveQuickLinks && (
                <Section title="Quick Links">
                  {renderQuickLinks(quickLinksData)}
                </Section>
              )}
            </GridItem>
          </Grid>
          <GridItem p={6} borderLeftWidth="1px" borderLeftColor="kgray.100">
            {screensContent}
          </GridItem>
        </Grid>
        <Box
          borderTopWidth="1px"
          borderTopColor="kgray.200"
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
          px={6}
        >
          <Flex alignItems="center">
            <Button
              variant="ghost"
              onClick={() => onNextOrPrev(account.accountId, 'prev')}
              color="#CFCFD9"
              fontFamily="mono"
              fontWeight="500"
              fontSize="12px"
              textTransform="uppercase"
            >
              <ArrowLeft color="#76767E" style={{marginRight: '12px'}} />
              Previous
            </Button>
            <Spacer />
            <Flex gap={2}>{Object.values(actionButtons)}</Flex>
            <Spacer />
            <Button
              variant="ghost"
              onClick={() => onNextOrPrev(account.accountId, 'next')}
              color="#CFCFD9"
              fontFamily="mono"
              fontWeight="500"
              fontSize="12px"
              textTransform="uppercase"
            >
              Next
              <ArrowRight color="#76767E" style={{marginLeft: '12px'}} />
            </Button>
          </Flex>
        </Box>
      </Grid>
    </>
  );
};

// Components

const Section = ({children, title, ...props}: BoxProps & {title: string}) => (
  <Box {...props}>
    <SectionTitle>{title}</SectionTitle>
    {children}
  </Box>
);

const SectionDivider = () => (
  <Box borderBottomWidth="1px" borderBottomColor="kgray.100" />
);

const SectionTitle = ({children, ...props}: BoxProps) => (
  <Box fontWeight={500} fontSize="18px" color="kgray.400" mb={2} {...props}>
    {children}
  </Box>
);

const SubSectionTitle = ({children, ...props}: BoxProps) => (
  <Box
    fontFamily="mono"
    fontWeight={500}
    fontSize="12px"
    textTransform="uppercase"
    color="kgray.300"
    mb={2}
    {...props}
  >
    {children}
  </Box>
);

interface CategoriesSectionProps extends FlexProps {
  primaryCategories?: PrimaryBusinessCategory[];
  secondaryCategories?: SecondaryBusinessCategory[];
  audiences?: BusinessAudience[];
}
export const CategoriesSection = ({
  primaryCategories,
  secondaryCategories,
  audiences,
  ...props
}: CategoriesSectionProps) => {
  if (
    !audiences?.length &&
    !primaryCategories?.length &&
    !secondaryCategories
  ) {
    return (
      <Flex color="kgray.300" {...props}>
        No categories
      </Flex>
    );
  }

  return (
    <Flex direction="column" gap="6" {...props}>
      {primaryCategories && primaryCategories.length > 0 && (
        <Box>
          <SubSectionTitle>Primary Category</SubSectionTitle>
          <PrimaryCategoriesRenderer categories={primaryCategories} />
        </Box>
      )}
      {secondaryCategories && secondaryCategories.length > 0 && (
        <Box>
          <SubSectionTitle>Secondary Category</SubSectionTitle>
          <SecondaryCategoriesRenderer categories={secondaryCategories} />
        </Box>
      )}
      {audiences && audiences.length > 0 && (
        <Box>
          <SubSectionTitle>Target Audience</SubSectionTitle>
          <AudienceRenderer audiences={audiences} />
        </Box>
      )}
    </Flex>
  );
};

interface SignalsSectionProps extends BoxProps {
  category: ScoringCategory;
  account: ScoredAccount;
}

const SignalsSection = ({category, account, ...props}: SignalsSectionProps) => {
  const scoringSignalResolver = useScoringSignalResolver();

  const signals = account.matchedSignals.filter(
    (signal) => scoringSignalResolver(signal)?.category === category
  );

  if (!signals.length) {
    return null;
  }
  return (
    <Box {...props}>
      <SubSectionTitle>{ScoringCategoryLabels[category]}</SubSectionTitle>
      <SignalsRenderer signals={signals} />
    </Box>
  );
};

const AttributeItem = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => (
  <GridItem display="flex" flexDirection="column">
    <SubSectionTitle>{title}</SubSectionTitle>
    <Box>{children}</Box>
  </GridItem>
);
