import {useQueryClient} from '@tanstack/react-query';
import {ClaimBonusCode} from '../../shared/api/definitions';
import {useApiMutation} from './api';
import {MetadataQueryKey} from '../../context/MetadataContext';

export const useClaimBonusCodeMutation = () => {
  const queryClient = useQueryClient();

  return useApiMutation('/claimBonusCode', ClaimBonusCode, {
    onSuccess: async () => {
      // claiming bonus code may give additional credits or switch plan
      await queryClient.invalidateQueries({
        queryKey: [MetadataQueryKey],
      });
    },
  });
};
