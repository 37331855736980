import React, {useMemo} from 'react';
import {
  Table,
  TableContainer,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Skeleton,
  Box,
  Tooltip,
} from '@chakra-ui/react';
import {assertNever, WithId} from '../../../../shared/util';
import {useAnalysisRunContext} from './AnalysisRunProvider';
import {
  AiFieldDataType,
  TestListVersionResults,
} from '../../../../shared/enrichment';
import {TestList} from '../../../../shared/testLists';
import {calculateNormalizedPercentage} from '../../../../lib/helpers';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import _ from 'lodash';
import {captureMessage} from '@sentry/react';
import {useSelectedVersionStore} from '../SelectedVersion.state';

function useFieldTableData() {
  const {selectedVersionId} = useSelectedVersionStore();
  const {analysisRun} = useAnalysisRunContext();

  return useMemo(() => {
    // Use the config stored on the analysis run since that cannot be modified
    const config = analysisRun.fieldDefinition.preview?.versions.find(({id}) =>
      id.equals(selectedVersionId)
    )?.config;

    if (!config) {
      captureMessage('Missing version config', {
        extra: {
          analysisRunId: analysisRun._id.toString(),
        },
        level: 'error',
      });

      return {
        headerLabel: 'Unknown Field',
        fieldValues: [],
      };
    }

    switch (config.fieldType) {
      case 'boolean':
        return {
          headerLabel: 'Answer',
          fieldValues: [true, false],
        };
      case 'rating':
        return {
          headerLabel: 'Rating',
          fieldValues: _.range(config.min, config.max + 1).reverse(),
        };
      case 'category':
        return {
          headerLabel: 'Category',
          fieldValues: config.categories.map(({name}) => name),
        };
      default:
        assertNever(config);
    }
  }, [analysisRun, selectedVersionId]);
}

const SkeletonCell = React.memo(() => {
  return <Skeleton h="10px" />;
});
SkeletonCell.displayName = 'SkeletonCell';

const TestListResultCell = ({
  fieldValue,
  result,
  testList,
}: {
  fieldValue: AiFieldDataType;
  result: TestListVersionResults;
  testList: WithId<TestList>;
}) => {
  const {fieldValues} = useFieldTableData();

  if (testList.status.type !== 'ready' || result.status === 'running') {
    return <SkeletonCell />;
  }

  const {count, percentage} = calculateNormalizedPercentage({
    targetValue: fieldValue,
    valueCounts: result.valueCounts.map(({value, count}) => [value, count]),
    isLastValue: fieldValue === fieldValues[fieldValues.length - 1],
  });

  return (
    <Box textAlign="center">
      <Tooltip label={`${count} accounts`} isDisabled={!count}>
        <Box>{percentage}%</Box>
      </Tooltip>
    </Box>
  );
};

const columnHelper = createColumnHelper<AiFieldDataType>();
function useTableColumns({headerLabel}: {headerLabel: string}) {
  const {analysisRun, testLists} = useAnalysisRunContext();

  return useMemo(
    () => [
      columnHelper.accessor((row) => row, {
        id: 'fieldValue',
        header: headerLabel,
        cell: (info) => info.getValue().toLocaleString(),
      }),

      // Each test list has a column
      ...analysisRun.results.map((result) => {
        const testListId = result.testListId.toString();
        const testList = testLists.find((list) =>
          list._id.equals(result.testListId)
        );

        if (!testList) {
          return columnHelper.accessor((row) => row, {
            id: testListId,
            header: 'Unknown Test List',
            cell: '-',
            size: 100,
          });
        }

        return columnHelper.accessor((row) => row, {
          id: testListId,
          header: () => <Box textAlign="center">{testList.label}</Box>,
          cell: (info) => (
            <TestListResultCell
              key={testListId}
              fieldValue={info.getValue()}
              result={result}
              testList={testList}
            />
          ),
          size: 100,
        });
      }),
    ],
    [analysisRun, headerLabel, testLists]
  );
}

export const AnalysisResultsTable = () => {
  const {headerLabel, fieldValues} = useFieldTableData();
  const columns = useTableColumns({headerLabel});

  const table = useReactTable({
    columns,
    data: fieldValues,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <TableContainer
      border="1px solid"
      borderColor="kgray.200"
      borderRadius="lg"
    >
      <Table variant="simple">
        <Thead bg="kgray.100">
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th key={header.id}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <Td
                  key={cell.id}
                  width={
                    cell.getContext().column.getSize()
                      ? `${cell.getContext().column.getSize()}px`
                      : 'auto'
                  }
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
