import {useQuery} from '@tanstack/react-query';
import {useKeyplayApi} from '../../context/ApiContext';
import {SamDefinition} from '../../shared/market';
import {SamDefinitionSizeResponse} from '../../shared/api/api';

export function useSamDefinitionSize({
  enabled,
  samDefinition,
}: {
  enabled: boolean;
  samDefinition: SamDefinition;
}) {
  const keyplayApi = useKeyplayApi();
  return useQuery({
    queryKey: ['samDefinitionSize', JSON.stringify(samDefinition)],
    queryFn: () =>
      keyplayApi<SamDefinitionSizeResponse>(
        `/samDefinitionSize?sam=${JSON.stringify(samDefinition)}`,
        {
          method: 'GET',
        },
        {toastOnError: true}
      ),
    enabled,
    refetchOnWindowFocus: false,
  });
}
