import React, {useCallback} from 'react';
import {
  Box,
  ButtonProps,
  Code,
  useToast,
  Tooltip,
  Flex,
  Button,
  Text,
} from '@chakra-ui/react';
import {useCustomer} from '../../../hooks/api/metadata';
import {useSimilarityState} from './Similarity.state';
import {useBulkFindAccounts} from '../../../hooks/useBulkFindAccounts';

export const LookalikesImportButton = ({children, ...rest}: ButtonProps) => {
  const customer = useCustomer();
  const toast = useToast();
  const {getLimits, localState, addLookalikes} = useSimilarityState();

  const {maxLookalikes} = getLimits(customer);
  const remainingLookalikes = maxLookalikes - localState.length;

  const showErrorToast = useCallback(() => {
    toast({
      title: 'Invalid lookalike list',
      description: `Please ensure your CSV file has a Domain column and is less than ${remainingLookalikes} rows.`,
      status: 'error',
    });
  }, [toast, remainingLookalikes]);

  const {isPending, open} = useBulkFindAccounts({
    maxRows: remainingLookalikes,
    onError: showErrorToast,
    onSuccess: addLookalikes,
    type: 'lookalike',
  });

  return (
    <Tooltip
      hasArrow
      isDisabled={isPending}
      label={
        <Flex direction="column" gap="4" my="2">
          <Text>
            Recommended for larger lists. The CSV should include a single column
            of domain names.
          </Text>
          <Code>
            <Box>Domain</Box>
            <Box>example1.com</Box>
            <Box>example2.com</Box>
          </Code>
        </Flex>
      }
      placement="top"
    >
      <Button
        colorScheme="kbuttonblue"
        fontSize="sm"
        fontWeight="normal"
        isDisabled={isPending || remainingLookalikes <= 0}
        onClick={open}
        variant="outline"
        {...rest}
      >
        {children}
      </Button>
    </Tooltip>
  );
};
