import {useKeyplayApi} from '../../context/ApiContext';
import {useQuery} from '@tanstack/react-query';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Container,
  FormControl,
  Input,
  Spinner,
} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {DebugAccountResponse} from '../../shared/api/api';

export const Signals = () => {
  const makeApiCall = useKeyplayApi();

  const [input, setInput] = useState('');
  const [domain, setDomain] = useState('');
  const [retry, setRetry] = useState(false);

  const {data, error, isLoading, isSuccess} = useQuery({
    queryKey: ['debug/signals/' + domain],
    queryFn: () =>
      makeApiCall<DebugAccountResponse[]>(`/debug/account/${domain}`),
    enabled: domain !== '',
    retry: (_failureCount, error) => {
      if (axios.isAxiosError(error) && error.response?.status === 503) {
        setRetry(true);
        return true;
      }
      setRetry(false);
      return false;
    },
    retryDelay: 10_000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (isSuccess) {
      setRetry(false);
    }
  }, [isSuccess]);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setDomain(input);
  };

  return (
    <Container fontSize="14" maxWidth="100%">
      <FormControl maxW={600}>
        <Box
          as="form"
          display="flex"
          flexDirection="row"
          gap={2}
          onSubmit={onSubmit}
          py={4}
        >
          <Input
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Account domain (e.g. notion.so)"
          />
          <Button type="submit">Lookup</Button>
        </Box>
      </FormControl>

      {data && <Box as="pre">{JSON.stringify(data, null, 2)}</Box>}
      {!!error && (
        <Alert status="error">
          <AlertIcon />
          <Box>Error fetching signals data for &quot;{domain}&quot;</Box>
        </Alert>
      )}
      {retry && (
        <Alert status="warning">
          <AlertIcon />
          <Box>Looking up &quot;{domain}&quot;. Retrying in 10 seconds...</Box>
        </Alert>
      )}

      {isLoading && (
        <Center m={4}>
          <Spinner />
        </Center>
      )}
    </Container>
  );
};
