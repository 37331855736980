import {chakra} from '@chakra-ui/react';
import {
  AddFilled,
  ArrowRight,
  Close,
  Information,
  Integration,
  Model,
  Search,
  Settings,
  View,
} from '@carbon/icons-react';

export const ChakraAddFilled = chakra(AddFilled);
export const ChakraArrowRight = chakra(ArrowRight);
export const ChakraClose = chakra(Close);
export const ChakraIntegration = chakra(Integration);
export const ChakraInformation = chakra(Information);
export const ChakraModel = chakra(Model);
export const ChakraSearch = chakra(Search);
export const ChakraSettings = chakra(Settings);
export const ChakraView = chakra(View);
